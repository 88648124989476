<template>
  <div class="tabs-business">
    <b-row>
      <b-col>
        <div class="status-profile">
          <b-row>
            <b-col
              md="4"
              sm="12"
              class="d-flex align-items-center"
            >
              <div>
                <p
                  data-testid="text-account-status"
                  class="mb-0"
                >
                  Account Status:
                  <span
                    data-testid="value-account-status"
                    class="status ml-2"
                    :class="userDetail.status === 'blocked' ? 'rejected' : ''"
                  >{{ userDetail.status }}</span>
                </p>
              </div>
            </b-col>
            <b-col
              md="8"
              sm="12"
              class="d-flex align-items-center"
            >
              <div v-if="userDetail.belongToPartner">
                <p class="mb-0 mr-2">
                  Belong to partner:
                  <a
                    class="cursor-pointer text-dark underline ml-1"
                    target="_blank"
                    :href="`/partner/${getPartnerLocalId(userDetail)}?tab=list-member`"
                  >{{
                    `${getPartnerLocalId(userDetail)} - ${getPartnerEmail(userDetail)}`
                  }}</a>
                </p>
              </div>
              <div>
                <b-button
                  v-if="!isPartner && hasPartnership"
                  class="btn-block-user"
                  variant="flat-dark"
                  size="sm"
                  @click="openAssignToAnotherPartner"
                >
                  <feather-icon
                    size="20"
                    icon="UserMinusIcon"
                    class="cursor-pointer icon-btn"
                  />
                  Assign to another partner
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="form-information">
          <b-row class="align-items-end">
            <b-col
              cols="4"
              class="role-tag"
            >
              <b-form-group class="w-100">
                <label>
                  Roles
                </label>
                <v-select
                  v-model="roleSelected"
                  multiple
                  placeholder="Select role"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="outline-primary"
                class="btn-edit"
                @click.prevent="EditRole"
              >
                Edit Roles
              </b-button>
            </b-col>

            <b-col
              cols="4"
              class="role-tag"
            >
              <b-form-group class="w-100">
                <label>
                  Tier
                </label>
                <v-select
                  v-model="tierSelected"
                  placeholder="Select tier"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col cols="2">
              <b-button
                variant="outline-primary"
                class="btn-edit"
                @click.prevent="editTier"
              >
                Edit Tier
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <validation-observer
                ref="informationValidation"
                v-slot="{ invalid }"
              >
                <b-form>
                  <b-row>
                    <!-- User ID -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="userId"
                          > User ID </label>
                        </div>
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="userId"
                            name="userId"
                            class="input-height"
                            placeholder="User Id"
                            disabled
                            :value="
                              userDetail.localUserId
                                ? userDetail.localUserId
                                : 'N/A'
                            "
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- User ID -->
                    <!-- Create At -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="createAt"
                            >
                              Create At
                            </label>
                          </div>
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              id="createAt"
                              name="createAt"
                              class="input-height"
                              placeholder="Create At"
                              disabled
                              :value="createAt ? createAt : 'N/A'"
                            />
                          </b-input-group>
                        </div>
                      </b-form-group>
                    </b-col>
                  <!-- Create At -->
                  </b-row>

                  <b-row>
                    <!-- Full Name -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="street-address"
                          >
                            Full Name
                          </label>
                        </div>

                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="business-name"
                            name="business-name"
                            class="input-height"
                            placeholder="Enter Full name"
                            disabled
                            :value="
                              userDetail.fullName ? userDetail.fullName : 'N/A'
                            "
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- Full Name -->

                    <!-- Register from service-->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="street-address"
                          >
                            Register from service
                          </label>
                        </div>

                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="register-from-service"
                            name="register-from-service"
                            class="input-height"
                            placeholder="Register from service"
                            disabled
                            :value="
                              userDetail.registerFromService ? userDetail.registerFromService : 'N/A'
                            "
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- Register from service-->
                  </b-row>

                  <b-row>
                    <!-- email -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="email"
                          > Email </label>
                        </div>
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            id="email"
                            name="email"
                            class="input-height"
                            placeholder="Enter Full name"
                            disabled
                            :value="userDetail.email ? userDetail.email : 'N/A'"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- Email -->
                    <!-- Phone number -->
                    <b-col
                      sm="12"
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <phone-input
                          :phone-data.sync="phoneData"
                          :disabled="!isEdit"
                        />
                      </b-form-group>
                    </b-col>
                  <!-- Phone number -->
                  </b-row>

                  <b-row v-if="isVNWhiteLabel">
                    <!-- Zalo -->
                    <b-col cols="12">
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="contact-zalo"
                          >
                            Zalo number <span class="text-danger">*</span>
                          </label>
                        </div>
                        <validation-provider
                          #default="{ errors }"
                          id="zaloNumber"
                          name="Zalo number"
                          rules="verifyZaloInvalid|required"
                        >
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              v-model.trim="zaloNumber"
                              id="contact-zalo"
                              name="contact-zalo"
                              class="input-height"
                              placeholder="Enter Zalo number"
                              :disabled="!isEdit"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="industry"
                          >
                            Industry <span class="text-danger">*</span>
                          </label>
                        </div>
                        <validation-provider
                          #default="{ errors }"
                          id="industry"
                          name="Industry"
                          rules="required"
                        >
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              v-model.trim="industry"
                              id="industry"
                              name="industry"
                              class="input-height"
                              placeholder="Enter Industry"
                              :disabled="!isEdit"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="shopCode"
                          >
                            TikTok Shop Code
                          </label>
                        </div>
                        <validation-provider
                          #default="{ errors }"
                          id="shopCode"
                          name="TikTok Shop code"
                        >
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              v-model.trim="shopCode"
                              id="shop-code"
                              name="shopCode"
                              class="input-height"
                              placeholder="Enter TikTok Shop code"
                              disabled
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="12">
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="contact-zalo"
                          >
                            Product Url / Landing page
                          </label>
                        </div>
                        <validation-provider
                          #default="{ errors }"
                          id="product-link"
                          name="product link"
                        >
                          <b-input-group class="input-group-merge">
                            <b-form-input
                              v-model.trim="productsData.productURL"
                              id="verify-product-link"
                              name="product link"
                              class="input-height input-product-link"
                              placeholder="Enter product Url / Landing page"
                              :tabindex="1"
                              disabled
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="!isVNWhiteLabel">
                    <!-- Facebook -->
                    <b-col cols="12">
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="contact-facebook"
                          >
                            Facebook
                          </label>
                        </div>

                        <b-input-group
                          class="input-group-merge input-fbUrl"
                          prepend="https://www.facebook.com/"
                        >
                          <b-form-input
                            v-model.trim="facebook"
                            id="connect-facebook"
                            name="connectFacebook"
                            class="input-height input-facebook"
                            placeholder="Enter Facebook"
                            :disabled="!isEdit"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- Facebook -->
                    <!-- What's app -->
                    <b-col
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="contact-WhatsApp"
                          >
                            What'sApp
                          </label>
                        </div>

                        <b-input-group class="input-group-merge">
                          <b-form-input
                            v-model.trim="whatsApp"
                            id="contact-WhatsApp"
                            name="contact-WhatsApp"
                            class="input-height"
                            placeholder="Enter What's App"
                            :disabled="!isEdit"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- What's app -->
                    <!-- Telegram -->
                    <b-col
                      md="12"
                      lg="6"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="contact-Telegram"
                          >
                            Telegram
                          </label>
                        </div>
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            v-model.trim="telegram"
                            id="contact-Telegram"
                            name="contact-Telegram"
                            class="input-height"
                            placeholder="Enter Telegram"
                            :disabled="!isEdit"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- Telegram -->
                  </b-row>

                  <b-row v-if="!isVNWhiteLabel">
                    <!-- business type -->
                    <b-col
                      sm="12"
                      md="12"
                    >
                      <b-form-group class="input-group-merge">
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="postal-code"
                            >
                              Business Type
                            </label>
                          </div>
                          <v-select
                            placeholder="Select your answer"
                            class="currency input-height"
                            disabled
                            :value="
                              userDetail.businessModel
                                ? userDetail.businessModel
                                : 'N/A'
                            "
                            :clearable="false"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon
                                  icon="ChevronDownIcon"
                                  size="20"
                                />
                              </span>
                            </template>
                          </v-select>
                        </div>
                      </b-form-group>
                    </b-col>
                  <!-- business type -->
                  </b-row>

                  <b-row class="select-country">
                    <b-col
                      sm="12"
                      md="12"
                      :lg="isVNWhiteLabel ? 12 : 6"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Country"
                        rules="required"
                      >
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <div>
                            <div class="d-flex justify-content-between">
                              <label class="form-label">
                                Country
                              </label>
                            </div>
                            <country-select
                              v-model="country"
                              placeholder="Select your country"
                              class="input-height country"
                              :country-name="true"
                              :autocomplete="true"
                              :country="country"
                              :disabled="!isEdit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      v-if="!isVNWhiteLabel"
                      cols="12"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="City/Region"
                        :rules="userDetail.country && country !== userDetail.country && !isVNWhiteLabel ? 'required' : ''"
                      >
                        <b-form-group :state="errors.length > 0 ? false : null">
                          <div>
                            <div class="d-flex justify-content-between">
                              <label class="form-label">
                                City/Region
                              </label>
                            </div>
                            <region-select
                              v-model="city"
                              class="input-height region"
                              :country-name="true"
                              :region-name="true"
                              :autocomplete="true"
                              :country="country"
                              :region="city"
                              :disabled="!isEdit || !country"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </div>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <!-- Street Address -->
                    <b-col
                      v-if="!isVNWhiteLabel"
                      cols="12"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="form-label"
                            for="street-address"
                          >
                            Street Address
                          </label>
                        </div>
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            v-model="streetAddress"
                            id="street-address"
                            name="street-address"
                            class="input-height"
                            placeholder="Enter street address"
                            :disabled="!isEdit"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <!-- Street Address -->

                    <!-- Postal Code -->
                    <b-col
                      v-if="!isVNWhiteLabel"
                      cols="12"
                    >
                      <b-form-group class="group-input">
                        <div class="d-flex justify-content-between">
                          <label
                            class="postal-code"
                            for="region"
                          >
                            Postal Code
                          </label>
                        </div>

                        <b-input-group class="input-group-merge">
                          <b-form-input
                            v-model="postalCode"
                            id="postal-code"
                            name="postal-code"
                            class="input-height"
                            placeholder="Enter Business Name"
                            :disabled="!isEdit"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  <!-- Postal Code -->
                  </b-row>

                  <b-row
                    v-if="!isVNWhiteLabel"
                    class="group-input"
                  >
                    <b-col
                      sm="12"
                      md="12"
                      lg="4"
                    >
                      <!-- What platform do you use for your store ? -->

                      <b-form-group>
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="postal-code"
                            >
                              Platform store
                            </label>
                          </div>
                          <v-select
                            placeholder="Select your answer"
                            class="currency input-height"
                            disabled
                            :value="
                              userDetail.storingPlatform
                                ? userDetail.storingPlatform
                                : 'N/A'
                            "
                            :clearable="false"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon
                                  icon="ChevronDownIcon"
                                  size="20"
                                />
                              </span>
                            </template>
                          </v-select>
                        </div>
                      </b-form-group>
                    <!-- What platform do you use for your store ? -->
                    </b-col>
                    <b-col
                      sm="12"
                      md="12"
                      lg="4"
                    >
                      <!-- What is your current monthly -->

                      <b-form-group>
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="postal-code"
                            >
                              Current monthly
                            </label>
                          </div>
                          <v-select
                            placeholder="Select your answer"
                            class="currency input-height"
                            disabled
                            :value="
                              userDetail.monthlyRevenue
                                ? userDetail.monthlyRevenue
                                : 'N/A'
                            "
                            :clearable="false"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon
                                  icon="ChevronDownIcon"
                                  size="20"
                                />
                              </span>
                            </template>
                          </v-select>
                        </div>
                      </b-form-group>
                    <!-- What is your current monthly -->
                    </b-col>
                    <b-col
                      sm="12"
                      md="12"
                      lg="4"
                    >
                      <!-- Have you run TikTok Ads before -->

                      <b-form-group>
                        <div>
                          <div class="d-flex justify-content-between">
                            <label
                              class="form-label"
                              for="postal-code"
                            >
                              Has run tikTok ads
                            </label>
                          </div>
                          <v-select
                            placeholder="Select your answer"
                            class="currency input-height"
                            disabled
                            :value="hasRunAds(userDetail.hasRunTikTokAds)"
                            :clearable="false"
                          >
                            <template #open-indicator="{ attributes }">
                              <span v-bind="attributes">
                                <feather-icon
                                  icon="ChevronDownIcon"
                                  size="20"
                                />
                              </span>
                            </template>
                          </v-select>
                        </div>
                      </b-form-group>
                    <!-- Have you run TikTok Ads before -->
                    </b-col>
                  </b-row>
                </b-form>
                <div class="group-btn-edit">
                  <div
                    v-if="!isEdit"
                    class="d-flex justify-content-end"
                  >
                    <b-button
                      class="
                          btn-submit btn-register
                          input-height
                          font-medium
                          txt-color
                          btn-continue
                        "
                      variant="primary"
                      type="submit"
                      @click="editUserDetail"
                    >
                      Edit
                    </b-button>
                  </div>
                  <div
                    v-else
                    class="d-flex justify-content-end"
                  >
                    <btn-loading
                      class="
                          btn-submit btn-register
                          input-height
                          font-medium
                          txt-color
                          btn-continue
                          mr-1
                        "
                      variant="primary"
                      type="submit"
                      :disabled="invalid || inValidContact || !hasEditInfo || validPhoneInput(invalid)"
                      :loading="loading"
                      @click="saveEdit"
                    >
                      Save
                    </btn-loading>
                    <b-button
                      class="
                          btn-submit  btn-register
                          input-height
                          font-medium
                          txt-color
                          btn-continue
                        "
                      variant="outline-primary"
                      type="submit"
                      @click="cancelEdit"
                    >
                      Cancel
                    </b-button>
                  </div>
                </div>
              </validation-observer>
            </b-col>
          </b-row>
        </div>

      </b-col>
    </b-row>

    <assign-to-another-partner
      :show-side-bar.sync="showUpgradeToAnotherPartner"
      :partner-local-id="partnerLocalId"
      :list-ad-account="listAdAccount"
      :total-spending="totalSpending"
      @fetch-user-info="fetchUserInfo"
    />

    <edit-role-side-bar
      :show-side-bar.sync="showEditRoleSideBar"
      :role-selected.sync="roleSelected"
      :user-id.sync="userDetail.localUserId"
      @fetch-user-info="fetchUserInfo"
    />

    <edit-tier-side-bar
      :show-side-bar.sync="showEditTierSideBar"
      :tier-selected.sync="tierSelected"
      :user-id.sync="userDetail.localUserId"
      @fetch-user-info="fetchUserInfo"
    />
  </div>
</template>

<script>
import numberFormatMixin from '@/mixins/numberFormatMixin'
import { toastification } from '@core/mixins/toast'
import paymentMixin from '@/mixins/paymentMixin'
import {
  BRow,
  BCol,
  BFormInput,
  BForm,
  BFormGroup,
  BInputGroup,
  BButton,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { USER_STATUS } from '@/constants'
import _get from 'lodash/get'
import pick from 'lodash/pick'
import vSelect from 'vue-select'
import '../../../libs/vue-country-region-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, verifyZaloInvalid } from '@validations'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import envMixin from '@/mixins/envMixin'
import AssignToAnotherPartner from './AssignToAnotherPartner.vue'
import PhoneInput from './PhoneInput.vue'
import EditRoleSideBar from './EditRoleSideBar.vue'
import EditTierSideBar from './EditTierSideBar.vue'

const { mapGetters, mapActions } = createNamespacedHelpers('user')
const { mapGetters: mapGettersAdAccountUser, mapActions: mapActionsAdAccountUser } = createNamespacedHelpers('partner')

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BForm,
    BFormGroup,
    BInputGroup,
    vSelect,
    BButton,
    BtnLoading,
    // component
    AssignToAnotherPartner,
    PhoneInput,
    EditRoleSideBar,
    EditTierSideBar,

    // validations
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [numberFormatMixin, toastification, paymentMixin, envMixin],

  data() {
    return {
      USER_STATUS,
      showSideBar: false,
      showBalanceSideBar: false,
      isUpdateUser: false,
      changePlanSidebar: false,
      tabIndex: 0,
      isReject: false,

      userId: null,
      showUpgradeToAnotherPartner: false,
      partnerLocalId: 0,
      isEdit: false,

      country: '',
      city: '',
      streetAddress: '',
      postalCode: '',
      phoneData: {
        countryAbbr: 'vn',
        countryCode: '',
        phone: '',
        isValid: true,
        isRequired: false,
        isEdit: false,
      },
      telegram: '',
      facebook: '',
      whatsApp: '',
      zaloNumber: '',
      industry: '',
      shopCode: null,
      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },

      required,
      verifyZaloInvalid,

      showEditRoleSideBar: false,
      listAdAccount: [],
      totalSpending: 0,

      showEditTierSideBar: false,
    }
  },

  computed: {
    ...mapGetters(['userDetail', 'userAdsAccounts', 'status', 'message', 'loading']),
    ...mapGettersAdAccountUser(['listAdAccountUser']),

    getPartnerLocalId() {
      return data => data?.belongToPartner?.partnerLocalId || 'N/A'
    },

    getPartnerEmail() {
      return data => data?.belongToPartner?.email || 'N/A'
    },

    facebookContact() {
      return _get(this.userDetail, ['contactInfo', 'facebook'])
    },
    whatsAppContact() {
      return _get(this.userDetail, ['contactInfo', 'whatsApp'])
    },
    telegramContact() {
      return _get(this.userDetail, ['contactInfo', 'telegram'])
    },

    createAt() {
      return new Date(this.userDetail.createdAt || '').toLocaleString('en-GB')
    },

    roleSelected() {
      return _get(this.userDetail, ['roles'])
    },

    tierSelected() {
      const num = _get(this.userDetail, ['tier', 'level'])
      const char1 = 'I'
      const char2 = 'V'
      let result = ''
      if (num === 0) {
        result = '0'
      } if (num > 0 && num <= 3) {
        result = char1.repeat(num)
      } if (num === 4) {
        result = char1 + char2
      } if (num === 5) {
        result = char2
      } if (num > 5 && num < 9) {
        result = char2 + char1.repeat(num - 5)
      }
      return {
        label: `Tier ${result}`,
        value: num,
      }
    },

    tax() {
      let tax = 0
      const { plans, transferFee } = this.userDetail
      if (
        plans?.transferFee?.tax !== undefined
        && plans?.transferFee?.tax !== null
      ) {
        tax = plans.transferFee.tax
      } else if (transferFee?.tax !== undefined && transferFee?.tax !== null) {
        tax = transferFee.tax
      }

      return parseFloat((tax * 100).toLocaleString('en-US'))
    },

    listTransferFee() {
      const { plans, transferFee } = this.userDetail
      if (plans?.transferFee) {
        return Object.entries(
          pick(plans.transferFee, ['payoneer', 'creditCard', 'USDT', 'BUSD']),
        ).map(([key, value]) => ({
          key,
          value: parseFloat((value * 100).toLocaleString('en-US')),
        }))
      }

      if (transferFee) {
        return Object.entries(
          pick(transferFee, ['payoneer', 'creditCard', 'USDT', 'BUSD']),
        ).map(([key, value]) => ({
          key,
          value: parseFloat((value * 100).toLocaleString('en-US')),
        }))
      }

      return this.defaultTransferFee
    },

    userBalance() {
      const amount = _get(this.userDetail, ['balance'], 0)
      return this.amountFormat(amount).toLocaleString('en-US')
    },

    showPhoneNumber() {
      let result = ''
      const { contactInfo } = this.userDetail
      if (contactInfo?.phone) {
        const phoneElm = contactInfo.phone.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = contactInfo.phone.split('|')
          result = `${countryAbbr.toUpperCase()} +${countryCode}${phone}`
        }
      }
      return result
    },

    isPartner() {
      return this.userDetail?.partnership?.status === 'active'
    },

    validPhoneInput() {
      return invalid => invalid || !this.phoneData.phone || !this.phoneData.isValid
    },

    inValidContact() {
      return !this.isVNWhiteLabel ? !(this.facebook || this.telegram || this.whatsApp) : !(this.zaloNumber)
    },

    hasEditInfo() {
      const phone = `${this.phoneData.countryAbbr}|${this.phoneData.countryCode}|${this.phoneData.phone}`
      let facebook = ''
      let isEdit = false
      if (this.facebook || this.userDetail?.contactInfo?.facebook) {
        const urlFb = 'https://www.facebook.com/'
        if (this.facebook?.includes('facebook.com')) {
          facebook = this.facebook
        } else {
          facebook = urlFb.concat(this.facebook)
        }
        isEdit = facebook !== this.userDetail.contactInfo?.facebook
      }
      return isEdit || ((phone !== this.userDetail.contactInfo?.phone)
      || (!!this.telegram && this.telegram !== this.userDetail.contactInfo?.telegram)
      || (!!this.whatsApp && this.whatsApp !== this.userDetail.contactInfo?.whatsApp)
      || (!!this.zaloNumber && this.zaloNumber !== this.userDetail.contactInfo?.zalo)
      || (!!this.industry && this.industry !== this.userDetail?.industry)
      || (!!this.shopCode && this.shopCode !== this.userDetail?.shopCode)
      || (!!this.streetAddress && this.streetAddress !== this.userDetail?.streetAddress)
      || (!!this.postalCode && this.postalCode !== this.userDetail?.postalCode)
      || (!!this.country && this.country !== this.userDetail?.country)
      || (!!this.city && this.city !== this.userDetail?.city))
    },
  },

  watch: {
    userDetail: {
      async handler(val) {
        if (val.contactInfo?.phone) {
          const [countryAbbr, countryCode, phone] = val?.contactInfo?.phone.split('|')
          this.phoneData.countryAbbr = countryAbbr || ''
          this.phoneData.countryCode = countryCode || ''
          this.phoneData.phone = phone || ''
        }
        if (val.contactInfo?.facebook) {
          const params = val.contactInfo.facebook?.split('facebook.com/')
          // eslint-disable-next-line prefer-destructuring
          this.facebook = params[1] || ''
        }
        const productLinks = val?.products?.productURLs
        if (productLinks) {
          const convertedUrls = Object.keys(productLinks).map(value => ({
            productUrl: value,
            ...productLinks[value],
          }))

          const arrSortDate = convertedUrls.sort(
            (a, b) => new Date(b.requestedAt) - new Date(a.requestedAt),
          )

          const productLinkReviewing = arrSortDate.find(
            item => item.status === 'reviewing',
          )
          this.productsData.productURL = productLinkReviewing?.productUrl || arrSortDate?.[0].productUrl
        }

        if (val) {
          this.telegram = val.contactInfo?.telegram || ''
          // this.facebook = val.contactInfo?.facebook || ''
          this.whatsApp = val.contactInfo?.whatsApp || ''
          this.zaloNumber = val.contactInfo?.zalo || ''
          this.industry = val.industry || ''
          this.shopCode = val.shopCode || ''
          this.streetAddress = val.streetAddress || ''
          this.postalCode = val.postalCode || ''
          this.country = await val.country || ''
          this.city = await val.city || ''
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['profileStatusUpdate', 'getUser', 'updateUser']),
    ...mapActionsAdAccountUser(['getListAdAccountUser']),

    async fetchUserInfo() {
      const { params } = this.$route
      await this.getUser(params)
    },

    hasRunAds(val) {
      if (val === false) {
        return 'No'
      }
      if (val === true) {
        return 'Yes'
      }
      return 'N/A'
    },

    capitalise(value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },

    subStatusVariant(status) {
      if (status === 'canceled') {
        return 'light-warning'
      }
      return 'light-success'
    },

    onChangePlan() {
      this.changePlanSidebar = true
    },

    onReject() {
      this.isReject = true
    },

    async openAssignToAnotherPartner() {
      this.showUpgradeToAnotherPartner = true
      this.partnerLocalId = this.userDetail?.belongToPartner?.partnerLocalId
      await this.getListAdAccountUser({
        // eslint-disable-next-line no-underscore-dangle
        adsAccounts: this.userAdsAccounts.map(adsAccount => adsAccount._id).join(','),
      })
      this.listAdAccount = this.listAdAccountUser
      this.totalSpending = this.listAdAccountUser.reduce((spend, item) => spend + item.spend, 0)
    },

    editUserDetail() {
      this.isEdit = true
      this.phoneData.isEdit = true
    },

    async saveEdit() {
      const success = await this.$refs.informationValidation.validate()

      const urlFb = 'https://www.facebook.com/'
      let facebookUrl = ''
      if (this.facebook?.includes('facebook.com')) {
        facebookUrl = this.facebook
      } else {
        facebookUrl = urlFb.concat(this.facebook)
      }

      if (success) {
        const {
          phone, telegram, facebook, whatsApp, zalo, industry, shopCode, country, city, streetAddress, postalCode, localUserId,
        } = this.userDetail

        const params = {
          id: localUserId,
          contactInfo: {
            ...(this.phoneData && this.phoneData !== phone && { phone: `${this.phoneData.countryAbbr}|${this.phoneData.countryCode}|${this.phoneData.phone}` }),
            ...(this.telegram && this.telegram !== telegram && { telegram: this.telegram }),
            ...(this.facebook && this.facebook !== facebook && { facebook: facebookUrl }),
            ...(this.whatsApp && this.whatsApp !== whatsApp && { whatsApp: this.whatsApp }),
            ...(this.zaloNumber && this.zaloNumber !== zalo && { zalo: this.zaloNumber }),
          },
          ...(this.industry && this.industry !== industry && { industry: this.industry }),
          ...(this.shopCode && this.shopCode !== shopCode && { shopCode: this.shopCode }),
          ...(this.country && this.country !== country && { country: this.country }),
          ...(this.city && this.city !== city && { city: this.city }),
          ...(this.streetAddress && this.streetAddress !== streetAddress && { streetAddress: this.streetAddress }),
          ...(this.postalCode && this.postalCode !== postalCode && { postalCode: this.postalCode }),
        }

        await this.updateUser(params)
        if (this.status) {
          this.getUser(params)
          this.toastSuccess('Edit user success')
          this.isEdit = false
          this.phoneData.isEdit = false
        } else {
          this.toastFailure(this.message)
        }
      }
    },

    cancelEdit() {
      this.resetData()
      this.isEdit = false
      this.phoneData.isEdit = false
    },

    async resetData() {
      await this.$refs.informationValidation.reset()
      if (this.userDetail.contactInfo?.phone) {
        const [countryAbbr, countryCode, phone] = this.userDetail.contactInfo?.phone.split('|')
        this.phoneData.countryAbbr = countryAbbr || ''
        this.phoneData.countryCode = countryCode || ''
        this.phoneData.phone = phone || ''
      } else {
        this.phoneData.phone = ''
      }
      if (this.userDetail.contactInfo?.facebook) {
        const params = this.userDetail.contactInfo?.facebook?.split('https://www.facebook.com/')
        // eslint-disable-next-line prefer-destructuring
        this.facebook = params[1] || ''
      }
      this.telegram = this.userDetail?.contactInfo?.telegram || ''
      this.whatsApp = this.userDetail?.contactInfo?.whatsApp || ''
      this.zaloNumber = this.userDetail?.contactInfo?.zalo || ''
      this.industry = await this.userDetail?.industry || ''
      this.country = await this.userDetail?.country || ''
      this.city = await this.userDetail?.city || ''
      this.streetAddress = this.userDetail?.streetAddress || ''
      this.postalCode = this.userDetail?.postalCode || ''
    },

    EditRole() {
      this.showEditRoleSideBar = true
    },

    editTier() {
      this.showEditTierSideBar = true
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.currency {
  width: 100%;

  .vs__dropdown-toggle {
    height: 100%;
    border-radius: 6px;
  }
}

.group-input {
  .b-form-tags {
    padding: 0.715rem 1rem;
    border-radius: 6px;
  }
  .b-form-tag {
    background: rgba(38, 103, 255, 0.1);
    color: #2667ff;
  }

  .b-form-tags-button {
    display: none;
  }
}

.select-country {
  .country,
  .region {
    width: 100%;
    border: 1px solid rgba(22, 33, 62, 0.1);
    border-radius: 0.357rem;
    padding: 0.438rem 1rem;

    &:focus-visible {
      outline: none;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent;
    background-image: url('data:image/svg+xml;utf8,<svg data-v-5d7634b4="" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline data-v-5d7634b4="" points="6 9 12 15 18 9"></polyline></svg>');
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 50%;
  }
}
.role-tag {
  .vs__selected {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1);
  }
  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1) !important;
  }
}
</style>

<style lang="scss" scoped>
.btn-block-user {
  padding: 10px 18.4px;
  border: 1px solid #e0e0e5;
  border-radius: 6px;
  transform: rotate(0.01deg);
  display: flex;
  align-items: center;

  .icon-btn {
    margin-right: 0.5rem;
  }
}

.status-profile {
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  margin-bottom: 24px;
  margin-top: 8px;
  padding-bottom: 24px;
  .status {
    text-transform: capitalize;
    color: rgba(122, 179, 76, 1);
    background-color: rgba(239, 250, 230, 1);
    padding: 6px 19px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: 700;
  }

  .rejected {
    color: rgba(240, 37, 72, 1);
    background-color: rgba(240, 37, 72, 0.1);
  }
}

.group-input {
  margin-bottom: 24px;
}

.btn-control {
  display: flex;
  justify-content: end;
  align-items: center;

  .btn-decline,
  .btn-continue {
    width: max-content;
    font-size: 14px;
    margin: 0 6px;
  }

  .btn-decline {
    background: rgba(225, 28, 78, 0.1) !important;
    color: #e11c4e !important;
    border: none !important;
  }
}

.btn-edit {
  margin-bottom: 1rem;
  height: 43px;
}
</style>
