<template>
  <div class="partner-ticket-container">
    <div>
      <!-- filter -->
      <b-card-code title="Filters">
        <!-- advance search input -->
        <b-row>
          <b-col md="9">
            <b-form-group>
              <label>Search user by field</label>
              <b-input-group class="input-filter">
                <b-input-group-prepend>
                  <b-form-select
                    v-model="searchSelected"
                    class="select-search-custom"
                    :options="searchOptions"
                    @input="handleChangeSearchType"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="searchTerm"
                  :placeholder=" searchSelected === 'email' ? 'Search by email' : 'Search by ID'"
                  :type="
                    searchSelected === 'localUserId' ||
                      searchSelected === 'adsAccountId' || searchSelected === 'referralCode'
                      ? 'number'
                      : 'text'
                  "
                  class="d-inline-block"
                  @wheel="handleMouseWheelInput"
                  @input="handleSearch"
                  @keyup.enter="handleSubmitSearch"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <btn-loading
              v-if="true"
              class="btn-export btn-partner"
              type="submit"
              block
              variant="outline-primary"
              :loading="false"
              @click="submitExportData"
            >
              Export data
              <feather-icon
                icon="UploadIcon"
                size="16"
              />
            </btn-loading>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label> Country </label>

              <v-select
                v-model="countrySelected"
                class="input-height"
                placeholder="Select country"
                :options="countryOptions"
                @input="handleCountryChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isEcomdyPlatform && hasSubscription"
            md="4"
            class="
              country-tag
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <b-form-group class="w-100">
              <label>
                Package member
              </label>
              <v-select
                v-model="packetSelected"
                class="input-height"
                placeholder="Select Package"
                :options="packetOptions"
                @input="handlePacketChange"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="4">
            <b-form-group>
              <label>
                Current plan
              </label>

              <b-form-select
                v-model="planSelected"
                :options="planOptions"
                class="input-height"
                @input="handlePlanChange"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label> Account status </label>

              <b-form-select
                v-model="statusSelected"
                :options="statusOptions"
                class="input-height"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col md="3">
            <b-form-group>
              <label>Assign At</label>

              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control input-height"
                  placeholder="Select date"
                  :config="configDate"
                  @on-close="handleDateChange"
                />
                <b-input-group-append
                  v-if="startDate && endDate"
                  is-text
                  class="close-icon"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearDate"
                  />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col> -->
        </b-row>
      </b-card-code>

      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="handleColumns"
        :rows="partners"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: totalPartner > 0,
        }"
        :sort-options="{
          enabled: totalPartner > 0,
        }"
        :is-loading="loading"
        @on-sort-change="onSortChange"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: User Info -->
          <a
            v-if="props.column.field === 'email'"
            class="d-flex align-items-center cursor-pointer"
            target="_blank"
            :href="`/partner/${props.row.localUserId}`"
          >
            <b-img
              :id="`avt-user-${props.row._id}`"
              rounded
              class="ads-avt-img mr-1"
              :src="getAvatar(props.row.registerFromService)"
            />
            <b-tooltip
              v-if="isEcomdyPlatform && props.row.registerFromService"
              placement="top"
              triggers="hover"
              :target="`avt-user-${props.row._id}`"
            >
              {{ getUserPlatform(props.row.registerFromService) }}
            </b-tooltip>
            <div>
              <p class="mb-0 text-secondary font-weight-bold mb-0 underline">
                {{ props.row.fullName || "" }}
                <span
                  v-if="props.row.whiteList"
                ><b-img
                  rounded
                  class="ads-avt-img mr-1"
                  :src="
                    require('@/assets/images/pages/payment/icon-white-list.svg')
                  "
                />
                </span>
              </p>
              <span class="text-placeholder">{{ props.row.email || "" }}</span>
            </div>
          </a>

          <!-- Column: Reff -->
          <span
            v-else-if="props.column.field === 'referralTracking.receivedMoney'"
          >
            {{ currencyFormat(receivedMoney(props.row)) }}
          </span>

          <!-- Column: packageMember -->
          <span v-else-if="props.column.field === 'packageMember'">
            {{ packetForMember(props.row) }}
          </span>

          <!-- Column: Balance -->
          <span v-else-if="props.column.field === 'balance'">
            ${{ userBalance(props.row.balance) }}
          </span>
          <!-- Column: adsAccounts -->
          <span v-else-if="props.column.field === 'adsAccounts'">
            {{
              props.row.adsAccounts && props.row.adsAccounts.length
                ? props.row.adsAccounts.length
                : 0
            }}
          </span>
          <!-- Column: plans.type -->
          <span v-else-if="props.column.field === 'plans.type'">
            {{
              props.row.plans && props.row.plans.type
                ? props.row.plans.type
                : "N/A"
            }}
          </span>

          <!-- Column: createdDate -->
          <div
            v-else-if="props.column.field === 'partnership.assignedAt'"
            class="d-flex flex-column"
          >
            <span>{{ createdDate(props.row.partnership.assignedAt) }}</span>
          </div>

          <!-- Column: partnership.statistic.totalMembers -->
          <div
            v-else-if="
              props.column.field === 'partnership.statistic.totalMembers'
            "
            class="d-flex flex-column"
          >
            <span v-if="totalMembers(props.row)">{{
              totalMembers(props.row)
            }}</span>
            <span v-else>N/A</span>
          </div>

          <!-- Column: region -->
          <div
            v-else-if="props.column.field === 'Region Management'"
            class="d-flex flex-column"
          >
            <span>{{ getRegionPartner(props.row) }}</span>
          </div>

          <!-- Column: country -->
          <div
            v-else-if="props.column.field === 'country'"
            class="d-flex flex-column"
          >
            <span v-if="props.row.country">{{ props.row.country }}</span>
            <span v-else>N/A</span>
          </div>

          <!-- Column: Status -->
          <span
            v-else-if="props.column.field === 'status'"
            class="position-relative"
          >
            <feather-icon
              v-if="props.row.status === 'blocked'"
              icon="EyeIcon"
              size="16"
              class="text-body align-middle cursor-pointer icon-reason-block"
              @click="openReasonBlock(props.row)"
            />
            <b-badge
              :variant="statusVariant(props.row.status)"
              class="text-capitalize"
            >
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: product status -->
          <span
            v-else-if="props.column.field === 'products.status'"
            class="position-relative"
          >
            <div
              v-if="props.row.products && props.row.products.status"
              class="status-product-links"
              :class="statusVariantRejected(props.row.products.status)"
            >
              <span class="content-status">{{
                statusContent(props.row.products.status)
              }}</span>
            </div>
          </span>

          <!-- Column: Actions -->
          <!-- <div v-else-if="props.column.field === 'actions'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              boundary="viewport"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="isUserActive(props.row.status)"
                @click="openBlockModal(props.row)"
              >
                <span>Block</span>
              </b-dropdown-item>

              <div v-else>
                <b-dropdown-item @click="onActiveUser(props.row.localUserId)">
                  <span>
                    Active
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="openBlockReasonModal(props.row)">
                  <span>Add reason block</span>
                </b-dropdown-item>
              </div>

              <b-dropdown-item @click="openSideBar(props.row)">
                <span>Balance Change</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.partnership && props.row.partnership.status ==='active'"
                @click="openRejectPartnerModal(props.row)"
              >
                <span>Reject Partner</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="openAssignPartnerSideBar(props.row)"
              >
                <span>Assign Partner</span>
              </b-dropdown-item>
            </b-dropdown>
          </div> -->

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalPartner > 0"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap mt-1 justify-content-between">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> of {{ totalPartner }} user(s) </span>
            </div>
            <div>
              <b-pagination
                :total-rows="totalPartner"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>

        <div
          slot="emptystate"
          class="text-center"
        >
          <no-data-table-image class="my-5" />
        </div>
      </vue-good-table>
    </div>
    <detail-blocked-user-modal
      ref="open-modal-reason-block"
      :user-data="userSelected === null ? {} : userSelected"
    />

    <export-data-modal
      ref="open-export-data-modal"
      :params-filter="paramsFilter"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import {
  BPagination,
  BSpinner,
  BImg,
  BBadge,
  BFormSelect,
  // BDropdown,
  // BDropdownItem,
  BFormGroup,
  BFormInput,
  // BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BInputGroup,
  VBModal,
  VBTooltip, BTooltip,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import {
  COUNTRIES,
  USER_STATUS,
  PROFILE_STATUS, REGISTER_FROM_SERVICE,
} from '@/constants'
import vSelect from 'vue-select'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import { VueGoodTable } from 'vue-good-table'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import DetailBlockedUserModal from '@/views/user/components/DetailBlockedUserModal.vue'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import envMixin from '@/mixins/envMixin'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import axiosLib from 'axios'
import { debounce } from 'lodash'
import usersMixin from '@/mixins/usersMixin'
import ExportDataModal from './ExportDataModal.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('partner')
const { mapActions: mapActionsPacket, mapGetters: mapGettersPacket } = createNamespacedHelpers('marketPackage')

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

export default {
  components: {
    BTooltip,
    BPagination,
    BSpinner,
    BImg,
    BBadge,
    BFormSelect,
    // BDropdown,
    // BDropdownItem,
    BFormGroup,
    BFormInput,
    // BInputGroupAppend,
    BInputGroupPrepend,
    BRow,
    BCol,
    BInputGroup,
    VueGoodTable,
    NoDataTableImage,
    BtnLoading,
    // VBModal,

    // flatPickr,
    vSelect,

    BCardCode,
    DetailBlockedUserModal,
    ExportDataModal,

  },

  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [toastification, envMixin, numberFormatMixin, usersMixin],

  data() {
    return {
      userSelected: null,
      partnerSelected: {},
      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'Ads account ID',
          value: 'adsAccountId',
        },
        {
          text: 'User email',
          value: 'email',
        },
        {
          text: 'Referral code',
          value: 'refCode',
        },
      ],

      // status filter
      statusSelected: null,
      statusOptions: [
        {
          text: 'All status',
          value: null,
        },
        {
          text: 'Activated',
          value: USER_STATUS.ACTIVE,
        },
        {
          text: 'Blocked',
          value: USER_STATUS.BLOCKED,
        },
      ],

      // plan filter need add more
      planSelected: null,
      planOptions: [
        {
          text: 'All plan',
          value: null,
        },
        {
          text: 'Black Agency',
          value: 'black_agency',
        },
        {
          text: 'White Agency',
          value: 'white_agency',
        },
      ],

      currentPage: 1,
      searchTerm: '',

      packetSelected: null,

      // sort
      sortTerm: {
        field: 'localUserId',
        type: 'desc',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50', '100'],

      countrySelected: null,
      countryOptions: COUNTRIES.map(item => item.name),

      paramsFilter: {},
    }
  },

  computed: {
    REGISTER_FROM_SERVICE() {
      return REGISTER_FROM_SERVICE
    },
    ...mapGetters(['listPartner', 'message', 'status', 'loading']),
    ...mapGettersPacket(['listMarketPackage']),

    columns() {
      return [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'User info',
          field: 'email',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Package member',
          field: 'packageMember',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasSubscription,
        },
        // {
        //   label: 'Plan partner',
        //   field: 'plans.type',
        //   thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
        //   tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        // },
        {
          label: 'Region Management',
          field: 'partnership.region',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Country',
          field: 'country',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Referral code',
          field: 'referralTracking.code',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasReferral,
        },
        {
          label: 'Assign At',
          field: 'partnership.assignedAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Product link status',
          field: 'products.status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ]
    },

    packetOptions() {
      return this.listMarketPackage.content.map(item => ({
        label: item.policies[0].plans?.type,
        value: item.policies[0].plans?.type,
      }))
    },

    getRegionPartner() {
      return data => data?.partnership?.region || 'N/A'
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    statusVariant() {
      const statusColor = {
        [USER_STATUS.ACTIVE]: 'light-success',
        // [USER_STATUS.BLOCKED]: 'light-danger',
      }

      return status => statusColor[status]
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    userInfo() {
      return img => img || require('@/assets/images/common/ic-user-info.svg')
    },

    partners() {
      return _get(this.listPartner, ['content'], [])
    },

    totalPartner() {
      return _get(this.listPartner, ['paging', 'total'], 0)
    },

    totalMembers() {
      return val => _get(val, ['partnership', 'statistic', 'totalMembers'], 0)
    },

    statusVariantRejected() {
      const statusColor = {
        [PROFILE_STATUS.APPROVED]: 'approved',
        [PROFILE_STATUS.REJECTED]: 'rejected',
        [PROFILE_STATUS.REVIEWING]: 'reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found',
      }

      return status => statusColor[status]
    },

    statusContent() {
      const statusContent = {
        [PROFILE_STATUS.APPROVED]: 'Approved',
        [PROFILE_STATUS.REJECTED]: 'Rejected',
        [PROFILE_STATUS.REVIEWING]: 'Reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'Not Found',
      }

      return status => statusContent[status]
    },

    packetForMember() {
      return val => val?.partnership?.packageIdForMember?.name || 'N/A'
    },

    noFilter() {
      return (
        this.searchTerm === ''
        && this.countrySelected === null
        && this.planSelected === null
        && this.statusSelected === null
        && this.packetSelected === null
      )
    },

    handleColumns() {
      if (this.isEcomdyPlatform) {
        return this.columns
      }
      return this.columns.filter(item => item.field !== 'partnership.region')
    },
  },

  created() {
    this.fetchListPackage()
    this.fetchListPartner()
  },

  beforeDestroy() {
    abort()
  },

  methods: {
    ...mapActions(['getListPartner']),
    ...mapActionsPacket(['getListPackage']),

    openReasonBlock(data) {
      this.userSelected = data
      this.$refs['open-modal-reason-block'].showModal()
    },

    openExportDataModal(data) {
      this.paramsFilter = data
      this.$refs['open-export-data-modal'].showModal()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListPartner()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchListPartner()
    },

    async handleCountryChange() {
      this.currentPage = 1
      await this.fetchListPartner()
    },

    async handlePlanChange() {
      this.currentPage = 1
      await this.fetchListPartner()
    },

    handleSearch: debounce(async function (searching) {
      if (searching === '' || ((searching.length > 3 && this.searchSelected !== 'email') || this.validateEmail(this.searchTerm))) {
        await this.fetchListPartner()
      }
    }, 1000),

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchListPartner()
      }
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchListPartner()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListPartner()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchListPartner()
    },

    // async clearDate() {
    //   this.rangeDate = null
    //   this.startDate = null
    //   this.endDate = null
    //   this.dateSelected = null
    //   await this.fetchListPartner()
    // },

    async fetchListPackage() {
      const params = {
        page: this.currentPage,
        size: 50,
      }
      await this.getListPackage(params)
    },

    async exportExcel() {
      const params = {
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.statusSelected === USER_STATUS.BLOCKED
              ? 'authentication.blockedAt'
              : this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.statusSelected && { status: this.statusSelected }),
        ...(this.planSelected && { planType: this.planSelected }),
        ...(this.countrySelected && { country: this.countrySelected }),
        ...(this.packetSelected && { packageType: this.packetSelected.value }),
      }

      this.openExportDataModal(params)
    },

    submitExportData() {
      this.exportExcel()
    },

    async handlePacketChange() {
      this.currentPage = 1
      await this.fetchListPartner()
    },

    async fetchListPartner() {
      abort()
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.statusSelected === USER_STATUS.BLOCKED
              ? 'authentication.blockedAt'
              : this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.statusSelected && { status: this.statusSelected }),
        ...(this.planSelected && { planType: this.planSelected }),
        ...(this.countrySelected && { country: this.countrySelected }),
        ...(this.packetSelected && { packageType: this.packetSelected.value }),
      }
      await this.getListPartner({ params, cancelToken: cancelToken.token })
      if (!this.status && this.message) {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.content-reason {
  p {
    margin: 0;
  }
}

.country-tag {
  /* .b-form-tags {
    padding: 0;
    border: none;
  } */

  .vs__selected {
    background: rgba(38, 103, 255, 0.1);
    color: rgb(38, 103, 255);
  }

  .vs__dropdown-option--highlight {
    background: rgba(38, 103, 255, 0.1);
    color: rgb(38, 103, 255) !important;
  }
}

.input-height {
  .vs__dropdown-toggle {
    padding: 2.09px 0 3.5px 0;
  }
}
</style>

<style lang="scss" scoped>
.icon-reason-block {
  position: absolute;
  top: -13px;
  right: -10px;
}
.input-height {
  height: 43px;
}

.status-product-links {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  .content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }

  &.not-found {
    background-color: #82868a1a;
    color: #82868a;
  }
}

.btn-partner {
  margin-top: 27.549px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.btn-export {
    height: 43px;
  }
}
</style>
