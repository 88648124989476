<template>
  <div>
    <div>
      <b-card-code class="filter-form">
        <b-row>
          <b-col md="5">
            <b-form-group>
              <label>Search user by field</label>
              <b-input-group>
                <b-input-group-prepend>
                  <b-form-select
                    v-model="searchSelected"
                    class="select-search-custom"
                    :options="searchOptions"
                    @input="handleChangeSearchType"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="searchTerm"
                  :placeholder="
                    searchSelected === 'email'
                      ? 'Search by email'
                      : 'Search by ID'
                  "
                  :type="
                    searchSelected === 'localUserId' ||
                      searchSelected === 'adsAccountId'
                      ? 'number'
                      : 'text'
                  "
                  class="d-inline-block"
                  @input="handleSearch"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group class="w-100">
              <label> Verify status </label>
              <v-select
                v-model="verifyStatusSelected"
                class="input-height"
                placeholder="Select status"
                :options="verifyStatusOptions"
                @input="handleVerifyStatusChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <btn-loading
              class="btn-export btn-partner"
              type="submit"
              block
              variant="outline-primary"
              :loading="loadingDown"
              @click="submitExportData"
            >
              Export data
              <feather-icon
                icon="UploadIcon"
                size="16"
              />
            </btn-loading>
          </b-col>
        </b-row>
        <b-row>
          <!-- <b-col md="4">
            <b-form-group>
              <label> Current plan </label>

              <b-form-select
                v-model="planSelected"
                :options="planOptions"
                @input="handlePlanChange"
              />
            </b-form-group>
          </b-col> -->
          <b-col md="4">
            <b-form-group>
              <label> Account status </label>

              <b-form-select
                v-model="statusSelected"
                @input="handleStatusChange"
              >
                <b-form-select-option
                  v-for="status in statusOptions"
                  :key="status.value"
                  :value="status.value"
                >
                  {{ status.text }}
                </b-form-select-option>
                <b-form-select-option-group label="Blocked">
                  <b-form-select-option
                    v-for="(statusBlock, index) in statusBlockOptions"
                    :key="index"
                    :value="statusBlock.value"
                  >
                    {{ statusBlock.text }}
                  </b-form-select-option>
                </b-form-select-option-group>
              </b-form-select>
            </b-form-group>
          </b-col>

          <!--          created at-->
          <b-col md="4">
            <b-form-group>
              <label>Created At</label>

              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="rangeCreatedDate"
                  class="form-control"
                  placeholder="Select date"
                  :config="configDate"
                  @on-close="handleCreatedDateChange"
                />
                <b-input-group-append
                  v-if="starCreatedDate && endCreatedDate"
                  is-text
                  class="close-icon"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearCreatedDate"
                  />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!--          spending at-->
          <b-col md="4">
            <b-form-group>
              <label>Spending At</label>

              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  placeholder="Select date"
                  :config="configDate"
                  @on-close="handleDateChange"
                />
                <b-input-group-append
                  v-if="startDate && endDate"
                  is-text
                  class="close-icon"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearDate"
                  />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-code>

      <!-- table  -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="listMember"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: totalUser > 0,
        }"
        :sort-options="{
          enabled: totalUser > 0,
        }"
        :is-loading="loading"
        @on-sort-change="onSortChange"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: User Info -->
          <div
            v-if="props.column.field === 'email'"
            class="d-flex align-items-center cursor-pointer"
            @click="editUser(props.row)"
          >
            <b-img
              rounded
              class="ads-avt-img"
              :src="userInfo()"
            />
            <div>
              <p class="mb-0">
                ID: {{ props.row.localUserId || '' }}
              </p>
              <span class="text-placeholder">{{ props.row.email || '' }}</span>
            </div>
          </div>

          <!-- Column: Balance -->
          <span v-else-if="props.column.field === 'balance'">
            ${{ userBalance(props.row.balance) }}
          </span>

          <!-- Column: monthlySpending -->
          <div v-else-if="props.column.field === 'monthlySpending'">
            <div
              v-if="props.row.monthlySpending"
              class="d-flex align-items-center justify-content-between"
            >
              <span>{{
                `${currentMonthlySpending(props.row)}
                (${monthOfMonthlySpending(props.row)})`
              }}</span>
              <feather-icon
                v-if="listMonthlySpending(props.row.monthlySpending).length > 1"
                v-b-tooltip.hover.top="'View detail'"
                icon="ExternalLinkIcon"
                size="16"
                class="align-middle cursor-pointer"
                @click="openMonthlySpendingModal(listMonthlySpending(props.row.monthlySpending))"
              />
            </div>
            <span v-else> N/A </span>
          </div>

          <!-- Column: totalSpending -->
          <div v-else-if="props.column.field === 'totalSpending'">
            <span>
              {{ currencyFormat(props.row.totalSpending) }}
            </span>
          </div>

          <!-- Column: totalDeposit -->
          <div v-else-if="props.column.field === 'totalDeposit'">
            <span>
              {{ currencyFormat(props.row.totalDeposit) }}
            </span>
          </div>

          <!-- Column: adsAccounts -->
          <span v-else-if="props.column.field === 'adsAccounts'">
            {{
              props.row.adsAccounts && props.row.adsAccounts.length
                ? props.row.adsAccounts.length
                : 0
            }}
          </span>
          <!-- Column: currentPlan -->
          <span v-else-if="props.column.field === 'currentPlan'">
            {{
              props.row.plans && props.row.plans.type
                ? `${props.row.plans.type} (${
                  (props.row.plans.subscription &&
                    props.row.plans.subscription.status) ||
                  'N/A'
                })`
                : 'N/A'
            }}
          </span>

          <!-- Column: createdDate -->
          <div
            v-else-if="props.column.field === 'createdAt'"
            class="d-flex flex-column"
          >
            <span>{{ createdDate(props.row) }}</span>
          </div>

          <!-- Column: createdDate -->
          <div
            v-else-if="props.column.field === 'belongToPartner.requestedAt'"
            class="d-flex flex-column"
          >
            <span>{{ requestDate(props.row) }}</span>
          </div>

          <!-- Column: Status -->
          <span
            v-else-if="props.column.field === 'status'"
            class="position-relative"
          >
            <feather-icon
              v-if="props.row.status === 'blocked'"
              icon="EyeIcon"
              size="16"
              class="text-body align-middle cursor-pointer icon-reason-block"
              @click="openReasonBlock(props.row)"
            />
            <b-badge
              :variant="statusVariant(props.row.status)"
              class="text-capitalize font-14"
            >
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- contactInfo.verification.status -->
          <div
            v-else-if="props.column.field === 'contactInfo.verification.status'"
            class="position-relative"
          >
            <feather-icon
              v-if="statusUserInfo(props.row) === 'rejected'"
              icon="EyeIcon"
              size="16"
              class="text-body align-middle cursor-pointer icon-reason-block"
              @click="openVerifyReasonBlock(props.row)"
            />
            <span
              v-if="statusUserInfo(props.row)"
              class="text-status"
              :class="statusVariantValidate(statusUserInfo(props.row))"
            >
              {{ statusUserInfo(props.row) }}
            </span>
            <span v-else> N/A </span>
          </div>

          <!-- Column: product status -->
          <span
            v-else-if="props.column.field === 'products.status'"
            class="position-relative"
          >
            <div
              v-if="props.row.products && props.row.products.status"
              class="status-product-links"
              :class="statusVariantRejected(props.row.products.status)"
            >
              <span class="content-status">{{
                statusContent(props.row.products.status)
              }}</span>
            </div>
          </span>
          <!-- Column: product status -->
          <div v-else-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="openAssignToAnotherPartner(props.row)">
                <span>Assign to another partner</span>
              </b-dropdown-item>
              <b-dropdown-item @click="handleRemoveMember(props.row)">
                <span>Remove member</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalUser > 0"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap mt-1 justify-content-between">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> of {{ totalUser }} user(s) </span>
            </div>
            <div>
              <b-pagination
                :total-rows="totalUser"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>

        <div
          v-if="errorGetListMemberPartner"
          slot="emptystate"
        >
          <p class="text-error">
            {{ errorGetListMemberPartner }}
          </p>
        </div>
      </vue-good-table>
    </div>

    <assign-to-another-partner
      :local-user-id="localUserId"
      :partner-local-id="partnerLocalId"
      :list-ad-account="listAdAccount"
      :total-spending="totalSpendingError"
      :show-side-bar.sync="showUpgradeToAnotherPartner"
      @fetch-user-info="fetchUser"
    />

    <detail-blocked-user-modal
      ref="open-modal-reason-block"
      :user-data="userSelected || {}"
    />

    <verify-reason-block-modal
      ref="open-modal-verify-reason-block"
      :user-data="userSelected || {}"
    />

    <detail-monthly-spending-modal
      ref="open-monthly-spending-modal"
      :amount-data="amountData || []"
    />
  </div>
</template>
<script>
/* eslint-disable global-require,implicit-arrow-linebreak */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import moment from 'moment'
import {
  DATE_RANGE, USER_STATUS, PROFILE_STATUS, STATUS_USER_INFO,
} from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'

import {
  BPagination,
  BSpinner,
  BImg,
  BBadge,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BInputGroup,
  VBModal,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BFormSelectOptionGroup,
  BFormSelectOption,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import axios from '@axios'
import DetailBlockedUserModal from '@/views/user/components/DetailBlockedUserModal.vue'
import VerifyReasonBlockModal from '@/views/partner/partnerDetail/VerifyReasonBlockModal.vue'
import envMixin from '@/mixins/envMixin'
import AssignToAnotherPartner from '../../user/components/AssignToAnotherPartner.vue'
import DetailMonthlySpendingModal from './DetailMonthlySpendingModal.vue'

const { mapGetters: mapGettersAuth } = createNamespacedHelpers('user')
const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('partner')

export default {
  components: {
    VerifyReasonBlockModal,
    BFormSelectOption,
    BFormSelectOptionGroup,
    BPagination,
    BFormSelect,
    BSpinner,
    BImg,
    BBadge,
    BFormGroup,
    BtnLoading,
    BFormInput,
    VueGoodTable,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BRow,
    BCol,
    flatPickr,
    BCardCode,
    BDropdown,
    BDropdownItem,
    AssignToAnotherPartner,
    DetailBlockedUserModal,
    DetailMonthlySpendingModal,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, envMixin],

  props: {
    errorGetListMemberPartner: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      loadingDown: false,

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: 'localUserId',
        type: 'desc',
      },

      // per page
      pageLength: 20,
      pages: ['10', '20', '50'],

      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,

      rangeCreatedDate: null,
      starCreatedDate: null,
      endCreatedDate: null,
      configDate: {
        mode: 'range',
      },

      // status filter
      statusSelected: null,
      statusBlockOptions: [
        {
          text: 'All blocked',
          value: USER_STATUS.BLOCKED,
        },
        {
          text: 'Admin blocked',
          value: USER_STATUS.BLOCKED_BY_ADMIN,
        },
        {
          text: 'Recharge failed many times',
          value: USER_STATUS.BLOCKED_BY_RECHARGE_FAILED,
        },
        {
          text: 'IP violation',
          value: USER_STATUS.BLOCKED_BY_IP_VIOLATION,
        },
        {
          text: 'Domain violation',
          value: USER_STATUS.BLOCKED_BY_DOMAIN_VIOLATION,
        },
        {
          text: 'Contact violation',
          value: USER_STATUS.BLOCKED_BY_CONTACT_VIOLATION,
        },
        {
          text: 'Payment violation',
          value: USER_STATUS.BLOCKED_BY_PAYMENT_VIOLATION,
        },
      ],
      statusOptions: [
        {
          text: 'All status',
          value: null,
        },
        {
          text: 'Activated',
          value: USER_STATUS.ACTIVE,
        },
      ],

      verifyStatusSelected: null,
      verifyStatusOptions: [
        {
          label: 'All Status',
          value: null,
        },
        {
          label: 'Rejected',
          value: STATUS_USER_INFO.REJECTED,
        },
        {
          label: 'Reviewing',
          value: STATUS_USER_INFO.REVIEWING,
        },
        {
          label: 'Approved',
          value: STATUS_USER_INFO.APPROVED,
        },
      ],

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,
      openAddFundModal: false,
      ticketComment: '',
      currentTicket: '',

      userSelected: null,

      // sidebar
      showSideBar: false,
      showAddNewUserSideBar: false,
      showAssignPartnerSideBar: false,

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'Ads account ID',
          value: 'adsAccountId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      localUserId: null,
      partnerLocalId: null,
      listAdAccount: [],
      totalSpendingError: 0,
      showUpgradeToAnotherPartner: false,
      listMemberRemove: [],
      amountData: [],
    }
  },

  computed: {
    ...mapGetters(['listMemberPartner', 'listAdAccountUser', 'message', 'status', 'loading']),
    ...mapGettersAuth(['userDetail']),

    columns() {
      return [
        {
          label: 'User info',
          field: 'email',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Monthly spending',
          field: 'monthlySpending',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass:
            'p-1 border-left-0 border-right-0 align-middle col-monthly-spending',
        },
        {
          label: 'Total spending',
          field: 'totalSpending',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Total deposit',
          field: 'totalDeposit',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Ads accounts',
          field: 'adsAccounts',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Current plan',
          field: 'currentPlan',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasSubscription,
        },
        {
          label: 'Create At',
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Assign At',
          field: 'belongToPartner.requestedAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Verification Status',
          field: 'contactInfo.verification.status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Product link',
          field: 'products.status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.action'),
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ]
    },

    totalUser() {
      return _get(this.listMemberPartner, ['paging', 'total'], 0)
    },

    currentMonthlySpending() {
      return data => this.currencyFormat(this.lastItem(this.listMonthlySpending(data?.monthlySpending))?.value)
    },

    monthOfMonthlySpending() {
      return data => this.lastItem(this.listMonthlySpending(data?.monthlySpending))?.month || 'N/A'
    },

    listMember() {
      return _get(this.listMemberPartner, ['content'], [])
    },

    statusVariant() {
      const statusColor = {
        [USER_STATUS.ACTIVE]: 'light-success',
        [USER_STATUS.BLOCKED]: 'light-danger',
      }

      return status => statusColor[status]
    },
    statusVariantRejected() {
      const statusColor = {
        [PROFILE_STATUS.APPROVED]: 'approved',
        [PROFILE_STATUS.REJECTED]: 'rejected',
        [PROFILE_STATUS.REVIEWING]: 'reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found',
      }

      return status => statusColor[status]
    },

    statusContent() {
      const statusContent = {
        [PROFILE_STATUS.APPROVED]: 'Approved',
        [PROFILE_STATUS.REJECTED]: 'Rejected',
        [PROFILE_STATUS.REVIEWING]: 'Reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'Not Found',
      }

      return status => statusContent[status]
    },

    userInfo() {
      return img => img || require('@/assets/images/common/ic-user-info.svg')
    },

    createdDate() {
      return value => (value ? new Date(value?.createdAt).toLocaleString('en-GB') : '')
    },

    requestDate() {
      return value => (value ? new Date(value?.belongToPartner?.requestedAt).toLocaleString('en-GB') : '')
    },

    isUserActive() {
      return stt => stt === USER_STATUS.ACTIVE
    },

    receivedMoney() {
      return data => _get(data, ['referralTracking', 'receivedMoney'])
    },

    userMongoId() {
      // eslint-disable-next-line no-underscore-dangle
      return this.userDetail?._id
    },

    noFilter() {
      return (
        this.searchTerm === ''
        && this.verifyStatusSelected === null
        && this.statusSelected === null
        && this.rangeDate === null
        && this.rangeCreatedDate === null
      )
    },

    lastItem() {
      return data => {
        let listCurrentItem = []
        if (data.length > 1) {
          listCurrentItem = data.sort((a, b) => {
            const c = new Date(a.month).toISOString()
            const d = new Date(b.month).toISOString()
            return Date.parse(c) - Date.parse(d)
          })
        } else {
          listCurrentItem = data
        }
        return listCurrentItem[listCurrentItem.length - 1]
      }
    },

    statusVariantValidate() {
      const statusColor = {
        [STATUS_USER_INFO.NOT_FOUND]: 'not-found',
        [STATUS_USER_INFO.REJECTED]: 'rejected',
        [STATUS_USER_INFO.REVIEWING]: 'reviewing',
        [STATUS_USER_INFO.APPROVED]: 'approved',
      }

      return status => statusColor[status]
    },

    statusUserInfo() {
      const statusInfo = {
        [STATUS_USER_INFO.NOT_FOUND]: 'not-found',
        [STATUS_USER_INFO.REVIEWING]: 'reviewing',
        [STATUS_USER_INFO.REJECTED]: 'rejected',
        [STATUS_USER_INFO.APPROVED]: 'approved',
      }

      return info => statusInfo[info?.contactInfo?.verification?.status]
    },
  },

  methods: {
    ...mapActions(['getListMemberPartner', 'removeMember', 'getListAdAccountUser']),

    listMonthlySpending(val) {
      let data = []
      Object.entries(val)
        .forEach(([key, value]) => {
          // console.log(new Date(key).toLocaleString('en-us', { month: 'short', year: 'numeric' }))
          data.push({
            value,
            month: key,
          })
        })

      data = data.sort((a, b) =>
        new Date(b.month) - new Date(a.month))

      return data
    },

    async onActiveUser(localUserId) {
      const params = {
        status: USER_STATUS.ACTIVE,
        id: localUserId,
      }
      await this.activeUser(params)
      if (this.status) {
        this.toastSuccess('Active user success')
        this.updateUserSelected()
      } else {
        this.toastFailure(this.message)
      }
    },

    async fetchUser() {
      if (
        this.searchTerm
        && !this.validateEmail(this.searchTerm)
        && this.searchSelected === 'email'
      ) {
        return
      }

      const localPartnerId = this.userDetail.localUserId || this.$route.params?.id

      const params = {
        id: localPartnerId,
        page: this.currentPage,
        size: this.pageLength,
        _id: this.userMongoId,
        ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}belongToPartner.requestedAt` }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.startDate && { spendingFromDate: this.startDate }),
        ...(this.endDate && { spendingToDate: this.endDate }),
        ...(this.statusSelected && { status: this.statusSelected }),
        ...(this.starCreatedDate && { from: this.starCreatedDate }),
        ...(this.endCreatedDate && { to: this.endCreatedDate }),
        ...(this.verifyStatusSelected && { verifyStatus: this.verifyStatusSelected.value }),

      }
      await this.getListMemberPartner(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchUser()
      } else if (searching.length > 3 && this.searchSelected !== 'email') {
        this.debounce = setTimeout(() => {
          this.fetchUser()
        }, 600)
      } else if (this.searchSelected === 'email') {
        if (this.validateEmail(this.searchTerm)) {
          this.debounce = setTimeout(() => {
            this.fetchUser()
          }, 1000)
        }
      }
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchUser()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchUser()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchUser()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchUser()
    },

    async handleDateChange(selectedDates) {
      this.rangeDate = selectedDates
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0]))
          .format(
            'YYYY-MM-DD',
          )
        this.endDate = moment(new Date(selectedDates[1]))
          .format('YYYY-MM-DD')
        await this.fetchUser()
      }
    },

    async handleCreatedDateChange(selectedDates) {
      this.rangeCreatedDate = selectedDates
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.starCreatedDate = moment(new Date(selectedDates[0]))
          .format(
            'YYYY-MM-DD',
          )
        this.endCreatedDate = moment(new Date(selectedDates[1]))
          .format('YYYY-MM-DD')
        await this.fetchUser()
      }
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null
      await this.fetchUser()
    },

    async clearCreatedDate() {
      this.rangeCreatedDate = null
      this.starCreatedDate = null
      this.endCreatedDate = null
      await this.fetchUser()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchUser()
    },

    async handleVerifyStatusChange() {
      this.currentPage = 1
      await this.fetchUser()
    },
    // async handlePlanChange() {
    //   this.currentPage = 1
    //   await this.fetchUser()
    // },

    async handlePickDateChange(date) {
      const { value } = date
      if (value !== 'ALL') {
        const start = value.start.format('YYYY-MM-DD')
        const end = value.end.format('YYYY-MM-DD')
        this.startDate = start
        this.endDate = end
        this.rangeDate = `${start} to ${end}`
        this.fetchUser()
      } else {
        this.clearDate()
      }
    },

    editUser(data) {
      this.userSelected = data
      // eslint-disable-next-line no-underscore-dangle
      window.open(`/user/${data.localUserId}?from=partner`)
    },

    openBlockModal(data) {
      this.userSelected = data
      this.$refs['open-block-modal'].showModal()
    },

    openReasonBlock(data) {
      this.userSelected = data
      this.$refs['open-modal-reason-block'].showModal()
    },

    openVerifyReasonBlock(data) {
      this.userSelected = data
      this.$refs['open-modal-verify-reason-block'].showModal()
    },

    openBlockReasonModal(data) {
      this.userSelected = data
      this.$refs['open-reason-modal'].showModal()
    },

    openReasonRejected(data) {
      this.userSelected = data
      this.$refs['open-modal-reason-reject'].showModal()
    },

    async updateUserSelected() {
      await this.fetchUser()
      this.userSelected = this.users.find(
        // eslint-disable-next-line no-underscore-dangle
        user => user._id === this.userSelected._id,
      )
    },

    openSideBar(data) {
      this.userSelected = data
      this.showSideBar = true
    },

    openAssignPartnerSideBar(data) {
      this.showAssignPartnerSideBar = true
      this.userSelected = data
    },

    userBalance(balance) {
      return this.amountFormat(balance)
        .toLocaleString('en-US')
    },

    async exportExcel() {
      this.loadingDown = true

      try {
        const localUserId = this.userDetail.localUserId || this.$route.params?.id

        const params = {
          ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}belongToPartner.requestedAt` }),
          ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
          ...(this.startDate && { spendingFromDate: this.startDate }),
          ...(this.endDate && { spendingToDate: this.endDate }),
          ...(this.statusSelected && { status: this.statusSelected }),
          ...(this.starCreatedDate && { from: this.starCreatedDate }),
          ...(this.endCreatedDate && { to: this.endCreatedDate }),
          ...(this.verifyStatusSelected && { verifyStatus: this.verifyStatusSelected.value }),
        }

        const response = await axios
          .get(`/api/admin/users/${localUserId}/partnership/members/export`, {
            responseType: 'blob',
            params,
          })
        const url = URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `list-member-${new Date().toLocaleDateString()}.xlsx`,
        )
        document.body.appendChild(link)
        link.click()
        // Clean up after download
        document.body.removeChild(link)
      } catch (e) {
        const error = await new Response(e.data).json() || 'Server Error'
        this.toastFailure(error.message)
      } finally {
        this.loadingDown = false
      }
    },

    submitExportData() {
      this.loadingDown = true
      if (this.noFilter) {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want export all data?', {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Export data',
            cancelTitle: 'Close',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.exportExcel()
            } else {
              this.loadingDown = false
            }
          })
      } else {
        this.exportExcel()
      }
    },

    async openAssignToAnotherPartner(data) {
      this.localUserId = data?.localUserId
      this.partnerLocalId = data?.belongToPartner?.partnerLocalId
      await this.getListAdAccountUser({
        // eslint-disable-next-line no-underscore-dangle
        adsAccounts: data?.adsAccounts.map(adsAccount => adsAccount._id)
          .join(','),
      })
      this.listAdAccount = this.listAdAccountUser
      this.totalSpendingError = this.listAdAccountUser.reduce((spend, item) => spend + item.spend, 0)
      this.showUpgradeToAnotherPartner = true
    },

    handleRemoveMember(data) {
      this.listMemberRemove = [...this.listMemberRemove, data.localUserId]

      const payload = {
        id: data?.belongToPartner?.partnerLocalId,
        memberLocalUserIds: this.listMemberRemove,
      }

      this.$bvModal
        .msgBoxConfirm('Are you sure you want to remove this member?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Remove',
          cancelTitle: 'Close',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.removeMember(payload)
              .then(() => {
                if (this.status) {
                  this.toastSuccess('Remove member success!')
                  this.fetchUser()
                } else {
                  this.toastFailure(this.message)
                }
              })
          }
        })
    },

    openMonthlySpendingModal(data) {
      this.amountData = data
      this.$refs['open-monthly-spending-modal'].showModal()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.filter-form {
  margin: 0;

  .card-header {
    padding: 0;
  }

  .card-body {
    padding: 0;
    margin-top: 3px;
  }
}

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.col-monthly-spending {
  min-width: 200px;
}
</style>

<style lang="scss" scoped>
.month-selection {
  max-width: 120px;
}

.dropdown-custom {
  position: absolute !important;
  top: -30px;
  left: 33px;
}

.icon-reason-block {
  position: absolute;
  right: -10px;
  top: -15px;
}

.status-product-links {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  .content-status {
    margin: 0;
    text-transform: capitalize;
    font-size: 14px;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }

  &.not-found {
    background-color: #82868a1a;
    color: #82868a;
  }
}

.text-status {
  text-transform: capitalize;
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  &.not-found {
    color: #babfc7;
    background-color: #babfc71a;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}

.btn-partner {
  margin-top: 27.549px;
  display: flex;
  justify-content: center;
  align-items: center;

  &.btn-export {
    height: 43px;
  }
}

.text-error {
  margin-top: 1rem;
  color: #f02649;
  text-align: center;
}

.icon-reason-block {
  position: absolute;
  right: -10px;
  top: -15px;
}
</style>
