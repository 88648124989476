<template>
  <b-modal
    ref="modal-block-user"
    id="block-child"
    class="modal-child"
    hide-footer
    hide-header
    centered
  >
    <div class="d-flex justify-content-between top-modal px-1 pt-1">
      <h3
        data-testid="title-reason-block-modal"
        class="font-medium text-primary"
      >
        Reason
      </h3>
      <div>
        <feather-icon
          data-testid="icon-close-reason-block-modal"
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hideModal"
        />
      </div>
    </div>

    <div class="position-relative pl-1 pr-1 reason-reject mt-1">
      <div class="d-flex">
        <p
          data-testid="text-admin-id"
          class="mr-2"
        ><span class="text-danger font-weight-600">Admin ID:</span>
          <span
            v-if="userData && userData.authentication && userData.authentication.updatedBy"
            data-testid="value-admin-id"
          >
            {{ userData.authentication.updatedBy }}
          </span>
          <span
            v-else
            data-testid="value-admin-id"
          >
            N/A
          </span>
        </p>
        <p data-testid="text-block-at"><span class="text-danger font-weight-600">Block At:</span>
          <span
            v-if="userData && userData.authentication && userData.authentication.blockedAt"
            data-testid="value-block-at"
          >
            {{ createdDate(userData.authentication.blockedAt) }}
          </span>
        </p>
      </div>
      <div class="d-flex">
        <p
          data-testid="text-user-related-ids"
          class="mr-2"
        ><span class="text-danger font-weight-600">User Related Ids:</span>
          <span
            v-if="userData && userData.authentication && userData.authentication.relatedUserIds"
            data-testid="value-user-related-ids"
          >
            {{ userData.authentication.relatedUserIds.join(',') }}
          </span>
          <span
            v-else
            data-testid="value-user-related-ids"
          >
            N/A
          </span>
        </p>
      </div>

      <b-badge
        class="reason-reject-message"
        variant="light-danger"
      >
        <p
          data-testid="text-reason-block"
          class="mb-0"
        >
          {{ reasonBlock }}
        </p>
      </b-badge>
    </div>

    <!--modal footer-->
    <div class="d-flex justify-content-end mt-2">
      <b-button
        variant="flat-secondary"
        data-testid="btn-cancel-reason-modal"
        @click="hideModal"
      >
        Cancel
      </b-button>
    </div>
    <!--/modal footer-->
  </b-modal>
</template>
<script>

import {
  BModal,
  BButton,
  BBadge,
} from 'bootstrap-vue'
import { REASON_BLOCK } from '@/constants'
import { toastification } from '@core/mixins/toast'
import { required } from '@validations'
import numberFormatMixin from '@/mixins/numberFormatMixin'

export default {
  components: {
    BModal,
    BButton,
    BBadge,
  },
  mixins: [numberFormatMixin, toastification],

  props: {
    userData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },

  data() {
    return {
      // validation
      required,
      reasonBlock: '',
    }
  },

  computed: {
    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },
  },

  watch: {
    userData: {
      handler(userInfo) {
        if (userInfo) {
          const reason = userInfo?.authentication?.blockedReasonId
          this.fetchReason(reason)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    fetchReason(reason) {
      if (reason) {
        switch (reason) {
          case REASON_BLOCK.BLOCKED_BY_ADMIN:
            this.reasonBlock = this.userData?.authentication?.blockedReason
            break
          case REASON_BLOCK.BLOCKED_BY_RECHARGE_FAILED:
            this.reasonBlock = 'User has recharged failed too many times'
            break
          default:
            this.reasonBlock = reason.replaceAll('_', ' ')
        }
      }
    },

    hideModal() {
      this.$refs['modal-block-user'].hide()
    },

    showModal() {
      this.$refs['modal-block-user'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
.reason-reject-message {
  text-align: start;
  white-space: normal;
}

.reason-reject {
  max-width: max-content;
  width: 100%;
}
</style>
