<template>
  <div>

    <b-card-code
      class="filter-card"
      title="Filters"
    >
      <!-- advance search input -->
      <b-row>
        <b-col md="5">
          <b-form-group>
            <label>Search user by field</label>
            <b-input-group
              class="input-filter"
            >
              <b-input-group-prepend>
                <b-form-select
                  v-model="searchSelected"
                  class="select-search-custom"
                  :options="searchOptions"
                  @input="handleChangeSearchType"
                />
              </b-input-group-prepend>

              <b-form-input
                v-model.trim="searchTerm"
                class="d-inline-block"
                :placeholder="handleSearchPlaceHolder"
                :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                @wheel="handleMouseWheelInput"
                @input="handleSearch"
                @keyup.enter="handleSubmitSearch"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          v-if="isOnlyEcomdyPlatform"
          md="3"
        >
          <b-form-group>
            <label>
              Filter by Platform
            </label>

            <b-form-select
              v-model="registerFromServiceSelected"
              class="w-100"
              :options="registerFromServiceOptions"
              @input="handleServiceChange"
            />

          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <label>
              All status
            </label>

            <b-form-select
              v-model="statusSelected"
              class="w-100"
              :options="statusOptions"
              placeholder="All status"
              @input="handleStatusChange"
            />

          </b-form-group>
        </b-col>
      </b-row>
    </b-card-code>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="tickets"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }"
      :pagination-options="{
        enabled: totalTickets > 0,
      }"
      :sort-options="{
        enabled: totalTickets > 0,
      }"
      :is-loading="loading"
      :group-options="{
        enabled: false
      }"
      @on-sort-change="onSortChange"
    >
      <template
        slot-scope="props"
        slot="table-row"
      >

        <div v-if="props.column.field === 'localUserId'">
          <p>
            {{ props.row.localUserId }}
          </p>
        </div>

        <!-- Column: User Info -->
        <div
          v-if="props.column.field === 'userId'"
          class="d-flex align-items-center"
        >
          <b-img
            :id="`avt-user-${props.row._id}`"
            rounded
            class="ads-avt-img"
            :src="getAvatar(props.row.registerFromService)"
          />
          <b-tooltip
            v-if="isEcomdyPlatform && props.row.registerFromService"
            placement="top"
            triggers="hover"
            :target="`avt-user-${props.row._id}`"
          >
            {{ getUserPlatform(props.row.registerFromService) }}
          </b-tooltip>
          <b-link
            class="text-link"
            :href="`/user/${props.row.localUserId}?tab=list-ads-account`"
            target="_blank"
            rel="noreferrer noopener"
          >
            <p class="mb-0">
              {{ props.row.fullName && capitalise(props.row.fullName) }}
            </p>
            <span>{{ props.row.email }}</span>
          </b-link>
        </div>

        <div v-if="props.column.field === 'adsAccount'">
          {{ props.row.adsAccounts.length }}
        </div>

        <div v-if="props.column.field === 'balance'">
          {{ currencyFormat(props.row.balance) }}
        </div>

        <div v-if="props.column.field === 'plan'">
          <p v-if="hasPlans(props.row)">
            {{ capitalise(props.row.plans.type) }}
          </p>
          <p v-else>
            N/A
          </p>
        </div>

        <!--        createdAt-->
        <div v-if="props.column.field === 'createdAt'">
          <p v-if="createdDate(props.row)">
            {{ createdDate(props.row) }}
          </p>
          <p v-else>
            N/A
          </p>
        </div>

        <div v-if="props.column.field === 'status'">
          <p v-if="!hasPlans(props.row)">
            N/A
          </p>
          <b-badge
            v-else
            :variant="subStatusVariant(props.row.plans.needToBeReviewed)"
            pill
          >
            {{ props.row.plans.needToBeReviewed ? 'Pending' : 'Completed' }}
          </b-badge>
        </div>
        <div v-if="props.column.field === 'reason'">
          <b-dropdown
            :ref="`dropdown-${props.row.originalIndex}`"
            no-caret
            variant="link"
          >
            <template v-slot:button-content>
              <feather-icon
                icon="EyeIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <div class="position-relative pl-1 pr-1 reason-reject">
              <p v-if="!hasPlans(props.row)">
                N/A
              </p>
              <div v-else>
                <p class="text-capitalize font-weight-bolder mb-0">
                  {{ props.row.plans.needToBeReviewed ? 'Pending' : 'Completed' }}
                </p>
                <a @click="$refs[`dropdown-${props.row.originalIndex}`].hide(true)">
                  <feather-icon
                    icon="XIcon"
                    size="16"
                    class="position-absolute position-top-0 position-right-0 mr-1"
                  />
                </a>

                <b-dropdown-divider />

                <p class="mb-0 font-14">
                  Reason: {{ props.row.plans.subscription.failedChargeReason || '' }}
                </p>
              </div>
            </div>
          </b-dropdown>
        </div>

        <div
          v-if="props.column.field === 'block_reason' && props.row.authentication && props.row.authentication.blockedReason"
        >
          <feather-icon
            icon="EyeIcon"
            size="16"
            class="text-body align-middle cursor-pointer"
            @click="openReasonBlock(props.row)"
          />
        </div>

        <div v-if="props.column.field === 'action'">
          <p v-if="!hasPlans(props.row)">
            N/A
          </p>
          <div v-else-if="props.row.plans.needToBeReviewed">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                @click="() => openConfirmApproval(props.row)"
              >
                <feather-icon
                  class="mr-50"
                  icon="CheckCircleIcon"
                />
                <span>
                  Approve
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                @click="rejectAccountTicket(props.row)"
              >
                <feather-icon
                  icon="ThumbsDownIcon"
                  class="mr-50"
                />
                <span>Skip</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div v-else>
            <p
              class="ml-1"
            >
              N/A
            </p>
          </div>
        </div>
      </template>

      <!-- pagination -->
      <template
        v-if="totalTickets > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1"
          :class="[{'justify-content-center':$device.mobile}, {'justify-content-between':!$device.mobile}]"
        >

          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Ticket per page
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap ">
              Showing
              {{ pageLength * (currentPage - 1) + 1 }}
              to
              {{ pageLength * currentPage }}
              of {{ totalTickets }} ticket(s)
            </span>
          </div>
          <div>
            <b-pagination
              :total-rows="totalTickets"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>
    </vue-good-table>

    <detail-blocked-user-modal
      ref="open-modal-reason-block"
      :user-data="userSelected === null ? {} : userSelected"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import envMixin from '@/mixins/envMixin'
import { createNamespacedHelpers } from 'vuex'
import get from 'lodash/get'
import BCardCode from '@core/components/b-card-code'
import {
  BPagination,
  BSpinner,
  BImg,
  BBadge,
  BDropdown,
  BDropdownItem,
  BFormSelect, BFormGroup, BFormInput, BInputGroupPrepend, BRow, BCol,
  BInputGroup, VBModal,
  BDropdownDivider,
  BLink, BTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import axiosLib from 'axios'
import {
  REGISTER_FROM_SERVICE,
} from '@/constants'
import usersMixin from '@/mixins/usersMixin'
import DetailBlockedUserModal from '../user/components/DetailBlockedUserModal.vue'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('subscription')

// clearTaskData
const blankTask = {
  id: null,
  title: '',
  type: '',
  status: '',
  userId: '',
  details: {},
  description: '',
}

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

export default {
  components: {
    BTooltip,
    BBadge,
    BPagination,
    BFormSelect,
    BSpinner,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BDropdownItem,
    BInputGroupPrepend,
    BInputGroup,
    BRow,
    BDropdown,
    BCol,
    BImg,
    BCardCode,
    BDropdownDivider,
    BLink,
    DetailBlockedUserModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, paymentMixin, envMixin, usersMixin],
  data() {
    return {
      columns: [
        {
          label: 'ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('payment.userInfo'),
          field: 'userId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Ads account',
          field: 'adsAccount',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'User balance',
          field: 'balance',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Current plan',
          field: 'plan',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Request at',
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Cancel reason',
          field: 'reason',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Block reason',
          field: 'block_reason',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('payment.action'),
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],
      currentPage: 1,
      searchTerm: '',
      userSelected: null,

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],

      // status filter
      statusOptions: [
        {
          text: 'All status',
          value: '',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'Completed',
          value: 'completed',
        },
      ],
      statusSelected: 'pending',
      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],
      subscription: (JSON.parse(JSON.stringify(blankTask))),
      showConfirmApproval: false,

      // platform filter
      registerFromServiceSelected: null,
      registerFromServiceOptions: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'TTM',
          value: REGISTER_FROM_SERVICE.TTM,
        },
        {
          text: 'Pancake',
          value: REGISTER_FROM_SERVICE.PANCAKE,
        },
        {
          text: 'TTAC',
          value: REGISTER_FROM_SERVICE.TTAC,
        },
      ],
    }
  },

  computed: {
    REGISTER_FROM_SERVICE() {
      return REGISTER_FROM_SERVICE
    },
    ...mapGetters(['subscriptions', 'message', 'status', 'loading']),

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    tickets() {
      return get(this.subscriptions, ['content'], [])
    },

    totalTickets() {
      return get(this.subscriptions, ['paging', 'total'], 0)
    },

    handleSearchPlaceHolder() {
      switch (this.searchSelected) {
        case 'localUserId':
          return 'Search by user ID'
        case 'email':
          return 'Search by user email'
        default:
          return ''
      }
    },

    createdDate() {
      return value => (value?.plans?.lastCanceledAt ? new Date(value?.plans?.lastCanceledAt).toLocaleString('en-GB') : '')
    },
  },

  async created() {
    await this.fetchSubscriptions()
  },

  beforeDestroy() {
    abort()
  },

  methods: {
    ...mapActions(['getSubscriptions', 'approveSubscription', 'rejectSubscription', 'deleteSubscription']),

    capitalise(value) {
      return value.charAt(0)
        .toUpperCase() + value.slice(1)
    },

    hasPlans(data) {
      return !!data.plans
    },

    async onApproval() {
      const params = {
        id: this.subscription.localUserId,
      }

      await this.approveSubscription(params)
      if (this.status) {
        this.toastSuccess('Approval success !')
        this.statusSelected = 'pending'
        this.fetchSubscriptions()
      } else {
        this.toastFailure(this.message)
      }
    },

    async rejectAccountTicket(data) {
      const params = {
        id: data.localUserId,
      }

      await this.rejectSubscription(params)
      if (this.status) {
        this.toastSuccess('Reject success !')
        this.statusSelected = 'pending'
        this.fetchSubscriptions()
      } else {
        this.toastFailure(this.message)
      }
    },

    openConfirmApproval(data) {
      this.subscription = data
      this.onApproval()
    },

    subStatusVariant(status) {
      if (status) {
        return 'light-warning'
      }
      return 'light-success'
    },

    clearTaskData() {
      const subscription = (JSON.parse(JSON.stringify(blankTask)))
      subscription.value = JSON.parse(JSON.stringify(blankTask))
    },

    async fetchSubscriptions() {
      abort()

      let params = {
        page: this.currentPage,
        size: this.pageLength,
        // ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}` }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.registerFromServiceSelected && { registerFromService: this.registerFromServiceSelected }),
      }

      if (this.statusSelected === 'pending') {
        params = {
          ...params,
          ...({ needToBeReviewed: true }),
        }
      }

      if (this.statusSelected === 'completed') {
        params = {
          ...params,
          ...({ needToBeReviewed: false }),
        }
      }

      if (this.statusSelected === '') {
        params = {
          ...params,
          ...({ needToBeReviewed: 'all' }),
        }
      }

      await this.getSubscriptions({ params, cancelToken: cancelToken.token })
      if (!this.status && this.message) {
        this.toastFailure(this.message)
      }
    },

    async handleSearch(searching) {
      const timeOut = this.searchSelected === 'paymentMethod' ? 0 : 600

      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchSubscriptions()
      } else if (searching.toString().length > 3 && this.searchSelected !== 'email') {
        this.debounce = setTimeout(() => {
          this.fetchSubscriptions()
        }, timeOut)
      } else if (this.searchSelected === 'email') {
        if (this.validateEmail(this.searchTerm)) {
          this.debounce = setTimeout(() => {
            this.fetchSubscriptions()
          }, 1000)
        }
      }
    },

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchSubscriptions()
      }
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchSubscriptions()
    },

    openReasonBlock(data) {
      this.userSelected = data
      this.$refs['open-modal-reason-block'].showModal()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchSubscriptions()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchSubscriptions()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchSubscriptions()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchSubscriptions()
    },

    async handleSelectPaymentMethod() {
      this.currentPage = 1
      await this.fetchSubscriptions()
    },

    async handleServiceChange() {
      this.currentPage = 1
      await this.fetchSubscriptions()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.reason-reject {
  width: 250px;
  max-height: 200px;
  overflow: auto;

  &-message {
    display: block;
    white-space: normal;
    text-align: start;
  }
}

.filter-card {
  margin-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid #ebe9f1;
  border-bottom: 0px;

  .form-group {
    margin: 0;
  }
}

.dropdown-menu.show {
  .dropdown-item:hover {
    background-color: #F02548;
    color: #fff;
  }
}

.link {
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}

.text-link {
  color: #3D405B;
  cursor: pointer;

  p {
    font-weight: 700;
  }

  text-decoration: underline;

  &:hover {
    color: #3D405B;
    text-decoration: underline;
  }
}

.link-review-ads-account {
  text-decoration: underline;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
}
</style>
<style lang="scss" scoped>
::v-deep .vgt-responsive {
  min-height: 40vh;
}
</style>
