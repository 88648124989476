/* eslint-disable global-require */
import moment from 'moment'
import { PAYMENT_METHOD, CREDIT_CARD_TYPE } from '@/constants'

import interpay from '@/assets/images/pages/payment/ic-interpaycard.svg'
import dankort from '@/assets/images/pages/payment/ic-dankortcard.svg'
import maestroCard from '@/assets/images/pages/payment/ic-maestrocard.svg'
import diners from '@/assets/images/pages/payment/ic-dinnerscard.svg'
import unionpay from '@/assets/images/pages/payment/ic-unionpaycard.svg'
import jcb from '@/assets/images/pages/payment/ic-jcbcard.svg'
import discover from '@/assets/images/pages/payment/ic-discovercard.svg'
import amex from '@/assets/images/pages/payment/ic-amexcard.svg'
import master from '@/assets/images/pages/payment/ic-mastercard.svg'
import electron from '@/assets/images/pages/payment/ic-electroncard.svg'
import visa from '@/assets/images/pages/payment/ic-visacard.svg'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'

export default {
  mixins: [generalConfigsMixin],
  computed: {
    /* ----- Payment method ----*/
    listPaymentMethodGeneral() {
      return this.generalConfigs?.availablePaymentMethods?.map(item => {
        if (item === PAYMENT_METHOD.AIRWALLEXAUTO) {
          return PAYMENT_METHOD.AIRWALLEX
        }
        return item
      })
    },

    listPaymentMethodGeneralPackage() {
      const listPaymentMethod = this.generalConfigs?.availablePaymentMethods?.map(item => {
        if (item === PAYMENT_METHOD.AIRWALLEXAUTO) {
          return PAYMENT_METHOD.AIRWALLEX
        }
        return item
      })
      if (this.isOnlyEcomdyPlatform) {
        return [...listPaymentMethod, PAYMENT_METHOD.PAYONEER_TICKET]
      }
      return listPaymentMethod
    },

    hasCreditCard() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.CREDITCARD)
    },

    hasPaypal() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PAYPAL)
    },

    hasPayoneer() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PAYONEER)
    },

    hasPayoneerTicket() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PAYONEER_TICKET)
    },

    hasTransferWise() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.TRANSFERWISE)
    },

    hasUSDT() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.USDT)
    },

    hasAirwallex() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.AIRWALLEX)
    },

    hasAirwallexAuto() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.AIRWALLEXAUTO)
    },

    hasTazapay() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.TAZAPAY)
    },

    hasLianLian() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.LIANLIAN)
    },

    hasLianLianTicket() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.LIANLIAN_TICKET)
    },

    hasLocalBank() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.LOCALBANK)
    },

    hasLocalBankTicket() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.LOCALBANK_TICKET)
    },

    hasPayFast() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PAYFAST)
    },

    hasPingPong() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PINGPONG_TICKET)
    },

    hasIdeal() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.IDEAL)
    },

    hasBancontact() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.BANCONTACT)
    },

    onlyOnePaymentMethod() {
      return this.generalConfigs?.availablePaymentMethods?.length === 1
    },

    defaultTransferFee() {
      return [
        {
          key: PAYMENT_METHOD.PAYFAST,
          name: 'PayFast',
          value: 0,
          isShow: this.hasPayFast,
        },
        {
          key: PAYMENT_METHOD.PAYPAL,
          name: 'PayPal',
          value: 0,
          isShow: this.hasPaypal,
        },
        {
          key: PAYMENT_METHOD.PAYONEER,
          name: 'Payoneer',
          value: 0,
          isShow: this.hasPayoneer,
        },
        {
          key: PAYMENT_METHOD.PAYONEER_TICKET,
          name: 'Payoneer Ticket',
          value: 0,
          isShow: this.hasPayoneerTicket || this.isOnlyEcomdyPlatform,
        },
        {
          key: PAYMENT_METHOD.USDT,
          name: 'USDT',
          value: 0,
          isShow: this.hasUSDT,
        },
        {
          key: PAYMENT_METHOD.CREDITCARD,
          name: 'Credit card',
          value: 0,
          isShow: this.hasCreditCard,
        },
        {
          key: PAYMENT_METHOD.AIRWALLEX,
          name: 'Airwallex',
          value: 0,
          isShow: this.hasAirwallexAuto,
        },
        {
          key: PAYMENT_METHOD.TRANSFERWISE,
          name: 'Wise',
          value: 0,
          isShow: this.hasTransferWise,
        },
        {
          key: PAYMENT_METHOD.TAZAPAY,
          name: 'Tazapay',
          value: 0,
          isShow: this.hasTazapay,
        },
        {
          key: PAYMENT_METHOD.LIANLIAN,
          name: 'LianLian',
          value: 0,
          isShow: this.hasLianLian,
        },
        {
          key: PAYMENT_METHOD.LIANLIAN_TICKET,
          name: 'LianLian',
          value: 0,
          isShow: this.hasLianLianTicket,
        },
        {
          key: PAYMENT_METHOD.LOCALBANK,
          name: 'Local bank',
          value: 0,
          isShow: this.hasLocalBank,
        },
        {
          key: PAYMENT_METHOD.LOCALBANK_TICKET,
          name: 'Local bank',
          value: 0,
          isShow: this.hasLocalBankTicket,
        },
        {
          key: PAYMENT_METHOD.PINGPONG_TICKET,
          name: 'PingPong',
          value: 0,
          isShow: this.hasPingPong,
        },
        {
          key: PAYMENT_METHOD.IDEAL,
          name: 'iDEAL',
          value: 0,
          isShow: this.hasIdeal,
        },
        {
          key: PAYMENT_METHOD.BANCONTACT,
          name: 'Bancontact',
          value: 0,
          isShow: this.hasBancontact,
        },
      ]
    },
  },
  methods: {
    getBrandIcon(brand) {
      switch (brand) {
        case PAYMENT_METHOD.PAYPAL:
          return require('@/assets/images/pages/payment/ic-paypal.svg')
        case PAYMENT_METHOD.PAYONEER:
          return require('@/assets/images/pages/payment/ic-payoneer.svg')
        case PAYMENT_METHOD.PAYONEER_TICKET:
          return require('@/assets/images/pages/payment/ic-payoneer.svg')
        case PAYMENT_METHOD.PINGPONG_TICKET:
          return require('@/assets/images/pages/payment/ic-pingpong.svg')
        case PAYMENT_METHOD.USDT:
          return require('@/assets/images/pages/payment/ic-usdt.svg')
        case PAYMENT_METHOD.BUSD:
          return require('@/assets/images/pages/payment/ic-bnb.svg')
        case PAYMENT_METHOD.TPBANK:
          return require('@/assets/images/pages/payment/ic-tpbank.svg')
        case PAYMENT_METHOD.CREDITCARD:
          return require('@/assets/images/pages/payment/ic-creditcard.svg')
        case PAYMENT_METHOD.AIRWALLEX:
          return require('@/assets/images/pages/payment/ic-airwallex.svg')
        case PAYMENT_METHOD.AIRWALLEXAUTO:
          return require('@/assets/images/pages/payment/ic-airwallex.svg')
        case PAYMENT_METHOD.TRANSFERWISE:
          return require('@/assets/images/pages/payment/ic-transferwise.svg')
        case PAYMENT_METHOD.TAZAPAY:
          return require('@/assets/images/pages/payment/ic-tazapay.svg')
        case PAYMENT_METHOD.LIANLIAN:
          return require('@/assets/images/pages/payment/ic-lianlian.svg')
        case PAYMENT_METHOD.LIANLIAN_TICKET:
          return require('@/assets/images/pages/payment/ic-lianlian.svg')
        case PAYMENT_METHOD.LOCALBANK:
          return require('@/assets/images/pages/payment/ic-local-bank.png')
        case PAYMENT_METHOD.LOCALBANK_TICKET:
          return require('@/assets/images/pages/payment/ic-local-bank.png')
        case PAYMENT_METHOD.PAYFAST:
          return require('@/assets/images/pages/payment/ic-payfast.jpg')
        case PAYMENT_METHOD.IDEAL:
          return require('@/assets/images/pages/payment/ic-ideal.svg')
        case PAYMENT_METHOD.BANCONTACT:
          return require('@/assets/images/pages/payment/ic-bancontact.svg')
        default:
          return ''
      }
    },

    namePaymentConvert(payment) {
      switch (payment) {
        case PAYMENT_METHOD.PAYPAL: return 'PayPal'
        case PAYMENT_METHOD.PAYONEER: return 'Payoneer'
        case PAYMENT_METHOD.PAYONEER_TICKET:
          if (this.hasPayoneer) {
            return 'Payoneer ticket'
          }
          return 'Payoneer'
        case PAYMENT_METHOD.CREDITCARD: return 'Credit card'
        case PAYMENT_METHOD.USDT: return 'USDT'
        case PAYMENT_METHOD.AIRWALLEX: return 'Airwallex'
        case PAYMENT_METHOD.AIRWALLEXAUTO: return 'Airwallex'
        case PAYMENT_METHOD.PAYFAST: return 'PayFast'
        case PAYMENT_METHOD.TAZAPAY: return 'Tazapay'
        case PAYMENT_METHOD.LIANLIAN: return 'LianLian'
        case PAYMENT_METHOD.LIANLIAN_TICKET: return 'LianLian'
        case PAYMENT_METHOD.LOCALBANK: return 'Local bank'
        case PAYMENT_METHOD.LOCALBANK_TICKET: return 'Local bank'
        case PAYMENT_METHOD.PINGPONG_TICKET: return 'PingPong'
        case PAYMENT_METHOD.IDEAL: return 'iDEAL'
        case PAYMENT_METHOD.BANCONTACT: return 'Bancontact'
        default: return payment
      }
    },

    getCreditCardLogo(brand) {
      switch (brand) {
        case CREDIT_CARD_TYPE.visa: return visa
        case CREDIT_CARD_TYPE.electron: return electron
        case CREDIT_CARD_TYPE.mastercard: return master
        case CREDIT_CARD_TYPE.amex: return amex
        case CREDIT_CARD_TYPE.discover: return discover
        case CREDIT_CARD_TYPE.jcb: return jcb
        case CREDIT_CARD_TYPE.diners: return diners
        case CREDIT_CARD_TYPE.unionpay: return unionpay
        case CREDIT_CARD_TYPE.maestro: return maestroCard
        case CREDIT_CARD_TYPE.dankort: return dankort
        case CREDIT_CARD_TYPE.interpayment: return interpay
        default: return ''
      }
    },

    getCreditCardName(brand) {
      switch (brand) {
        case CREDIT_CARD_TYPE.visa: return 'Visa'
        case CREDIT_CARD_TYPE.electron: return 'Electron'
        case CREDIT_CARD_TYPE.mastercard: return 'MasterCard'
        case CREDIT_CARD_TYPE.amex: return 'Amex'
        case CREDIT_CARD_TYPE.discover: return 'Discover'
        case CREDIT_CARD_TYPE.jcb: return 'JCB'
        case CREDIT_CARD_TYPE.diners: return 'Diners'
        case CREDIT_CARD_TYPE.unionpay: return 'Unionpay'
        case CREDIT_CARD_TYPE.maestro: return 'Maestro'
        case CREDIT_CARD_TYPE.dankort: return 'Dankort'
        case CREDIT_CARD_TYPE.interpayment: return 'Interpayment'
        default: return ''
      }
    },

    getFormatDate(time) {
      if (!time) {
        return null
      }
      return moment(time).format('DD/MM/YYYY')
    },

    getFormatTime(time) {
      return moment(time).format('LT')
    },

    getBriefTransactionId(id) {
      if (!id) {
        return ''
      }

      return `${id.substring(0, 10)}...`
    },

    formatTransType(str) {
      if (str.length > 0) {
        const frags = str.split('_')
        for (let i = 0; i < frags.length; i += 1) {
          frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1)
        }
        return frags.join(' ')
      }
      return str
    },

    getTotalFee(payload) {
      if (!payload) {
        return 0
      }

      const taxCharge = payload.taxCharge ? payload.taxCharge : 0
      const serviceChargeFee = payload.serviceCharge ? payload.serviceCharge : 0
      const paymentGateCharge = payload.paymentGateCharge ? payload.paymentGateCharge : 0
      return taxCharge + serviceChargeFee + paymentGateCharge
    },

    formatAccountHistoryType(type) {
      switch (type) {
        case 'recharge_ads_account_balance':
          return 'Add funds'
        case 'refund_ads_account_balance':
          return 'Withdraw'
        case 'system_withdraw_due_to_inactive':
          return 'System withdrawal due to inactive'
        default:
          return type
      }
    },
  },
}
