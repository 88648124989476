<template>
  <div class="tickets-container">
    <div>
      <b-card-code>
        <div class="title-card">
          <h3 class="m-0">
            List Ad account management
          </h3>
        </div>
        <b-row class="mt-2">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group>
              <label>We synchronize the data from these Ad Account Id.</label>
              <b-input-group>
                <b-form-textarea
                  v-model="listAdAccount"
                  placeholder="Ex: 1772629436019746, 1772629436019746, ..."
                />
              </b-input-group>
              <small>Please separate the Id by a comma (,).</small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group>
              <label>Number of latest days to fetch data</label>
              <b-input-group>
                <b-form-input
                  v-model.number="dayNumber"
                  id="login-email"
                  class="input-height"
                  name="dayNumber"
                  type="number"
                  placeholder="Ex: 3"
                />
              </b-input-group>
              <p
                v-if="!dayNumber"
                class="text-danger mb-0 font-14"
              >The number of latest days is required</p>
              <p
                v-else-if="dayNumber <= 0 || dayNumber > 6"
                class="text-danger mb-0 font-14"
              >The number of latest days is greater than 0 and less than 6</p>
              <small>We fetch and save the latest data daily.</small>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="2"
          >
            <btn-loading
              class="mt-2"
              type="submit"
              variant="primary"
              block
              :loading="loadingImport"
              @click.prevent="importAdCreatives"
            >
              Import
            </btn-loading>
          </b-col>
        </b-row>
      </b-card-code>
      <!-- filter -->
      <b-card-code>
        <div class="title-card">
          <h3 class="m-0">
            Filters
          </h3>
        </div>
        <b-row>
          <b-col
            v-if="hasAM"
            md="4"
          >
            <b-form-group>
              <label>
                Filter by AM
              </label>
              <v-select
                v-model="amSelected"
                class="input-height"
                placeholder="Select AM"
                :options="listAMOptions"
                :filter-by="handleSearchAm"
                @input="handleAmChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group class="w-100">
              <label> AM review status </label>
              <v-select
                v-model="AMStatusSelected"
                class="input-height"
                placeholder="Select status"
                :options="AMStatusOptions"
                @input="handleAMStatusChange"
              />
            </b-form-group>
          </b-col>
          <b-col md="5">
            <b-form-group>
              <label>Created At</label>

              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  placeholder="Select date"
                  :config="configDate"
                  @on-close="handleDateChange"
                />
                <b-input-group-append
                  v-if="startDate && endDate"
                  is-text
                  class="close-icon"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearDate"
                  />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-code>
      <!-- table -->
      <vue-good-table
        id="table-list-ad-creative"
        mode="remote"
        class="table-list-ad-creative position-relative"
        :columns="columns"
        :rows="adCreatives"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: totalAdCreative > 0
        }"
        :sort-options="{
          enabled: totalAdCreative > 0
        }"
        :is-loading="loading"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- TikTok Shop code -->
          <div
            v-if="props.column.field === 'video'"
            class="d-flex align-items-center"
            @click="openReviewAdCreativeSideBar(props.row)"
          >
            <div class="img-thumbnail-creative">
              <b-img
                :src="getImage(props.row.thumbnail)"
                alt="video"
              />
              <div class="ic-play-video">
                <feather-icon
                  icon="PlayCircleIcon"
                  size="24"
                />
              </div>
            </div>
            <p class="mb-0">
              {{ props.row.ad_name }}
            </p>
          </div>

          <!-- product link -->
          <span v-else-if="props.column.field === 'productLinks'">
            <p
              v-if="props.row.landing_page_url"
              v-b-tooltip="props.row.landing_page_url"
              class="text-blue underline cursor-pointer tag-content content-product-link m-0"
              @click="goToUrl(props.row.landing_page_url)"
            >
              {{ props.row.landing_page_url }}
            </p>
            <span v-else>
              N/A
            </span>
          </span>

          <!-- TikTok status -->
          <div
            v-else-if="props.column.field === 'tiktokStatus'"
          >
            {{ convertStatus(props.row.secondary_status) }}
          </div>

          <!-- AM Status -->
          <div
            v-else-if="props.column.field === 'amStatus'"
          >
            <span
              v-if="statusAmReviewInfo(props.row.reviewProcess.status)"
              class="text-status"
              :class="statusVariant(props.row.reviewProcess.status)"
            >
              {{ statusAmReviewInfo(props.row.reviewProcess.status) }}
            </span>
            <span v-else> N/A </span>
          </div>

          <!-- AM Note -->
          <div
            v-else-if="props.column.field === 'description'"
          >
            <b-dropdown
              v-if="props.row.reviewProcess.description"
              :ref="`dropdown-${props.row.ad_id}`"
              no-caret
              variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <div class="position-relative pl-1 pr-1 am-note">
                <p class="text-capitalize font-weight-bolder">
                  {{ props.row.reviewProcess.status }}
                </p>
                <a @click="$refs[`dropdown-${props.row.ad_id}`].hide(true)">
                  <feather-icon
                    icon="XIcon"
                    size="20"
                    class="position-absolute position-top-0 position-right-0 mr-1"
                  />
                </a>
                <p>
                  AM review: {{ props.row.reviewProcess.byAdminId }}
                </p>
                <div
                  :class="`reason-message ${statusAmReviewInfo(props.row.reviewProcess.status)}`"
                  v-html="props.row.reviewProcess.description"
                />
              </div>
            </b-dropdown>
          </div>

          <!-- createdAt -->
          <div v-else-if="props.column.field === 'createdAt'">
            <span v-if="createdDate(props.row.create_time)">
              {{ createdDate(props.row.create_time) }}
            </span>
            <span v-else>N/A</span>
          </div>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalAdCreative > 0"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap mt-1 justify-content-between">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap">
                of {{ totalAdCreative }} ad creative(s) info
              </span>
            </div>
            <div>
              <b-pagination
                :total-rows="totalAdCreative"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>

        <div
          slot="emptystate"
          class="text-center"
        >
          <no-data-table-image class="my-5" />
        </div>
      </vue-good-table>
    </div>

    <review-ad-creative-side-bar
      :ad-creative-data="adCreativeSelected"
      :show-side-bar.sync="showReviewAdCreativeSideBar"
      @fetch-list-ad-creative="fetchListAdCreative"
    />
  </div>
</template>

<script>
/* eslint-disable no-underscore-dangle,global-require,camelcase */
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BPagination,
  BSpinner,
  BImg,
  BFormSelect,
  BInputGroupAppend,
  VBTooltip,
  VBModal,
  BInputGroup,
  BDropdown,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { createNamespacedHelpers } from 'vuex'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import { VueGoodTable } from 'vue-good-table'
import { toastification } from '@core/mixins/toast'
import {
  AM_REVIEW_ADS_STATUS,
  TIKTOK_ADS_PRIMARY_STATUS,
  TIKTOK_ADS_SECONDARY_STATUS_MAPPING,
} from '@/constants'
import Ripple from 'vue-ripple-directive'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import envMixin from '@/mixins/envMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import ReviewAdCreativeSideBar from '@/views/ad-creative/components/reviewAdCreativeSideBar.vue'
import moment from 'moment/moment'
import flatPickr from 'vue-flatpickr-component'
import _get from 'lodash/get'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import vSelect from 'vue-select'

const { mapGetters, mapActions } = createNamespacedHelpers('adCreative')
const { mapGetters: mapGettersUser, mapActions: mapActionsUser } = createNamespacedHelpers('user')
const { mapGetters: mapGettersAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BPagination,
    BSpinner,
    BImg,
    BFormSelect,
    VueGoodTable,
    BInputGroupAppend,
    BInputGroup,
    flatPickr,
    BDropdown,
    ReviewAdCreativeSideBar,
    NoDataTableImage,
    BCardCode,
    BtnLoading,
    vSelect,
  },

  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },

  mixins: [toastification, numberFormatMixin, envMixin, generalConfigsMixin],

  data() {
    return {
      loadingImport: false,
      adCreativeSelected: {},
      showReviewAdCreativeSideBar: false,

      listAdAccount: '',
      dayNumber: 1,

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50', '100'],

      AMStatusSelected: {
        label: 'Reviewing',
        value: AM_REVIEW_ADS_STATUS.IN_REVIEW,
      },
      AMStatusOptions: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Rejected',
          value: AM_REVIEW_ADS_STATUS.REJECTED,
        },
        {
          label: 'Reviewing',
          value: AM_REVIEW_ADS_STATUS.IN_REVIEW,
        },
        {
          label: 'Approved',
          value: AM_REVIEW_ADS_STATUS.APPROVED,
        },
      ],

      listQuery: [],
      adCreativeIdSelected: '',
      amSelected: {
        label: 'All AM',
        value: 0,
      },
    }
  },

  watch: {
    adAccountImport: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.adsAccountIds?.length > 0) {
          this.listAdAccount = val.adsAccountIds.join(',')
        }
      },
    },
    user: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.localUserId && this.listAM.length > 0) {
          this.setAmSelected()
          this.fetchAdAccountImport()
        }
      },
    },

    listAM: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.length > 0 && this.user.localUserId) {
          this.setAmSelected()
        }
      },
    },

    $route: {
      deep: true,
      immediate: true,
      handler(route) {
        const {
          ad_id,
          review_status,
          am_id,
        } = route.query
        if (review_status) {
          switch (review_status) {
            case AM_REVIEW_ADS_STATUS.REJECTED:
              this.AMStatusSelected = {
                label: 'Rejected',
                value: AM_REVIEW_ADS_STATUS.REJECTED,
              }
              break
            case AM_REVIEW_ADS_STATUS.IN_REVIEW:
              this.AMStatusSelected = {
                label: 'Reviewing',
                value: AM_REVIEW_ADS_STATUS.IN_REVIEW,
              }
              break
            case AM_REVIEW_ADS_STATUS.APPROVED:
              this.AMStatusSelected = {
                label: 'Approved',
                value: AM_REVIEW_ADS_STATUS.APPROVED,
              }
              break
            default:
              this.AMStatusSelected = {
                label: 'All Status',
                value: '',
              }
          }
        }

        if (ad_id) {
          this.adCreativeIdSelected = ad_id
        }

        if (am_id) {
          this.amSelected = this.listAMOptions.find(item => item.value === Number(am_id))
        }

        const { query } = this.$route
        if (this.listQuery.length === 0 && Object.entries(query).length > 0) {
          const objectKeys = Object.keys(query)

          const newQuery = objectKeys.map(value => ({
            keyQuery: value,
            valueQuery: `${query[value]}`,
          }))
          this.listQuery = newQuery
        }
      },
    },

    listQuery: {
      handler(query) {
        if (query.length > 0) {
          const objUrl = query.reduce((result, item) => {
            const key = item.keyQuery
            const value = item.valueQuery
            // eslint-disable-next-line no-param-reassign
            result[key] = value
            return result
          }, {})
          this.$router.push({
            name: 'ad-creative',
            query: objUrl,
          })
        } else {
          window.history.pushState({}, '', '/ad-creative')
        }
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(['listAdCreative', 'loading', 'status', 'message', 'adAccountImport']),
    ...mapGettersUser(['listAM']),
    ...mapGettersAuth(['user']),

    userId() {
      return _get(this.user, ['localUserId'], '')
    },

    getImage() {
      return img => img || require('@/assets/images/common/no-image.png')
    },

    columns() {
      return [
        {
          label: 'Video',
          field: 'video',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
          width: '300px',
        },
        {
          label: 'Review Status',
          field: 'amStatus',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
        },
        {
          label: 'AM note',
          field: 'description',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          width: '90px',
        },
        {
          label: 'Belong to AM',
          field: 'amId',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          hidden: !this.hasAM,
        },
        {
          label: 'Ad Account ID',
          field: 'advertiser_id',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
        },
        {
          label: 'Ad Group ID',
          field: 'adgroup_id',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
        },
        {
          label: 'Ad Creative ID',
          field: 'ad_id',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
        },
        {
          label: 'productLinks',
          field: 'productLinks',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
        },
        {
          label: 'TikTok Status',
          field: 'tiktokStatus',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
          width: '150px',
        },
        {
          label: 'Create At',
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
        },
      ]
    },

    listAMOptions() {
      const listAMMap = this.listAM.map(item => {
        const options = {
          label: item.email,
          value: item.localUserId,
        }
        return options
      })
      const optionNone = {
        label: 'All AM',
        value: 0,
      }
      return [optionNone, ...listAMMap]
    },

    statusVariant() {
      const statusColor = {
        [AM_REVIEW_ADS_STATUS.REJECTED]: 'rejected',
        [AM_REVIEW_ADS_STATUS.IN_REVIEW]: 'reviewing',
        [AM_REVIEW_ADS_STATUS.APPROVED]: 'approved',
      }

      return status => statusColor[status]
    },

    statusAmReviewInfo() {
      const statusInfo = {
        [AM_REVIEW_ADS_STATUS.IN_REVIEW]: 'reviewing',
        [AM_REVIEW_ADS_STATUS.REJECTED]: 'rejected',
        [AM_REVIEW_ADS_STATUS.APPROVED]: 'approved',
      }
      return status => statusInfo[status]
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    totalAdCreative() {
      return _get(this.listAdCreative, ['paging', 'total'], 0)
    },

    adCreatives() {
      return _get(this.listAdCreative, ['content'], [])
    },

    convertStatus() {
      return status => {
        const statusTikTok = Object.entries(TIKTOK_ADS_SECONDARY_STATUS_MAPPING)
          .find(([, value]) => (value.includes(status)))
        switch (statusTikTok?.[0]) {
          case TIKTOK_ADS_PRIMARY_STATUS.STATUS_NOT_DELETE:
            return 'Not delete'
          case TIKTOK_ADS_PRIMARY_STATUS.STATUS_DELETE:
            return 'Delete'
          case TIKTOK_ADS_PRIMARY_STATUS.STATUS_NOT_DELIVERY:
            return 'Not delivering'
          case TIKTOK_ADS_PRIMARY_STATUS.STATUS_DISABLE:
            return 'Disable'
          case TIKTOK_ADS_PRIMARY_STATUS.STATUS_DELIVERY_OK:
            return 'Delivery'
          case TIKTOK_ADS_PRIMARY_STATUS.STATUS_TIME_DONE:
            return 'Done'
          case TIKTOK_ADS_PRIMARY_STATUS.STATUS_RF_CLOSED:
            return 'Close'
          case TIKTOK_ADS_PRIMARY_STATUS.STATUS_FROZEN:
            return 'Frozen'
          default:
            return statusTikTok?.[0]
        }
      }
    },
  },

  async created() {
    await this.getListAM()
  },

  methods: {
    ...mapActions(['getAdCreatives', 'importAdCreative', 'getAdAccountImport']),
    ...mapActionsUser(['getListAM']),
    setAmSelected() {
      if (this.$route.query.am_id) {
        this.amSelected = this.listAMOptions.find(item => item.value === Number(this.$route.query.am_id))
      } else {
        this.amSelected = this.listAMOptions.find(item => item.value === Number(this.userId))
      }
      this.fetchListAdCreative()
    },

    async fetchAdAccountImport() {
      const params = {
        amId: this.userId,
      }
      await this.getAdAccountImport(params)
    },

    async fetchListAdCreative() {
      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.amSelected && this.amSelected.value && { amId: this.amSelected.value }),
        ...(this.AMStatusSelected && this.AMStatusSelected.value && { reviewStatus: this.AMStatusSelected.value }),
        ...(this.startDate && { from: this.startDate }),
        ...(this.endDate && { to: this.endDate }),
        ...(this.adCreativeIdSelected && { adId: this.adCreativeIdSelected }),
      }
      await this.getAdCreatives(params)
    },

    handleSearchAm(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (label || '').toLocaleLowerCase()
        .indexOf(searchTxt) > -1 || option?.value?.toString()
          .toLocaleLowerCase()
          .indexOf(searchTxt) > -1
    },

    async handleAmChange() {
      this.currentPage = 1
      this.listQuery = this.listQuery.filter(item => item.keyQuery !== 'am_id')
      if (this.amSelected) {
        this.listQuery.push({
          keyQuery: 'am_id',
          valueQuery: this.amSelected.value,
        })
      }
      await this.fetchListAdCreative()
    },

    handleAMStatusChange() {
      this.currentPage = 1

      this.listQuery = this.listQuery.filter(item => item.keyQuery !== 'review_status')
      if (this.AMStatusSelected && this.AMStatusSelected.value !== '') {
        this.listQuery.push({
          keyQuery: 'review_status',
          valueQuery: this.AMStatusSelected.value,
        })
      }
      this.fetchListAdCreative()
    },

    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0])).format('YYYY-MM-DD')
        this.endDate = moment(new Date(selectedDates[1])).format('YYYY-MM-DD')
        await this.fetchListAdCreative()
      }
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null
      await this.fetchListAdCreative()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListAdCreative()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchListAdCreative()
    },

    openReviewAdCreativeSideBar(data) {
      this.adCreativeSelected = data
      this.showReviewAdCreativeSideBar = true
    },

    async importAdCreatives() {
      this.loadingImport = true
      const adAccounts = this.listAdAccount.split(',').map(item => item.trim()).filter(item => item !== '')
      const params = {
        ...(this.listAdAccount && { adsAccountIds: adAccounts }),
        ...(this.dayNumber && { numberOfBeforeReviewDays: this.dayNumber }),
      }
      await this.importAdCreative(params)
      if (this.status) {
        const intervalInit = setInterval(() => {
          this.fetchAdAccountImport()
          if (this.adAccountImport.status === 'SYNCED') {
            clearInterval(intervalInit)
            this.toastSuccess('The system is synchronizing, please wait 5-10 minutes')
            this.fetchListAdCreative()
            this.loadingImport = false
          }
        }, 10000)
      } else {
        this.loadingImport = false
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

</style>
<style lang="scss" scoped>
.img-thumbnail-creative {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  min-width: 110px;
  height: 70px;
  background-color: #cccccc6e;
  margin-right: 4px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .ic-play-video {
    display: none;
  }

  &:hover .ic-play-video {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #ccccccb3;
    cursor: pointer;
  }
}

.content-product-link {
  word-break: break-all;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-status {
  text-transform: capitalize;
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;
}

.am-note {
  width: 400px;

  .reason-message {
    padding: 12px;
    border-radius: 12px;
    margin-bottom: 12px;

    p {
      margin-bottom: 0;
    }
  }
}

.text-status, .reason-message {
  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}
</style>
