<template>
  <div>
    <!-- filter -->
    <b-card-code title="Filters">
      <!-- advance search input -->
      <b-row>
        <b-col
          md="6"
          class="d-flex justify-content-between align-items-center"
        >
          <b-form-group class="w-100">
            <label>{{ $t('common.textSearchUserByField', { nameSearch: 'account' }) }}</label>
            <b-input-group class="input-filter">
              <b-input-group-prepend>
                <b-form-select
                  v-model="searchSelected"
                  class="select-search-custom"
                  :options="searchOptions"
                  @input="handleChangeSearchType"
                />
              </b-input-group-prepend>
              <b-form-input
                v-model.trim="searchTerm"
                :placeholder="
                  searchSelected === 'email'
                    ? 'Search by user email'
                    : 'Search by user ID'
                "
                :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                class="d-inline-block"
                @wheel="handleMouseWheelInput"
                @input="handleSearch"
                @keyup.enter="handleSubmitSearch"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          class="country-tag d-flex justify-content-between align-items-center"
        >
          <b-form-group class="w-100">
            <label> Country </label>
            <v-select
              v-model="countrySelected"
              class="input-height"
              multiple
              placeholder="Select country"
              :options="availableOptions"
            />
          </b-form-group>
          <b-button
            variant="primary"
            class="btn-search"
            @click.prevent="handleCountryChange"
          >
            {{ $t('common.search') }}
          </b-button>
        </b-col>
        <b-col
          v-if="hasAM"
          :md="isExclusivePartner? 3 : 4"
          class="country-tag d-flex justify-content-between align-items-center select-filter-am"
        >
          <b-form-group class="w-100">
            <label>
              Filter by AM
            </label>
            <v-select
              v-model="amSelected"
              class="input-height"
              placeholder="Select AM"
              :options="listAMOptions"
              :filter-by="handleSearchAm"
              @input="handleAmChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isOnlyEcomdyPlatform"
          md="2"
        >
          <b-form-group>
            <label>
              Filter by Platform
            </label>

            <b-form-select
              v-model="registerFromServiceSelected"
              :options="registerFromServiceOptions"
              @input="handleServiceChange"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <label>
              {{ $t('account.status') }}
            </label>

            <b-form-select
              v-model="statusSelected"
              :options="statusOptions"
              @input="handleStatusChange"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isExclusivePartner"
          md="3"
        >
          <b-form-group>
            <label>Creative Status</label>

            <b-form-select
              v-model="statusProductVideoSelected"
              :options="statusOptions"
              @input="handleStatusChange"
            />
          </b-form-group>
        </b-col>
        <b-col :md="isExclusivePartner ? 3 : 3">
          <b-form-group>
            <label>{{ $t('common.requestedAt') }}</label>
            <b-input-group class="input-group-merge">
              <flat-pickr
                v-model="rangeDate"
                class="form-control"
                placeholder="Select date"
                :config="configDate"
                @on-close="handleDateChange"
              />
              <b-input-group-append
                v-if="startDate && endDate"
                is-text
                class="close-icon"
              >
                <feather-icon
                  class="cursor-pointer"
                  icon="XIcon"
                  @click="clearDate"
                />
              </b-input-group-append>
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  icon="CalendarIcon"
                />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-code>

    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="currentColumns"
      style-class="vgt-table custom-table"
      :rows="ProductLinks"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: totalProductLinks > 0,
      }"
      :sort-options="{
        enabled: totalProductLinks > 0,
      }"
      :is-loading="loading"
      :group-options="{
        enabled: false,
      }"
    >
      <!-- @on-sort-change="onSortChange" @on-row-click="onRowClick" -->
      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ads Account -->
        <a
          v-if="props.column.field === 'userId'"
          class="d-flex align-items-center"
          target="_blank"
          :href="`/user/${props.row.localUserId}?type=productLinks`"
        >
          <b-img
            :id="`avt-user-${props.row._id}`"
            rounded
            class="ads-avt-img"
            :src="getAvatar(props.row.registerFromService)"
          />
          <b-tooltip
            v-if="isEcomdyPlatform && props.row.registerFromService"
            placement="top"
            triggers="hover"
            :target="`avt-user-${props.row._id}`"
          >
            {{ getUserPlatform(props.row.registerFromService) }}
          </b-tooltip>
          <div class="d-flex flex-column">
            <p class="text-secondary font-weight-bold mb-0 underline">
              {{ props.row.fullName && capitalise(props.row.fullName) }}
              <br>
              <small class="text-placeholder">
                {{ props.row.email }}
              </small>
            </p>
          </div>
        </a>

        <!-- Column: messages -->
        <span v-else-if="props.column.field === 'reason'">
          <feather-icon
            v-if="
              props.row.reviewProductResults &&
                props.row.reviewProductResults.length > 0 &&
                (props.row.profileStatus === 'approved' ||
                  props.row.profileStatus === 'rejected')
            "
            icon="EyeIcon"
            size="16"
            class="text-body align-middle cursor-pointer icon-reason-block"
            @click="openReasonRejected(props.row)"
          />
        </span>

        <!-- Column: messages -->
        <div
          v-else-if="props.column.field === 'country'"
          class="td-country"
        >
          <p
            v-if="props.row.country"
            class="m-0"
          >
            {{ props.row.country }}
          </p>
          <p
            v-else
            class="m-0"
          >
            N/A
          </p>
        </div>

        <!-- Column: products.productURLs -->
        <div v-else-if="props.column.field === 'products.productURLs'">
          <div v-if="productLinkList(props.row).length > 0">
            <div
              v-for="(item, index) in productLinkList(
                props.row
              )"
              :key="index"
              class="product-link d-flex align-items-center"
            >
              <div
                class="d-flex align-items-center"
              >
                <p
                  v-b-tooltip="item.productUrl"
                  class="
                text-blue
                underline
                cursor-pointer
                tag-content
                content-product-link
                m-0
              "
                  @click="goToUrl(item.productUrl)"
                >
                  {{ item.productUrl }}
                </p>
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      :disabled="item.status === 'approved'"
                      @click="approveProductLink(props.row, item.productUrl)"
                    >
                      <feather-icon
                        class="mr-50"
                        icon="CheckCircleIcon"
                      />
                      <span>{{ $t('common.approve') }}</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      :disabled="item.status === 'rejected'"
                      @click="openRejectedTask(props.row ,item)"
                    >
                      <feather-icon
                        icon="ThumbsDownIcon"
                        class="mr-50"
                      />
                      <span>{{ $t('common.reject') }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="m-0">
              N/A
            </p>
          </div>
        </div>

        <!-- Column: targetMarkets -->
        <div v-else-if="props.column.field === 'targetMarkets'">
          <div v-if="productLinkList(props.row).length > 0">
            <div
              v-for="(item, index) in productLinkList(
                props.row
              )"
              :key="index"
              class="target-market d-flex align-items-center"
            >
              <p
                class="tag-content m-0 content-target-market"
              >
                {{ targetMarketsToString(item) }}
              </p>
            </div>
          </div>
          <div v-else>
            <p class="m-0">
              N/A
            </p>
          </div>
        </div>

        <!-- Column: Status product link -->
        <div
          v-else-if="props.column.field === 'status'"
        >
          <div v-if="productLinkList(props.row).length > 0">
            <div
              v-for="(item, index) in productLinkList(props.row)"
              :key="index"
              class="product-link-status d-flex align-items-center"
            >
              <div
                class="tag-content m-0 content-product-link-status"
              >
                <p
                  class="content-status status-product-links"
                  :class="statusVariantRejected(item.status)"
                >
                  {{ item.status }}
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            <p class="m-0">
              N/A
            </p>
          </div>
        </div>
        <!-- Column: Status product link -->

        <!-- EXCLUSIVE_PARTNER -->

        <!-- Column: productVideo -->
        <div
          v-else-if="props.column.field === 'productVideo'"
          class="d-flex align-items-center"
        >
          <div
            v-if="productLinkList(props.row).length > 0"
            class="w-100"
          >
            <div
              v-for="(item, index) in productLinkList(props.row)"
              :key="index"
              class="list-product-video"
            >
              <div v-if="listVideoProduct(item)">
                <b-img
                  :src="require('@/assets/images/icons/ic-list-product-video.png')"
                  alt="list product video"
                  class="cursor-pointer"
                  @click="openModalListVideo({infoUser: props.row, infoProductSelected: item})"
                />
              </div>
              <div
                v-else
                class="d-flex align-items-center"
              >
                N/A
              </div>
            </div>
          </div>
          <div
            v-else
            class="none-value"
          >
            N/A
          </div>
        </div>
        <!--end product video-->

        <!-- Column: status product -->
        <div
          v-else-if="props.column.field === 'statusProductVideo'"
          class="d-flex align-items-center"
        >
          <div
            v-if="productLinkList(props.row).length > 0"
            class="w-100"
          >
            <div
              v-for="(item, index) in productLinkList(props.row)"
              :key="index"
              class="list-product-video"
            >
              <div v-if="statusProductVideo(item)">
                <div
                  class="tag-content m-0 content-product-link-status"
                >
                  <p
                    class="content-status status-product-links"
                    :class="statusVariantRejected(statusProductVideo(item))"
                  >
                    {{ statusProductVideo(item) }}
                  </p>
                </div>
              </div>
              <div
                v-else
                class="d-flex align-items-center"
              >
                N/A
              </div>
            </div>
          </div>
          <div
            v-else
            class="none-value"
          >
            N/A
          </div>
        </div>
        <!--end product video-->

        <div v-else-if="props.column.field === 'platform'">
          <span>
            {{ platformExclusivePartner(props.row) }}
          </span>
        </div>

        <div
          v-else-if="props.column.field === 'industry'"
          :class="industryExclusivePartner(props.row) !== 'N/A' ? 'column-industry': null"
        >
          <span>
            {{ industryExclusivePartner(props.row) }}
          </span>
        </div>

        <div v-else-if="props.column.field === 'screenshot'">
          <div
            v-if="dashboardScreenshot(props.row)"
            style="width: 300px"
          >
            <div
              v-for="(item, index) in dashboardScreenshot(props.row)"
              :key="index"
            >
              <a
                :href="item"
                target="_blank"
                rel="noopener noreferrer"
                class="text-blue link-screenshot"
              >
                {{ item }}
              </a>
            </div>
          </div>
          <div v-else>
            <span>
              N/A
            </span>
          </div>
        </div>
        <!-- END EXCLUSIVE_PARTNER -->

        <!-- Column: Status -->
        <div
          v-else-if="props.column.field === 'belongToPartner.partnerLocalId'"
        >
          <div
            v-if="props.row.belongToPartner && props.row.belongToPartner.partnerLocalId"
          >
            <span>{{ props.row.belongToPartner.partnerLocalId }}</span>
          </div>
          <div v-else>
            <p class="m-0">
              N/A
            </p>
          </div>
        </div>

        <!-- Column: amId -->
        <div v-else-if="props.column.field === 'amId'">
          <span
            v-if="props.row.amId"
            class="content-status"
          >
            {{ props.row.amId }}
          </span>
          <span v-else> N/A </span>
        </div>

        <!-- Column: Status -->
        <div
          v-else-if="props.column.field === 'products.status'"
          class="d-flex align-items-center"
        >
          <div
            v-if="props.row.products && props.row.products.status"
            class="status-product-links"
            :class="statusVariantRejected(props.row.products.status)"
          >
            <span class="content-status">{{ props.row.products.status }}</span>
          </div>
        </div>

        <!-- Column: Requested at -->
        <div
          v-else-if="props.column.field === 'requestAt'"
          class="d-flex flex-column"
        >
          <div v-if="props.row.products && props.row.products.requestedAt">
            <span>{{ createdDate(props.row.products.requestedAt) }}</span>
          </div>
          <div v-else>
            <span>N/A</span>
          </div>
        </div>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalProductLinks > 0"
        slot="pagination-bottom"
      >
        <div
          class="d-flex flex-wrap mt-1"
          :class="[
            { 'justify-content-center': $device.mobile },
            { 'justify-content-between': !$device.mobile },
          ]"
        >
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> {{ $t('pagination.pageLength') }} </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              {{
                $t('pagination.showing', {
                  pageFrom: pageLength * (currentPage - 1) + 1,
                  pageTo: pageLength * currentPage
                })
              }}
            </span>
          </div>
          <div>
            <b-pagination
              :total-rows="totalProductLinks"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <!-- custom loading  -->
      <template slot="loadingContent">
        <b-spinner
          variant="primary"
          type="grow"
          small
        />
      </template>
    </vue-good-table>

    <reject-reason-modal
      ref="open-modal-reason-reject"
      :user-data="userSelected === null ? {} : userSelected"
    />

    <Reject-product-link
      :local-user-id="localUserId"
      :detail-product-link="detailProductLink"
      :show-side-bar.sync="isTaskReasonRejectActive"
      @fetchProductLinks="fetchProductLinks"
    />

    <list-product-video
      ref="open-modal-list-product-video"
      :local-user-id="localUserId"
      :product-url="productUrl"
      :product-link-selected="productLinkSelected"
      @fetchProductLinks="fetchProductLinks"
    />
  </div>
</template>
<script>
import {
  BPagination,
  BInputGroupAppend,
  BInputGroup,
  BFormGroup,
  BCol,
  BRow,
  BFormSelect,
  BInputGroupPrepend,
  BFormInput,
  BSpinner,
  BImg,
  BButton,
  BDropdown,
  BDropdownItem,
  VBTooltip, BTooltip,
} from 'bootstrap-vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import BCardCode from '@core/components/b-card-code'
import { PROFILE_STATUS, COUNTRIES, REGISTER_FROM_SERVICE } from '@/constants'
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { VueGoodTable } from 'vue-good-table'
import _get from 'lodash/get'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import _maxBy from 'lodash/maxBy'
import RejectReasonModal from '@/views/user/components/RejectReasonModal.vue'
import vSelect from 'vue-select'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
import Ripple from 'vue-ripple-directive'
import ListProductVideo from '@/views/product-links/components/ListProductVideo.vue'
import axiosLib from 'axios'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import usersMixin from '@/mixins/usersMixin'
import RejectProductLink from './RejectProductLink.vue'

const { mapActions } = createNamespacedHelpers('productLinks')
const { mapActions: mapActionsMyTask } = createNamespacedHelpers('myTask')
const { mapActions: mapActionsUser } = createNamespacedHelpers('user')

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

export default {
  components: {
    BTooltip,
    ListProductVideo,
    BPagination,
    BInputGroupAppend,
    BInputGroup,
    BFormGroup,
    BCol,
    BRow,
    BFormSelect,
    BInputGroupPrepend,
    BFormInput,
    BSpinner,
    BImg,
    BButton,
    BDropdown,
    BDropdownItem,

    BCardCode,

    flatPickr,

    VueGoodTable,
    RejectReasonModal,
    vSelect,
    RejectProductLink,
  },

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [numberFormatMixin, toastification, envMixin, generalConfigsMixin, usersMixin],

  data() {
    return {
      searchTerm: '',
      searchSelected: 'localUserId',

      isTaskReasonRejectActive: false,

      userSelected: null,
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },
      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],

      currentPage: 1,

      // status filter
      statusSelected: '',
      statusProductVideoSelected: '',

      statusOptions: [
        {
          text: 'All status',
          value: '',
        },
        {
          text: 'Approved',
          value: PROFILE_STATUS.APPROVED,
        },
        {
          text: 'Rejected',
          value: PROFILE_STATUS.REJECTED,
        },
        {
          text: 'Reviewing',
          value: PROFILE_STATUS.REVIEWING,
        },
        {
          text: 'Not Found',
          value: PROFILE_STATUS.NOT_FOUND,
        },
      ],

      countryOptions: COUNTRIES.map(item => item.name),
      countrySelected: [],

      urlVideo: null,

      columnsExclusive: [
        {
          label: 'Product Video',
          field: 'productVideo',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-0 border-left-0 border-right-0 align-middle td-product-video',
        },
        {
          label: 'Creative status',
          field: 'statusProductVideo',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-0 border-left-0 border-right-0 align-middle td-product-video',
        },
        {
          label: 'Platform',
          field: 'platform',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Industry',
          field: 'industry',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Screenshot',
          field: 'screenshot',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],
      currentColumns: [],
      localUserId: null,
      detailProductLink: {},
      amSelected: null,
      listUserInfoSelected: null,
      productLinkSelected: [],
      productUrl: null,

      // platform filter
      registerFromServiceSelected: null,
      registerFromServiceOptions: [
        {
          text: 'All',
          value: null,
        },
        {
          text: 'TTM',
          value: REGISTER_FROM_SERVICE.TTM,
        },
        {
          text: 'Pancake',
          value: REGISTER_FROM_SERVICE.PANCAKE,
        },
        {
          text: 'TTAC',
          value: REGISTER_FROM_SERVICE.TTAC,
        },
      ],
    }
  },

  computed: {
    REGISTER_FROM_SERVICE() {
      return REGISTER_FROM_SERVICE
    },
    // ...mapGetters(['listProductLinks', 'loading']),
    // ...mapGettersMyTask(['searchTask']),
    // ...mapGettersUser(['message', 'status', 'listAM']),

    ...mapGetters({
      listProductLinks: 'productLinks/listProductLinks',
      messageGetListProduct: 'productLinks/message',
      statusGetListProduct: 'productLinks/status',
      loading: 'productLinks/loading',
      searchTask: 'myTask/searchTask',
      listAM: 'user/listAM',
      message: 'user/message',
      status: 'user/status',
      generalConfigs: 'auth/generalConfigs',
    }),

    columns() {
      return [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Country',
          field: 'country',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Product Links',
          field: 'products.productURLs',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf th-product-link',
          tdClass: 'p-0 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Target markets',
          field: 'targetMarkets',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf th-product-link',
          tdClass: 'p-0 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Product link status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf th-product-link',
          tdClass: 'p-0 border-left-0 border-right-0 align-middle td-status-video',
        },
        {
          label: this.$t('account.accountInfo'),
          field: 'userId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Belong to partner id',
          field: 'belongToPartner.partnerLocalId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasPartnership,
        },
        {
          label: 'Belong to AM',
          field: 'amId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasAM,
        },
        {
          label: 'Products status',
          field: 'products.status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Request at',
          field: 'requestAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ]
    },

    statusProductVideo() {
      return itemVideo => itemVideo?.summaryVideos?.status
    },

    listVideoProduct() {
      return item => item?.summaryVideos?.videos
    },

    ProductLinks() {
      return _get(this.listProductLinks, ['content'], [])
    },

    totalProductLinks() {
      return _get(this.listProductLinks, ['paging', 'total'], 0)
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    statusVariantRejected() {
      const statusColor = {
        [PROFILE_STATUS.APPROVED]: 'approved',
        [PROFILE_STATUS.REJECTED]: 'rejected',
        [PROFILE_STATUS.REVIEWING]: 'reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found',
      }

      return status => statusColor[status]
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    availableOptions() {
      return this.countryOptions?.filter(
        opt => this.countrySelected?.indexOf(opt) === -1,
      )
    },

    productLinkList() {
      return val => {
        if (!val?.products?.productURLs) return []

        const productLinks = val?.products?.productURLs
        const convertedUrls = Object.keys(productLinks)
          .map(value => ({
            productUrl: value,
            ...productLinks[value],
          }))

        return convertedUrls.reverse()
      }
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    targetMarketsToString() {
      return item => item?.targetMarkets && Array.isArray(item?.targetMarkets) && item?.targetMarkets.length > 0 && item?.targetMarkets.join(', ')
    },

    listAMOptions() {
      const listAMMap = this.listAM.map(item => {
        const options = {
          label: item.email,
          value: item.localUserId,
        }
        return options
      })
      const optionNone = {
        label: 'N/A',
        value: 0,
      }
      return [optionNone, ...listAMMap]
    },

    platformExclusivePartner() {
      return value => value?.exclusiveVerifyInfo?.platform || 'N/A'
    },

    industryExclusivePartner() {
      return value => value?.exclusiveVerifyInfo?.industry || 'N/A'
    },

    dashboardScreenshot() {
      return value => value?.exclusiveVerifyInfo?.dashboardScreenshots
    },
  },

  watch: {
    generalConfigs: {
      handler(configValue) {
        if (configValue?.system === 'ADREACH') {
          this.currentColumns = [...this.columns.slice(0, 5), ...this.columnsExclusive, ...this.columns.slice(5)]
          this.statusSelected = ''
          this.statusProductVideoSelected = PROFILE_STATUS.REVIEWING
        } else {
          this.currentColumns = this.columns
          this.statusSelected = PROFILE_STATUS.REVIEWING
          this.statusProductVideoSelected = ''
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    this.fetchProductLinks()
    this.getListAM()
    // if (this.searchTask.id && this.searchTask.status) {
    //   this.searchTerm = this.searchTask.id
    //   this.statusSelected = this.searchTask.status
    //   this.handleSearch(this.searchTask.id)
    // }
  },

  beforeDestroy() {
    abort()
    this.setSearchTask({
      id: '',
      status: '',
    })
  },

  methods: {
    ...mapActions([
      'getListProductLinks',
      'approveTicket',
      'rejectTicket',
      'commentTicket',
      'handleProductVideo',
    ]),

    ...mapActionsMyTask(['setSearchTask']),
    ...mapActionsUser(['updateStatusProductLinks', 'getListAM']),

    openModalListVideo({ infoUser, infoProductSelected }) {
      this.productUrl = infoProductSelected.productUrl
      this.localUserId = infoUser.localUserId
      this.productLinkSelected = infoProductSelected?.summaryVideos?.videos
      this.$refs['open-modal-list-product-video'].showModal()
    },

    openReasonRejected(data) {
      this.userSelected = data
      this.$refs['open-modal-reason-reject'].showModal()
    },

    openRejectedTask(detail, val) {
      this.localUserId = detail.localUserId
      this.detailProductLink = val
      this.isTaskReasonRejectActive = true
    },

    capitalise(value) {
      return value && value.charAt(0)
        .toUpperCase() + value.slice(1)
    },

    contentReason(val) {
      const itemReason = _maxBy(val.reviewProductResults, item => item.at)
      return itemReason?.content
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchProductLinks()
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null
      await this.fetchProductLinks()
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchProductLinks()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchProductLinks()
    },

    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0]))
          .format(
            'YYYY-MM-DD',
          )
        this.endDate = moment(new Date(selectedDates[1]))
          .format('YYYY-MM-DD')
        await this.fetchProductLinks()
      }
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchProductLinks()
      } else if (
        searching.toString().length > 3
        && this.searchSelected !== 'email'
      ) {
        this.debounce = setTimeout(() => {
          this.fetchProductLinks()
        }, 600)
      } else if (this.searchSelected === 'email') {
        if (this.validateEmail(this.searchTerm)) {
          this.debounce = setTimeout(() => {
            this.fetchProductLinks()
          }, 1000)
        }
      }
    },

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchProductLinks()
      }
    },

    async fetchProductLinks() {
      // if (
      //   this.searchTerm
      //   && !this.validateEmail(this.searchTerm)
      //   && this.searchSelected === 'email'
      // ) {
      //   return
      // }

      abort()

      const { countrySelected } = this
      let countryString = ''
      if (countrySelected && Array.isArray(countrySelected) && countrySelected.length > 0) {
        countryString = this.countrySelected.map(item => encodeURIComponent(item))
        countryString = countryString.join(',')
      }

      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field
          }`,
        }),
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.startDate && { requestedProfileFrom: this.startDate }),
        ...(this.endDate && { requestedProfileTo: this.endDate }),
        ...(this.statusSelected && { productStatus: this.statusSelected }),
        ...(this.statusProductVideoSelected && this.isExclusivePartner && { productVideoStatus: this.statusProductVideoSelected }),
        ...(countryString && { country: countryString }),
        ...(this.amSelected && { amId: this.amSelected.value }),
        ...(this.registerFromServiceSelected && { registerFromService: this.registerFromServiceSelected }),

      }
      await this.getListProductLinks({ params, cancelToken: cancelToken.token })
      if (!this.statusGetListProduct && this.messageGetListProduct) {
        this.toastFailure(this.messageGetListProduct)
      }
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchProductLinks()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchProductLinks()
    },

    async handleCountryChange() {
      this.currentPage = 1
      await this.fetchProductLinks()
    },

    async approveProductLink(val, productLink) {
      const paramsApprove = {
        id: val.localUserId,
        action: 'approve',
        productURL: productLink,
      }
      await this.updateStatusProductLinks(paramsApprove)
      if (this.status) {
        this.toastSuccess('Approved product links success')
        this.fetchProductLinks()
      } else {
        this.toastFailure(this.message)
      }
    },

    handleSearchAm(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (label || '').toLocaleLowerCase()
        .indexOf(searchTxt) > -1 || option?.value?.toString()
          .toLocaleLowerCase()
          .indexOf(searchTxt) > -1
    },

    async handleAmChange() {
      this.currentPage = 1
      await this.fetchProductLinks()
    },

    async handleServiceChange() {
      this.currentPage = 1
      await this.fetchProductLinks()
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.custom-table {
  .th-product-link {
    padding-left: 0 !important;
  }

  .td-product-video {
    min-width: 150px;
    width: 100%;
  }

  .td-status-video {
    min-width: 150px;
    width: 100%;
  }
}

.country-tag {
  /* .b-form-tags {
      padding: 0;
      border: none;
    } */

  .vs__selected {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1);
  }

  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1) !important;
  }
}

.input-height {
  .vs__dropdown-toggle {
    padding: 3.09px 0 3.5px 0;
  }
}

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.reason-reject {
  width: 250px;
  max-height: 200px;
  overflow: auto;

  &-message {
    display: block;
    white-space: normal;
    text-align: start;
  }
}

.content-reason {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  p {
    margin: 0;
  }
}

.tooltip-inner {
  max-width: 700px !important;
}

.select-filter-am {
  .vs__open-indicator {
    display: none;
  }
}
</style>

<style lang="scss" scoped>
.column-industry {
  min-width: 150px;
}

.td-country {
  width: max-content;
}

.product-link-status {
  height: 74px;

  &:not(:last-child) {
    border-bottom: 1px solid #ebe9f1;
  }

  .content-product-link-status {
    padding: 0.366rem 0;
  }
}

.target-market {
  height: 74px;

  &:not(:last-child) {
    border-bottom: 1px solid #ebe9f1;
  }

  .content-target-market {
    padding: 0.666rem 0;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.product-link {
  height: 74px;

  &:not(:last-child) {
    border-bottom: 1px solid #ebe9f1;
  }

  .content-product-link {
    width: 400px;
    word-break: break-all;
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.btn-search {
  margin-top: 13px;
  margin-left: 1rem;
}

::v-deep .vgt-responsive {
  min-height: 45vh;
}

.text-capitalize {
  width: max-content;
}

.status-product-links {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  &.content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}

.list-product-video {
  padding: .5rem;
  height: 74px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid #ebe9f1;
  }
}

.none-value {
  padding: .5rem;
  height: 74px;
  display: flex;
  align-items: center;
}
</style>
