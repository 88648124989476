// eslint-disable-next-line import/prefer-default-export
export const ADS_ACCOUNT_TICKETS_STATUS = {
  OPEN: 'opening',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
  CLOSED: 'closed',
}

export const TRANSACTIONS_STATUS = {
  PENDING: 'pending',
  DONE: 'done',
  REJECTED: 'rejected',
  REFUNDED: 'refunded',
}

export const TICKET_ACTIONS = {
  APPROVE: 'Approve',
  REJECT: 'Reject',
  VIEW: 'View',
}

export const TICKET_TYPE = {
  RECHARGE_USER_BALANCE: 'recharge_user_balance',
  CREATE_ADS_ACCOUNT: 'create_ads_account',
  REFUND: 'request_refund',
  CASHBACK: 'cashback',
}

export const ADS_ACCOUNT_STATUS = {
  SHOW_ACCOUNT_STATUS_NOT_APPROVED: 'SHOW_ACCOUNT_STATUS_NOT_APPROVED',
  SHOW_ACCOUNT_STATUS_APPROVED: 'SHOW_ACCOUNT_STATUS_APPROVED',
  SHOW_ACCOUNT_STATUS_IN_REVIEW: 'SHOW_ACCOUNT_STATUS_IN_REVIEW',
  SHOW_ACCOUNT_STATUS_PUNISHED: 'SHOW_ACCOUNT_STATUS_PUNISHED',
  CONTRACT_NOT_YET_EFFECTIVE: 'CONTRACT_NOT_YET_EFFECTIVE',
  CLOSED: 'CLOSED',
}
