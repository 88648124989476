<template>
  <div>
    <div>
      <b-card-code title="Filters">
        <!-- advance search input -->
        <b-row>
          <b-col
            md="8"
            lg="9"
          >
            <b-form-group>
              <label>Search partner by field</label>
              <b-input-group class="input-filter">
                <b-input-group-prepend>
                  <b-form-select
                    v-model="searchSelected"
                    class="select-search-custom"
                    :options="searchOptions"
                    @input="handleChangeSearchType"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="searchTerm"
                  :placeholder="
                    searchSelected === 'partnerEmail'
                      ? 'Search by partner email'
                      : 'Search by partner ID'
                  "
                  :type="searchSelected === 'forPartnerLocalId' ? 'number' : 'text'"
                  class="d-inline-block"
                  @wheel="handleMouseWheelInput"
                  @input="handleSearch"
                  @keyup.enter="handleSubmitSearch"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            lg="3"
          >
            <btn-loading
              class="
                btn-addNewUser
                d-flex
                justify-content-center
                align-items-center
                m-0
              "
              type="submit"
              variant="primary"
              block
              :loading="loading"
              @click="editSideBar('add')"
            >
              New gift code
            </btn-loading>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            offset-md="3"
            offset-lg="5"
            md="4"
            lg="3"
          >
            <b-form-group>
              <label> Status </label>

              <b-form-select
                v-model="statusSelected"
                :options="statusOptions"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="5"
            lg="4"
          >
            <b-form-group>
              <label>Created At & End At</label>
              <b-input-group class="input-group-merge">
                <flat-pickr
                  v-model="rangeDate"
                  class="form-control"
                  placeholder="Select date"
                  :config="{ mode: 'range' }"
                  @on-close="handleDateChange"
                />
                <b-input-group-append
                  v-if="startDate && endDate"
                  is-text
                  class="close-icon"
                >
                  <feather-icon
                    class="cursor-pointer"
                    icon="XIcon"
                    @click="clearDate"
                  />
                </b-input-group-append>
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    icon="CalendarIcon"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-code>
      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="giftCodes"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: totalGiftCode > 0,
        }"
        :sort-options="{
          enabled: totalGiftCode > 0,
        }"
        :is-loading="loading"
        @on-sort-change="onSortChange"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: Worth Gift -->
          <div
            v-if="props.column.field === 'value'"
            class="d-flex flex-column"
          >
            <span>${{ props.row.value }}</span>
          </div>

          <!-- Column: savedQuantity -->
          <div
            v-else-if="props.column.field === 'savedQuantity'"
            class="d-flex flex-column"
          >
            <span>{{ savedQuantity(props.row) }}</span>
          </div>

          <!-- Column: paidQuantity -->
          <div
            v-else-if="props.column.field === 'paidQuantity'"
            class="d-flex flex-column"
          >
            <span>{{ paidQuantity(props.row) }}</span>
          </div>

          <!-- Column: start -->
          <div
            v-else-if="props.column.field === 'startedAt'"
            class="d-flex flex-column"
          >
            <span>{{ createdDate(props.row.startedAt) }}</span>
          </div>

          <!-- Column: expired -->
          <div
            v-else-if="props.column.field === 'endedAt'"
            class="d-flex flex-column"
          >
            <span>{{ createdDate(props.row.endedAt) }}</span>
          </div>

          <!-- Column: condition -->
          <div
            v-else-if="props.column.field === 'condition'"
            class="content-condition"
          >
            <ul class="p-0 mb-0">
              <li>
                <span>Min Total spend: ${{ props.row.minSpend }}</span>
              </li>
              <li>
                <span>Max Total spend: ${{ props.row.maxSpend }}</span>
              </li>
            </ul>
          </div>

          <!-- Column: timeOfUse -->
          <div
            v-else-if="props.column.field === 'timeOfUse'"
            class="content-condition"
          >
            <p class="m-0">
              {{ timeOfUse(props.row) }}
            </p>
          </div>

          <!-- Column: minimumSpending -->
          <div
            v-else-if="props.column.field === 'minimumSpending'"
            class="content-condition"
          >
            <p class="m-0">
              {{ minimumSpending(props.row) }}
            </p>
          </div>

          <!-- Column: Partner Info -->
          <div
            v-else-if="props.column.field === 'forPartnerId'"
            class="d-flex align-items-center cursor-pointer"
          >
            <b-img
              rounded
              class="ads-avt-img"
              :src="userInfo()"
            />
            <div>
              <p class="mb-0">
                {{ forPartner(props.row).fullName || "" }}
                <span v-if="props.row.whiteList">
                  <b-img
                    rounded
                    class="ads-avt-img"
                    :src="
                      require('@/assets/images/pages/payment/icon-white-list.svg')
                    "
                  />
                </span>
              </p>
              <span class="text-placeholder">{{
                forPartner(props.row).email || ""
              }}</span>
            </div>
          </div>

          <!-- Column: Status -->
          <span
            v-else-if="props.column.field === 'status'"
            class="position-relative"
          >
            <b-badge
              class="text-capitalize status-gift-code"
              :variant="statusVariant(props.row.status)"
              :class="props.row.status"
            >
              <span class="content-status">{{ props.row.status }}</span>
            </b-badge>
          </span>

          <!-- Column: Actions -->
          <span v-else-if="props.column.field === 'action'">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <span
                  @click.prevent="handleDeactivatedGiftCode(props.row._id)"
                >Deactivate</span>
              </b-dropdown-item>
              <b-dropdown-item @click="editSideBar('edit', props.row)">
                <span>Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalGiftCode > 0"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap mt-1 justify-content-between">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> of {{ totalGiftCode }} user(s) </span>
            </div>
            <div>
              <b-pagination
                :total-rows="totalGiftCode"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>
      </vue-good-table>
    </div>

    <!-- Note sidebar -->
    <edit-side-bar
      :show-side-bar.sync="showSideBar"
      :edit-mode.sync="editMode"
      :gift-code-selected="giftCodeSelected === null ? {} : giftCodeSelected"
      @fetch-gift-code="fetchGiftCode"
    />
  </div>
</template>
<script>
/* eslint-disable no-underscore-dangle */
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import moment from 'moment'
import { DATE_RANGE, STATUS_GIFT_CODE, USER_STATUS } from '@/constants'
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import BCardCode from '@core/components/b-card-code'
import {
  BPagination,
  BSpinner,
  BImg,
  BBadge,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BRow,
  BCol,
  BInputGroup,
  VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import flatPickr from 'vue-flatpickr-component'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import EditSideBar from './components/EditSideBar.vue'

const { mapActions, mapGetters } = createNamespacedHelpers('giftCode')

export default {
  components: {
    BPagination,
    BFormSelect,
    BSpinner,
    BImg,
    BDropdown,
    BDropdownItem,
    BBadge,
    BFormGroup,
    BtnLoading,
    BFormInput,
    VueGoodTable,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BRow,
    BCol,
    flatPickr,
    BCardCode,
    // component
    EditSideBar,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification],
  data() {
    return {
      columns: [
        {
          label: 'Redeem code',
          field: 'code',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Worth gift',
          field: 'value',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Saved Quantity/ Total Quantity',
          field: 'savedQuantity',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Paid Quantity/ Total Quantity',
          field: 'paidQuantity',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Start',
          field: 'startedAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'End Date',
          field: 'endedAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Condition',
          field: 'condition',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Time of use within',
          field: 'timeOfUse',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf th-time-of-use',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Minimum Required Spending',
          field: 'minimumSpending',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Apply for partner',
          field: 'forPartnerId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.action'),
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],
      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: 'localUserId',
        type: 'desc',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],

      // date picker
      rangeDate: null,
      startDate: null,
      endDate: null,
      configDate: {
        mode: 'range',
      },

      // status filter
      statusSelected: null,
      statusOptions: [
        {
          text: 'All status',
          value: null,
        },
        {
          text: 'Active',
          value: STATUS_GIFT_CODE.ACTIVE,
        },
        {
          text: 'Deactivate',
          value: STATUS_GIFT_CODE.DEACTIVATE,
        },
        {
          text: 'Expired',
          value: STATUS_GIFT_CODE.EXPIRED,
        },
      ],

      // week, month, year filter
      dateSelected: null,
      dateOptions: DATE_RANGE,
      openAddFundModal: false,
      ticketComment: '',
      currentTicket: '',

      giftCodeSelected: null,

      // sidebar
      showSideBar: false,
      editMode: false,

      // search Dropdown
      idPartnerSearch: '',
      searchSelected: 'forPartnerLocalId',
      searchOptions: [
        {
          text: 'Partner ID',
          value: 'forPartnerLocalId',
        },
        {
          text: 'Partner email',
          value: 'partnerEmail',
        },
      ],
    }
  },

  computed: {
    ...mapGetters([
      'partnerInfo',
      'listPartnerNoPaging',
      'listGiftCode',
      'message',
      'status',
      'loading',
    ]),

    spendGiftCodeFormat() {
      return amount => amount?.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
    },

    minimumSpending() {
      return giftCode => this.spendGiftCodeFormat(giftCode.minimumSpending) || 'N/A'
    },

    savedQuantity() {
      return giftCode => `${giftCode.savedQuantity || 0}/${giftCode.quantity || 0}`
    },

    paidQuantity() {
      return giftCode => `${giftCode.paidQuantity || 0}/${giftCode.quantity || 0}`
    },

    timeOfUse() {
      return giftCode => `${giftCode.timeOfUse} days` || 'N/A'
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    totalGiftCode() {
      return _get(this.listGiftCode, ['paging', 'total'], 0)
    },

    giftCodes() {
      return _get(this.listGiftCode, ['content'], [])
    },

    forPartner() {
      return val => _get(val, ['forPartnerId'], {})
    },

    statusVariant() {
      const statusColor = {
        [USER_STATUS.ACTIVE]: 'light-success',
        [USER_STATUS.BLOCKED]: 'light-danger',
      }

      return status => statusColor[status]
    },

    userInfo() {
      return img => img || require('@/assets/images/common/ic-user-info.svg')
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    isUserActive() {
      return stt => stt === 'activate'
    },

    receivedMoney() {
      return data => _get(data, ['referralTracking', 'receivedMoney'])
    },
  },

  created() {
    this.fetchGiftCode()
    this.fetchAllPartner()
  },

  methods: {
    ...mapActions([
      'getListGiftCode',
      'deactivateGiftCode',
      'getListPartnerNoPaging',
      'getInfoUser',
    ]),

    async handleDeactivatedGiftCode(idGiftCode) {
      const params = {
        status: 'deactivate',
        id: idGiftCode,
      }
      await this.deactivateGiftCode(params)
      if (this.status) {
        this.toastSuccess('Deactivate gift code success')
        this.fetchGiftCode()
      } else {
        this.toastFailure(this.message)
      }
    },

    async fetchGiftCode() {
      if (
        this.searchTerm
        && !this.validateEmail(this.searchTerm)
        && this.searchSelected === 'partnerEmail'
      ) {
        return
      }

      const params = {
        page: this.currentPage,
        size: this.pageLength,
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.startDate && { startedAt: this.startDate }),
        ...(this.endDate && { endedAt: this.endDate }),
        ...(this.statusSelected && { status: this.statusSelected }),
      }
      await this.getListGiftCode(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        this.fetchGiftCode()
      } else if (searching.length > 3 && this.searchSelected !== 'partnerEmail') {
        this.debounce = setTimeout(() => {
          this.fetchGiftCode()
        }, 600)
      } else if (this.searchSelected === 'partnerEmail') {
        if (this.validateEmail(this.searchTerm)) {
          this.debounce = setTimeout(() => {
            this.fetchGiftCode()
          }, 1000)
        }
      }
    },

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'partnerEmail') {
        this.fetchGiftCode()
      }
    },

    async fetchAllPartner() {
      await this.getListPartnerNoPaging()
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchGiftCode()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchGiftCode()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchGiftCode()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchGiftCode()
    },

    async handleDateChange(selectedDates) {
      if (selectedDates.length === 2) {
        // check if user select start and end date already.
        this.dateSelected = null
        this.startDate = moment(new Date(selectedDates[0])).format(
          'YYYY-MM-DD',
        )
        this.endDate = moment(new Date(selectedDates[1])).format('YYYY-MM-DD')
        await this.fetchGiftCode()
      }
    },

    async clearDate() {
      this.rangeDate = null
      this.startDate = null
      this.endDate = null
      this.dateSelected = null
      await this.fetchGiftCode()
    },

    async handleStatusChange() {
      this.currentPage = 1
      await this.fetchGiftCode()
    },

    async handlePickDateChange(date) {
      const { value } = date
      if (value !== 'ALL') {
        const start = value.start.format('YYYY-MM-DD')
        const end = value.end.format('YYYY-MM-DD')
        this.startDate = start
        this.endDate = end
        this.rangeDate = `${start} to ${end}`
        this.fetchGiftCode()
      } else {
        this.clearDate()
      }
    },

    editSideBar(type, data) {
      this.giftCodeSelected = data || {}
      this.showSideBar = true
      this.editMode = type === 'edit'
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}
.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-addNewUser {
  margin-top: 24.449px !important;
}

.th-time-of-use{
  min-width: 150px !important;
}
</style>
<style lang="scss" scoped>
.dropdown-custom {
  position: absolute !important;
  top: -30px;
  left: 33px;
}

.icon-reason-block {
  position: absolute;
  right: -10px;
  top: -15px;
}
</style>

<style lang="scss" scoped>
.status-gift-code {
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  .content-status {
    margin: 0;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
  }
  &.activate {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.deactivate {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }
}

.content-condition {
  width: max-content;
  display: flex;
  align-items: center;
}
</style>
