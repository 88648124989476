<template>
  <div>
    <div>
      <b-card-code>
        <!-- advance search input -->
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="title-card">
            <h3 class="m-0">
              Filters
            </h3>
          </div>

          <div class="export-data-controller">
            <btn-loading
              class="btn-export btn-partner"
              type="submit"
              block
              variant="outline-primary"
              :loading="loadingDown"
              @click="submitExportData"
            >
              Export data
              <feather-icon
                icon="UploadIcon"
                size="16"
              />
            </btn-loading>
          </div>
        </div>
        <b-row class="form-product-links">
          <b-col
            md="4"
            class="country-tag d-flex justify-content-between align-items-center"
          >
            <b-form-group class="w-100">
              <label>{{ $t('common.textSearchUserByField', { nameSearch: 'user' }) }}</label>
              <b-input-group class="input-filter">
                <b-input-group-prepend>
                  <b-form-select
                    v-model="searchSelected"
                    class="select-search-custom"
                    :options="searchOptions"
                    @input="handleChangeSearchType"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="searchTerm"
                  :placeholder="
                    searchSelected === 'email'
                      ? 'Search by email'
                      : 'Search by ID'
                  "
                  :type="
                    searchSelected === 'localUserId' ||
                      searchSelected === 'adsAccountId'
                      ? 'number'
                      : 'text'
                  "
                  class="d-inline-block"
                  @wheel="handleMouseWheelInput"
                  @input="handleSearch"
                  @keyup.enter="handleSubmitSearch"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="hasAM"
            md="4"
          >
            <b-form-group>
              <label>
                Filter by AM
              </label>
              <v-select
                v-model="amSelected"
                class="input-height"
                placeholder="Select AM"
                :options="listAMOptions"
                :filter-by="handleSearchAm"
                @input="handleAmChange"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group>
              <label>
                Filter by status
              </label>
              <v-select
                v-model="statusSelected"
                class="input-height input-filter-status"
                placeholder="Select status"
                :options="listStatusOptions"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col>

        </b-row>
      </b-card-code>
      <!-- table -->
      <vue-good-table
        mode="remote"
        :columns="columns"
        :rows="inactiveAdAccount"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: totalInactiveAdAccount > 0,
        }"
        :sort-options="{
          enabled: totalInactiveAdAccount > 0,
        }"
        :select-options="{
          enabled: !isGoodvibesPlatform,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'selection-wrapper',
          selectionText: 'rows selected',
          clearSelectionText: 'Clear',
          selectAllByGroup: true // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :is-loading="loading"
        @on-selected-rows-change="selectionChanged"
        @on-sort-change="onSortChange"
      >
        <div slot="selected-row-actions">
          <b-button
            variant="outline"
            size="sm"
            v-bind="$attrs"
            v-on="$listeners"
            @click="deleteAdsAccount"
          >
            Close
          </b-button>
        </div>
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: User Info -->
          <a
            v-if="props.column.field === 'email'"
            class="d-flex align-items-center cursor-pointer"
            target="_blank"
            :href="`/user/${props.row.localUserId}`"
          >
            <b-img
              :id="`avt-user-${props.row.assignAdsAccount.userId._id}`"
              rounded
              class="ads-avt-img"
              :src="getAvatar(props.row.assignAdsAccount.userId.registerFromService)"
            />
            <b-tooltip
              v-if="isEcomdyPlatform && props.row.assignAdsAccount.userId.registerFromService"
              placement="top"
              triggers="hover"
              :target="`avt-user-${props.row.assignAdsAccount.userId._id}`"
            >
              {{ getUserPlatform(props.row.assignAdsAccount.userId.registerFromService) }}
            </b-tooltip>
            <div>
              <p class="mb-0 text-secondary font-weight-bold mb-0 underline position-relative">
                {{ props.row.fullName || '' }}
              </p>
              <span class="text-placeholder">{{ props.row.userEmail || '' }}</span>
            </div>
          </a>

          <div
            v-else-if="props.column.field === 'AdsAccountId'"
            class="d-flex flex-column"
          >
            <b-link
              class="theme-text-primary underline"
              :href="`https://ads.tiktok.com/i18n/dashboard?aadvid=${props.row._id}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              {{ props.row._id }}
            </b-link>
          </div>

          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="adsStatusVariant(props.row.status)">
              {{ handleStatus(props.row.status) }}
            </b-badge></span>

          <!-- Column:  Remaining balance -->
          <span v-else-if="props.column.field === 'balance'">
            <div
              class="text-secondary font-weight-bold"
            >
              {{ currencyFormat(props.row.balance, props.row.currency) }}
            </div>
          </span>

          <!-- Column: ReportDate -->
          <div
            v-else-if="props.column.field === 'reportAt'"
            class="d-flex flex-column"
          >
            <span>{{ createdDate(props.row.shouldReportToAdminFrom) }}</span>
          </div>

          <!-- Column: amId -->
          <div
            v-else-if="props.column.field === 'amId'"
          >
            <span
              v-if="props.row.amId"
              class="content-status"
            >
              {{ props.row.amId }}
            </span>
            <span v-else>
              N/A
            </span>
          </div>

          <!--column: Reactive-->
          <div
            v-else-if="props.column.field === 'action'"
            class="cursor-pointer text-success"
            @click="openReactiveAdAccountModal(props.row._id)"
          >
            <b-button
              class="button-reactive"
              variant="outline-success"
            >
              Reactive
            </b-button>
          </div>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalInactiveAdAccount > 0"
          slot="pagination-bottom"
        >
          <div class="d-flex flex-wrap mt-1 justify-content-between">
            <!-- page length -->
            <div class="d-flex align-items-center mb-0">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                @input="handlePageChange"
              />
              <span class="text-nowrap"> of {{ totalInactiveAdAccount }} user(s) </span>
            </div>
            <div>
              <b-pagination
                :total-rows="totalInactiveAdAccount"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>
      </vue-good-table>
    </div>

    <reactive-ad-account-modal
      ref="open-modal-reactive-ad-account"
      :ad-account-id="adAccountId"
      @fetchInactiveAdAccount="fetchInactiveAdAccount"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import { createNamespacedHelpers } from 'vuex'
import _get from 'lodash/get'
import BCardCode from '@core/components/b-card-code'
import {
  BPagination,
  BSpinner,
  BImg,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend,
  BRow,
  BCol,
  BInputGroup,
  VBModal,
  BButton,
  VBTooltip,
  BLink,
  BBadge, BTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import paymentMixin from '@/mixins/paymentMixin'
import { toastification } from '@core/mixins/toast'
import envMixin from '@/mixins/envMixin'
import Ripple from 'vue-ripple-directive'
import { ADS_ACCOUNT_STATUS, REGISTER_FROM_SERVICE } from '@/constants'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import axios from '@/libs/axios'
import vSelect from 'vue-select'
import axiosLib from 'axios'
import { debounce } from 'lodash'
import usersMixin from '@/mixins/usersMixin'
import ReactiveAdAccountModal from './components/ReactiveAdAccountModal.vue'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('inactiveAdAccount')
const {
  mapActions: mapActionsUser,
  mapGetters: mapGettersUser,
} = createNamespacedHelpers('user')

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

export default {
  components: {
    BTooltip,
    BtnLoading,
    BPagination,
    BFormSelect,
    BSpinner,
    BImg,
    BFormGroup,
    BFormInput,
    VueGoodTable,
    BInputGroupPrepend,
    BInputGroup,
    BRow,
    BCol,
    BCardCode,
    BButton,
    BLink,
    BBadge,
    vSelect,

    ReactiveAdAccountModal,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, envMixin, paymentMixin, usersMixin],
  data() {
    return {
      loadingDown: false,

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: 'shouldReportToAdminFrom',
        type: 'desc',
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50'],

      amSelected: null,
      listAdAccountInactive: null,

      statusSelected: {
        label: 'Approved',
        value: 'SHOW_ACCOUNT_STATUS_APPROVED',
      },
      listStatusOptions: [
        {
          label: 'All status',
          value: null,
        },
        {
          label: 'Approved',
          value: 'SHOW_ACCOUNT_STATUS_APPROVED',
        },
        {
          label: 'Suspended',
          value: 'SHOW_ACCOUNT_STATUS_PUNISHED',
        },
        {
          label: 'Closed',
          value: 'CLOSED',
        },
      ],

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'Ads account ID',
          value: 'adsAccountId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      adAccountId: '',
    }
  },

  computed: {
    REGISTER_FROM_SERVICE() {
      return REGISTER_FROM_SERVICE
    },
    ...mapGetters(['listInactiveAdAccount', 'message', 'status', 'loading']),
    ...mapGettersUser(['listAM']),
    columns() {
      return [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'User Email',
          field: 'email',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Ad Account ID',
          field: 'AdsAccountId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Ad account status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'BC Partner ID',
          field: 'partnerBCId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Business Center',
          field: 'BCName',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Remaining balance',
          field: 'balance',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Report At',
          field: 'reportAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Belong to AM',
          field: 'amId',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
          hidden: !this.hasProductLink,
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ]
    },

    inactiveAdAccount() {
      return _get(this.listInactiveAdAccount, ['content'], [])
    },

    totalInactiveAdAccount() {
      return _get(this.listInactiveAdAccount, ['paging', 'total'], 0)
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    adsStatusVariant() {
      const statusColor = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: 'light-danger',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: 'light-success',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: 'light-warning',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: 'light-info',
        [ADS_ACCOUNT_STATUS.CONTRACT_NOT_YET_EFFECTIVE]: 'light-primary',
        [ADS_ACCOUNT_STATUS.CLOSED]: 'light-secondary',
      }

      return status => statusColor[status]
    },

    handleStatus() {
      const STATUS_MAPPER = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: 'Disapproved',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: 'Approved',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: 'In Review',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: 'Suspended',
        [ADS_ACCOUNT_STATUS.CONTRACT_NOT_YET_EFFECTIVE]: 'Contract not yet effective',
        [ADS_ACCOUNT_STATUS.CLOSED]: 'Closed',

      }
      return status => STATUS_MAPPER[status]
    },

    noFilter() {
      return this.searchTerm === '' && this.amSelected === null && this.statusSelected === null
    },

    listAMOptions() {
      const listAMMap = this.listAM.map(item => {
        const options = {
          label: item.email,
          value: item.localUserId,
        }
        return options
      })
      const optionNone = {
        label: 'N/A',
        value: 0,
      }
      return [optionNone, ...listAMMap]
    },
  },

  created() {
    this.fetchInactiveAdAccount()
    this.getListAM()
  },

  beforeDestroy() {
    abort()
  },

  methods: {
    ...mapActions(['getListInactiveAdAccount', 'deleteAdsAccounts']),
    ...mapActionsUser(['getListAM']),

    handleStatusChange() {
      this.fetchInactiveAdAccount()
    },

    async exportExcel() {
      this.loadingDown = true
      try {
        const params = {
          ...(this.sortTerm.field && {
            sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}`,
          }),
          page: this.currentPage,
          size: this.pageLength,
          ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
          ...(this.amSelected && { amId: this.amSelected.value }),
          ...(this.statusSelected && { status: this.statusSelected.value }),
        }

        const { data } = await axios.get('/api/export/ads-account-inactive-report', {
          responseType: 'blob',
          params,
        })
        const url = URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `list-inactive-ads-account-${new Date().toLocaleDateString()}.xlsx`,
        )
        document.body.appendChild(link)
        link.click()
        // Cleanup after download
        document.body.removeChild(link)
        this.loadingDown = false // Update loading state correctly
      } catch (e) {
        // const error = await new Response(e.data).json() || 'Server Error'
        if (e.response?.status === 403) {
          this.toastFailure('Not enough permission')
        } else {
          this.toastFailure(e.message)
        }
        this.loadingDown = false
      }
    },

    submitExportData() {
      this.loadingDown = true
      if (this.noFilter) {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want export all data?', {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Export data',
            cancelTitle: 'Close',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.exportExcel()
            } else {
              this.loadingDown = false
            }
          })
      } else {
        this.exportExcel()
      }
    },

    selectionChanged({ selectedRows }) {
      this.listAdAccountInactive = selectedRows
    },

    async deleteMultiAdsAccount() {
      const listAdsAccount = this.listAdAccountInactive.map(adAccount => {
        const {
          BCId,
          _id,
        } = adAccount
        return {
          adsAccountId: _id,
          BCId,
        }
      })
      await this.deleteAdsAccounts(listAdsAccount)
      if (this.status) {
        this.toastSuccess('Close ads account success!')
        await this.fetchInactiveAdAccount()
      } else {
        this.toastFailure(this.message)
      }
    },

    deleteAdsAccount() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want close ads account?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'primary',
          okTitle: 'Close',
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.deleteMultiAdsAccount()
          } else {
            this.loadingDown = false
          }
        })
    },

    async fetchInactiveAdAccount() {
      abort()
      const params = {
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}`,
        }),
        page: this.currentPage,
        size: this.pageLength,
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.amSelected && { amId: this.amSelected.value }),
        ...(this.statusSelected && { status: this.statusSelected.value }),
      }
      await this.getListInactiveAdAccount({ params, cancelToken: cancelToken.token })
      if (!this.status && this.message) {
        this.toastFailure(this.message)
      }
    },

    handleSearch: debounce(async function (searching) {
      if (searching === '' || ((searching.length > 3 && this.searchSelected !== 'email') || this.validateEmail(this.searchTerm))) {
        await this.fetchInactiveAdAccount()
      }
    }, 1000),

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        this.fetchInactiveAdAccount()
      }
    },

    handleChangeSearchType() {
      this.searchTerm = ''
      this.fetchInactiveAdAccount()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchInactiveAdAccount()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchInactiveAdAccount()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchInactiveAdAccount()
    },

    openReactiveAdAccountModal(data) {
      this.adAccountId = data
      this.$refs['open-modal-reactive-ad-account'].showConfirmModal()
    },

    handleSearchAm(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (label || '').toLocaleLowerCase()
        .indexOf(searchTxt) > -1 || option?.value?.toString()
          .toLocaleLowerCase()
          .indexOf(searchTxt) > -1
    },

    async handleAmChange() {
      this.currentPage = 1
      await this.fetchInactiveAdAccount()
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.form-product-links {
  .b-form-tags {
    border: none;
    padding: 0;
  }

  .vs__selected {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1);
  }
}

.country-tag {
  /* .b-form-tags {
    padding: 0;
    border: none;
  } */

  .vs__selected {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1);
  }

  .vs__dropdown-option--highlight {
    background: rgba(22, 33, 62, 0.1);
    color: rgba(22, 33, 62, 1) !important;
  }
}

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.select-search-custom {
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-height {
  .vs__dropdown-toggle {
    padding: 3.09px 0 3.5px 0;
  }
}

.input-filter-status {
  .vs__dropdown-toggle {
    .vs__selected-options {
      .vs__selected {
        margin-top: 0;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.export-data-controller {
  padding-top: 27px;
}

.btn-search {
  margin-top: 13px;
  margin-left: 1rem;
}

.btn-outline {
  background-color: #fff;
  color: #fab345;
  border-color: #fab345;
}

.button-reactive {
  padding: 0.3rem 0.5rem;
  border-radius: 0.358rem;
  font-size: 12px;
}
</style>
