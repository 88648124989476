<template>
  <div>
    <b-card-code
      title=""
      class="filter-card mb-0"
    >
      <!-- advance search input -->
      <b-row align-v="center">
        <!--        <b-col md="3">-->
        <!--          <div class="d-flex align-items-center mb-0">-->
        <!--            <span class="text-nowrap"> Show </span>-->
        <!--            <b-form-select-->
        <!--              v-model="pageLength"-->
        <!--              :options="pages"-->
        <!--              class="mx-1 entries"-->
        <!--              @input="handleChangeRecords"-->
        <!--            />-->
        <!--            <span class="text-nowrap"> entries </span>-->
        <!--          </div>-->
        <!--        </b-col>-->
        <b-col md="6">
          <div class="d-flex flex-column flex-md-row">
            <b-form-group class="mb-0 my-md-0 my-1 search-form">
              <b-input-group>
                <b-input-group-prepend>
                  <b-form-select
                    v-model="searchSelected"
                    class="select-search-custom"
                    :options="searchOptions"
                    @input="handleChangeSearchType"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="searchTerm"
                  :placeholder="
                    searchSelected === 'name'
                      ? 'Search by name'
                      : 'Search by ID'
                  "
                  class="d-inline-block"
                  @input="handleSearch"
                />
              </b-input-group>
            </b-form-group>
          </div>
        </b-col>
        <b-col md="6">

          <div class="d-flex flex-column flex-md-row justify-content-end">
            <b-button
              variant="primary"
              data-testid="btn-create-ads-account"
              class="ml-md-1"
              @click="openCreateAdsSidebar"
            >
              Create ads account
            </b-button>
            <b-button
              variant="outline-primary"
              data-testid="btn-assign-ads-account"
              class="ml-md-1"
              @click="openAssignAdsSidebar"
            >
              Assign ads account manually
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card-code>

    <b-modal
      v-model="showConfirmPopup"
      centered
      title="Are you make sure to delete this ads account ?"
    >
      <div class="ads-remove-items">
        <div
          v-for="(item, index) in adsAccountsSelected"
          :key="index"
          class="d-flex align-items-center wrapper-item"
        >
          <b-form-checkbox
            :data-testid="`checkbox-choose-ad-acc-delete-${item._id}`"
            :checked="true"
            @change="(checked) => onChangeSelect(item, checked)"
          />

          <b-img
            rounded
            class="ads-avt-img"
            :src="adsAccountImg()"
          />
          <div
            class="d-flex flex-column"
          >
            <b-link
              class="theme-text-primary font-weight-600"
              :href="`https://ads.tiktok.com/i18n/dashboard?aadvid=${item._id}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              {{ item.name }}
            </b-link>
            <span class="text-placeholder">ID: {{ item._id }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="hasSubscription"
        class="d-flex align-items-center font-weight-600 cancel-sub"
      >
        <b-form-checkbox
          data-testid="checkbox-cancel-sub"
          :checked="shouldCancelSubs"
          :disabled="isDisabledCheckBoxCancelSubs"
          @change="(checked) => shouldCancelSubs = checked"
        />
        <span :class="{ 'disable-checkbox-cancel-sub': isDisabledCheckBoxCancelSubs}">Cancel subscription?</span>
      </div>
      <template #modal-footer>
        <div class="w-100 text-center">
          <b-button
            data-testid="button-cancel-delete-ads-acc"
            class="mr-1"
            variant="flat-dark"
            @click="closeDeleteAdsAccountModal"
          >
            Cancel
          </b-button>
          <btn-loading
            data-testid="button-confirm-delete-ads-acc"
            variant="primary"
            class=""
            :loading="loading"
            :disabled="!adsAccountsRemove.length"
            @click="handleConfirmDelete"
          >
            Confirm
          </btn-loading>
        </div>
      </template>
    </b-modal>

    <!-- table -->
    <vue-good-table
      mode="remote"
      data-testid="table-list-ads-acc"
      :columns="columns"
      :rows="listAdsAccounts"
      :is-loading="loading"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :sort-options="{
        enabled: totalAccount > 0,
      }"
      :pagination-options="{
        enabled: totalAccount > 0,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'selection-wrapper',
        selectionText: 'rows selected',
        clearSelectionText: 'Clear',
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }"
      @on-sort-change="onSortChange"
      @on-selected-rows-change="selectionChanged"
    >
      <div slot="selected-row-actions">
        <b-button
          data-testid="button-close-ads-acc"
          variant="warning"
          size="sm"
          class="mr-1"
          :class="isDisableBtnClose ? 'disable-btn-close' : null"
          v-bind="$attrs"
          :disabled="isDisableBtnClose"
          v-on="$listeners"
          @click="closeAdsAccount"
        >
          Close
        </b-button>
        <b-button
          data-testid="button-delete-ads-acc"
          variant="outline"
          size="sm"
          v-bind="$attrs"
          v-on="$listeners"
          @click="removeAdsAccount"
        >
          Delete
        </b-button>
      </div>

      <template
        slot-scope="props"
        slot="table-row"
      >
        <!-- Column: Ads Account -->
        <div
          v-if="props.column.field === '_id'"
          class="d-flex flex-column justify-content-end"
        >
          <div class="d-flex align-items-center">
            <b-img
              rounded
              class="ads-avt-img"
              :src="adsAccountImg()"
            />
            <div
              data-testid="list-ads-account-detail"
              class="d-flex flex-column"
            >
              <b-link
                data-testid="ads-account-name"
                class="theme-text-primary font-weight-600"
                :href="`https://ads.tiktok.com/i18n/dashboard?aadvid=${props.row._id}`"
                target="_blank"
                rel="noreferrer noopener"
              >
                {{ props.row.name }}
              </b-link>
              <span
                data-testid="ads-account-id"
                class="text-placeholder"
              >ID: {{ props.row._id }}</span>
              <span
                data-testid="user-partner-id"
                class="text-placeholder"
              >PartnerId: {{ props.row.partnerBCId }}</span>
              <span
                v-if="props.row.BCId"
                data-testid="bc-agency-name"
                class="text-placeholder"
              >BCName: {{ getBCName(props.row.BCId) }}</span>
            </div>
          </div>
        </div>

        <!-- Column: Business Account -->
        <div
          v-else-if="props.column.field === 'BCId'"
          class="d-flex align-items-center"
        >
          <b-img
            rounded
            :src="require('@/assets/images/avatars/1.png')"
            class="avt-img"
          />
          <div class="d-flex flex-column">
            <b-link
              class="underline"
              :href="`https://business.tiktok.com/manage/overview?org_id=${props.row.BCId}`"
              target="_blank"
              rel="noreferrer noopener"
            >
              {{ props.row.name }}
            </b-link>
            <span>ID: {{ props.row.BCId }}</span>
          </div>
        </div>

        <!-- Column: timezone -->
        <div
          v-else-if="props.column.field === 'timezone'"
          class="d-flex align-items-center"
        >
          <span
            v-if="props.row.displayTimezone"
            class="text-nowrap text-capitalize"
          >
            {{ props.row.displayTimezone }}
          </span>
          <span v-else>
            {{ props.row.timezone }}
          </span>
        </div>

        <!-- Column: status -->
        <span
          v-else-if="props.column.field === 'status'"
          class="text-nowrap"
        >
          <b-badge
            :variant="adsStatusVariant(props.row.status)"
            class="font-14 content-status"
          >
            {{ handleStatus(props.row.status) }}
          </b-badge>
        </span>

        <!-- Column: createdDate -->
        <div
          v-else-if="props.column.field === 'createdAt'"
          class="d-flex flex-column"
        >
          <div
            v-if="props.row.assignAdsAccount && props.row.assignAdsAccount.at"
          >
            <span>{{ createdDate(props.row.assignAdsAccount.at) }}</span>
          </div>
        </div>

        <!-- Column: Balance -->
        <span v-else-if="props.column.field === 'balance'">
          {{ userBalance(props.row.balance) }} {{ props.row.currency }}
          <!--          {{ currencyFormat(props.row.balance, props.row.currency) }}-->
        </span>

        <!-- Column: Alert -->
        <span v-else-if="props.column.field === 'balanceReminder'">
          <span :class="alertTextColor(props.row.balanceReminder)">{{
            props.row.balanceReminder ? 'Yes' : 'No'
          }}</span>
        </span>

        <!-- Column: common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        v-if="totalAccount > 0"
        slot="pagination-bottom"
      >
        <div class="d-flex flex-wrap mt-1 justify-content-between">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> of {{ totalAccount }} account(s) </span>
          </div>
          <div>
            <b-pagination
              :total-rows="totalAccount"
              :value="currentPage"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>

      <div
        v-if="errorGetListAdAccount"
        slot="emptystate"
      >
        <p class="text-error">
          {{ errorGetListAdAccount }}
        </p>
      </div>
    </vue-good-table>

    <add-ads-account
      :show-side-bar="showAssignAds"
      @close-side-bar="showAssignAds = false"
      @fetch-ads-account-user="fetchAdsAccountUser"
    />

    <create-ads-account
      :show-side-bar="showCreateAds"
      @close-side-bar="showCreateAds = false"
      @fetch-ads-account-user="fetchAdsAccountUser"
    />

    <modal-confirm-close-ads-account
      ref="open-modal-close-ads-account"
      :ads-accounts-selected="adsAccountsSelected"
    />
  </div>
</template>
<script>
/* eslint-disable global-require */
import numberFormatMixin from '@/mixins/numberFormatMixin'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import BCardCode from '@core/components/b-card-code'
import { ADS_ACCOUNT_STATUS } from '@/constants'
import {
  BImg,
  BButton,
  BLink,
  BFormSelect,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroup,
  BBadge,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { toastification } from '@core/mixins/toast'
import Ripple from 'vue-ripple-directive'
import { createNamespacedHelpers } from 'vuex'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import ModalConfirmCloseAdsAccount from '@/views/user/components/modalConfirmCloseAdsAccount.vue'
import _get from 'lodash/get'
import envMixin from '@/mixins/envMixin'
import AddAdsAccount from './AddAdsAccount.vue'
import CreateAdsAccount from './CreateAdsAccount.vue'

const {
  mapActions,
  mapGetters,
} = createNamespacedHelpers('user')

export default {
  components: {
    BPagination,
    ModalConfirmCloseAdsAccount,
    BFormSelect,
    BLink,
    BFormGroup,
    BImg,
    BRow,
    BCol,
    BCardCode,
    BFormInput,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BButton,
    VueGoodTable,
    AddAdsAccount,
    BtnLoading,
    BBadge,
    CreateAdsAccount,
  },
  directives: {
    Ripple,
  },
  mixins: [numberFormatMixin, toastification, generalConfigsMixin, envMixin],
  props: {
    errorGetListAdAccount: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Account Name',
          field: '_id',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Account status',
          field: 'status',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: this.$t('account.timeZone'),
          field: 'timezone',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Created At',
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Account Balance',
          field: 'balance',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
        {
          label: 'Balance Alert',
          field: 'balanceReminder',
          thClass: 'p-1 border-left-0 border-right-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 align-middle',
        },
      ],
      currentPage: 1,
      searchTerm: '',

      // per page
      pageLength: 10,
      pages: ['10', '20', '50', '100'],
      // status filter
      searchSelected: 'id',
      searchOptions: [
        {
          text: 'Account id',
          value: 'id',
        },
      ],

      // sort
      sortTerm: {
        field: 'localUserId',
        type: 'desc',
      },

      // user

      adsAccountsSelected: [],
      adsAccountsRemove: [],
      showConfirmPopup: false,
      showAssignAds: false,
      showCreateAds: false,

      isDisableBtnClose: false,
      shouldCancelSubs: false,
      isDisabledCheckBoxCancelSubs: true,
    }
  },

  computed: {
    ...mapGetters(['message', 'status', 'userAdsAccounts', 'loading', 'userDetail']),

    listAdsAccounts() {
      return _get(this.userAdsAccounts, ['content'], [])
    },

    totalAccount() {
      return this.userDetail?.adsAccounts?.length
    },

    alertTextColor() {
      const txtColor = {
        [true]: 'text-danger',
        [false]: 'text-success',
      }

      return txt => txtColor[txt]
    },

    adsStatusVariant() {
      const STYLE_STATUS = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: 'light-danger',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: 'light-success',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: 'light-warning',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: 'light-info',
        [ADS_ACCOUNT_STATUS.CONTRACT_NOT_YET_EFFECTIVE]: 'light-primary',
        [ADS_ACCOUNT_STATUS.CLOSED]: 'light-secondary',

      }
      return status => STYLE_STATUS[status]
    },

    handleStatus() {
      const STATUS_MAPPER = {
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_NOT_APPROVED]: 'Disapproved',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_APPROVED]: 'Approved',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_IN_REVIEW]: 'In Review',
        [ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED]: 'Suspended',
        [ADS_ACCOUNT_STATUS.CONTRACT_NOT_YET_EFFECTIVE]: 'Contract not yet effective',
        [ADS_ACCOUNT_STATUS.CLOSED]: 'Closed',

      }
      return status => STATUS_MAPPER[status]
    },

    adsAccountImg() {
      return img => img || require('@/assets/images/common/ic-ad-info.svg')
    },

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    getBCName() {
      return bcId => {
        const currentBC = this.listOriginBC.find(item => item.BCId === bcId)
        if (currentBC) {
          return currentBC.BCName || bcId
        }
        return bcId
      }
    },
  },

  // watch: {
  //   adsAccountsSelected(value) {
  //     this.adsAccountsRemove = value
  //   },
  // },

  methods: {
    ...mapActions(['deleteAdsAccount', 'getAdsAccountsUser', 'cancelSubscription']),
    selectionChanged({ selectedRows }) {
      /* eslint-disable no-underscore-dangle */
      this.adsAccountsSelected = selectedRows
      const listAdsAccountClose = selectedRows.filter(account => (account.status === ADS_ACCOUNT_STATUS.CLOSED || account.status === ADS_ACCOUNT_STATUS.SHOW_ACCOUNT_STATUS_PUNISHED))
      this.isDisableBtnClose = listAdsAccountClose?.length > 0
    },

    openAssignAdsSidebar() {
      this.showAssignAds = true
    },

    openCreateAdsSidebar() {
      this.showCreateAds = true
    },

    removeAdsAccount() {
      this.adsAccountsRemove = this.adsAccountsSelected
      this.handleCheckBoxCancelSub()
      this.showConfirmPopup = true
    },

    closeAdsAccount() {
      this.$refs['open-modal-close-ads-account'].showModal()
    },

    onChangeSelect(data, checked) {
      if (!checked) {
        this.adsAccountsRemove = this.adsAccountsRemove.filter(
          item => item._id !== data._id,
        )
      } else {
        this.adsAccountsRemove.push(data)
      }

      this.handleCheckBoxCancelSub()
    },

    fetchAdsAccountUser() {
      this.$emit('fetch-user-info')
    },

    async handleConfirmDelete() {
      const adsAccountIds = this.adsAccountsRemove.map(item => item._id)
      await this.deleteAdsAccount({
        adsAccounts: adsAccountIds,
        userId: this.$route.params.id,
      })
      if (this.status) {
        if (this.shouldCancelSubs && this.hasSubscription) {
          await this.handleCancelSubscription()
        }
        this.toastSuccess('Account deleted !')
        this.closeDeleteAdsAccountModal()
        this.fetchAdsAccountUser()
        await this.fetchListAdsAccount()
      } else {
        this.toastFailure(this.message)
      }
    },

    async handleCancelSubscription() {
      await this.cancelSubscription({
        userId: this.$route.params.id,
        // shouldCancelSubs: this.shouldCancelSubs,
      })

      if (!this.status) {
        this.toastFailure(this.message)
      }
    },

    async handleSearch(searching) {
      this.searchTerm = searching
      this.onChangeList()
    },

    handleChangeSearchType() {
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListAdsAccount()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchListAdsAccount()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListAdsAccount()
    },

    onChangeList() {
      this.$emit('on-change', {
        size: this.pageLength,
        page: this.currentPage,
        keyword: this.searchTerm,
      })
    },

    userBalance(balance) {
      return this.amountFormat(balance)
        .toLocaleString('en-US')
    },

    async fetchListAdsAccount() {
      const { id } = this.$route?.params
      const params = {
        id,
        page: this.currentPage,
        size: this.pageLength,
        ...(this.sortTerm.field && { sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${this.sortTerm.field}` }),
      }
      await this.getAdsAccountsUser(params)
      if (!this.status) {
        this.toastFailure(this.message)
      }
    },
    closeDeleteAdsAccountModal() {
      this.showConfirmPopup = false
      this.isDisabledCheckBoxCancelSubs = true
      this.shouldCancelSubs = false
    },
    handleCheckBoxCancelSub() {
      if (this.adsAccountsRemove.length === this.totalAccount) {
        this.shouldCancelSubs = true
        this.isDisabledCheckBoxCancelSubs = false
      } else {
        this.shouldCancelSubs = false
        this.isDisabledCheckBoxCancelSubs = true
      }
    },
  },
}
</script>
<style lang="scss">
.content-status {
  text-transform: capitalize;
}
</style>

<style lang="scss" scope>
@import "@core/scss/vue/libs/vue-good-table.scss";

.ads-avt-img {
  width: 44px;
  height: 44px;
  margin-right: 10px;
}

.close-icon {
  .input-group-text {
    padding-left: 0;
    padding-right: 0;
  }
}

.filter-card {
  //border: 1px solid #eeeeee;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 16px;

  .card-header {
    display: none;
  }

  .select-search-custom {
    width: 100%;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .card-body {
    padding: 0;
  }
}

.selection-wrapper {
  a {
    margin-top: 7px;
    color: #3d405b;
  }

  button.btn-outline {
    background-color: #fff;
    color: #fab345;
    border-color: #fab345;
  }
}

.vgt-responsive {
  .vgt-checkbox-col {
    input[type="checkbox"]:checked {
      background-color: red;
    }
  }
}

.wrapper-item {
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px;

  &:not(:last-child) {
    margin-bottom: 15px;
  }
}

.cancel-sub {
  padding: 15px;
}

.ads-remove-items {
  max-height: 180px;
  overflow-y: auto;
}

//.modal-header {
//  padding: 32px 32px 20px 32px;
//}

button.close {
  display: none;
}

.filter-card {
  .entries {
    width: 91px;
  }
}

.search-form {
  flex: 1;
}

.text-error {
  margin-top: 1rem;
  color: #f02649;
  text-align: center;
}

.disable-btn-close {
  cursor: not-allowed;
}

.disable-checkbox-cancel-sub {
  opacity: 0.5;
}
</style>
