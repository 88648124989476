<template>
  <div class="container-product-link">
    <div class="form-add-product-link">
      <validation-observer
        ref="formValidation"
        v-slot="{ invalid }"
      >
        <b-card-title class="title-tab">
          Product user selling.
        </b-card-title>

        <div class="form-product-links">
          <b-row>
            <b-col
              cols="12"
              class="input-form"
            >

              <b-form-group
                class="product-tag"
                label-for="tags-validation"
              >
                <div class="d-flex justify-content-between">
                  <label class="form-label">
                    Product Url
                  </label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  id="fullName"
                  name="product link"
                  rules="required|url"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="productsData.productURL"
                      name="product link"
                      class="input-height"
                      placeholder="Enter product link"
                      data-testid="input-product-link"
                      :tabindex="1"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <validation-provider
                #default="{errors}"
                name="target market"
                rules="required"
              >
                <b-form-group class="m-0 country-tag">
                  <div class="d-flex justify-content-between">
                    <label class="form-label">
                      Target Market
                    </label>
                  </div>
                  <v-select
                    v-model="productsData.targetMarkets"
                    class="input-height"
                    multiple
                    placeholder="-- Select an option --"
                    data-testid="select-target-market"
                    :options="availableOptions"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-col>
          </b-row>

          <div
            v-if="errorAddProductLink"
            class="error-message-stripe"
          >
            <div class="d-flex align-items-start">
              <div class="d-flex align-items-center">
                <img
                  style="margin-right: 8px"
                  alt="alert"
                  :src="require('@/assets/images/common/ic-warning-red.svg')"
                >
                <p class="m-0 font-weight-600">
                  Error:
                </p>
              </div>
              <p class="content-error">
                {{ errorAddProductLink }}
              </p>
            </div>
          </div>

          <div class="btn-control">
            <b-button
              class="
            btn-submit btn-register
            input-height
            font-medium
            txt-color
            btn-continue
          "
              variant="primary"
              block
              type="submit"
              data-testid="btn-submit-product-link"
              :loading="loading"
              :disabled="invalid"
              @click.prevent="createProductLinkUser"
            >
              Create one
            </b-button>
          </div>
        </div>
      </validation-observer>
    </div>

    <div>
      <vue-good-table
        class="table-product-links"
        mode="remote"
        :columns="columns"
        :rows="listProductLink"
        :pagination-options="{
          enabled: totalProductLinks > 0,
        }"
        :sort-options="{
          enabled: totalProductLinks > 0,
        }"
        :is-loading="loading"
        @on-sort-change="onSortChange"
      >
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: productURLs -->
          <div
            v-if="props.column.field === 'productLink'"
            class="d-flex align-items-center"
          >
            <div
              v-if="props.row.productUrl"
              class="content-url"
            >
              <span
                class="detail"
                @click.prevent="goToUrl(props.row.productUrl)"
              >{{ props.row.productUrl }}</span>
            </div>
          </div>

          <!-- Column: Target Market -->
          <div
            v-else-if="props.column.field === 'targetMarkets'"
            class="d-flex align-items-center"
          >
            <div v-if="props.row.targetMarkets">
              <ul class="list-target m-0 p-0">
                <li
                  v-for="(item, index) in props.row.targetMarkets"
                  :key="index"
                  class="item-target"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </div>

          <!-- Column: create At -->
          <div
            v-else-if="props.column.field === 'createAt'"
            class="d-flex align-items-center"
          >
            <div v-if="props.row.requestedAt">
              <p class="m-0">
                {{ createdDate(props.row.requestedAt) }}
              </p>
            </div>
            <div v-else>
              <p class="m-0">
                {{ createdDate(props.row.approvedAt) }}
              </p>
            </div>
          </div>
          <!-- Column: create At -->

          <!-- Column: adminId -->
          <div
            v-else-if="props.column.field === 'adminId'"
            class="d-flex align-items-center"
          >
            <div v-if="props.row.reviewedBy">
              <p class="m-0">
                {{ props.row.reviewedBy }}
              </p>
            </div>
            <div v-else-if="props.row.rejectedBy">
              <p class="m-0">
                {{ props.row.rejectedBy }}
              </p>
            </div>
            <div v-else>
              <p class="m-0">
                N/A
              </p>
            </div>
          </div>

          <!-- Column: Message -->
          <div
            v-else-if="props.column.field === 'rejectedReason'"
            class="d-flex align-items-center"
          >
            <div
              v-if="props.row.rejectedReason"
              class="w-100"
            >
              <div
                class="content-reason"
                v-html="props.row.rejectedReason"
              />
            </div>
            <div v-else>
              <span>N/A</span>
            </div>
          </div>

          <!-- Column: Status -->
          <div
            v-else-if="props.column.field === 'status'"
            class="d-flex align-items-center"
          >
            <div
              v-if="props.row.status"
              class="status-product-links"
              :class="statusVariantRejected(props.row.status)"
            >
              <span class="content-status">{{ props.row.status }}</span>
            </div>
          </div>
          <!-- Column: Status -->

          <!-- Column: Actions -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  :disabled="props.row.status === 'approved'"
                  @click="approveAccountTicket(props.row)"
                >
                  <feather-icon
                    class="mr-50"
                    icon="CheckCircleIcon"
                  />
                  <span> Approve </span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="props.row.status === 'rejected'"
                  @click="openRejectedTask(props.row)"
                >
                  <feather-icon
                    icon="ThumbsDownIcon"
                    class="mr-50"
                  />
                  <span>Reject</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalProductLinks > 0"
          slot="pagination-bottom"
        >
          <div class="footer-table">
            <span
              class="text-secondary font-weight-600"
            >{{ totalProductLinks }} Product Link(s)</span>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
          />
        </template>

        <div
          v-if="errorGetUserInfo"
          slot="emptystate"
        >
          <p class="text-error">
            {{ errorGetUserInfo }}
          </p>
        </div>
      </vue-good-table>
    </div>

    <Reject
      :detail-product-link="detailProductLink"
      :show-side-bar.sync="isTaskReasonRejectActive"
    />
  </div>
</template>
<script>
import {
  BCardTitle,
  BInputGroup,
  BButton,
  BFormGroup,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import {
  PROFILE_STATUS,
  TOOLBAR_EDITOR_OPTIONS,
} from '@/constants'
import { required, url } from '@validations'
import { toastification } from '@core/mixins/toast'
import vSelect from 'vue-select'
import { VueGoodTable } from 'vue-good-table'
import icApproved from '@/assets/images/pages/payment/icon-approve.svg'
import icRejected from '@/assets/images/pages/payment/icon-rejected.svg'
import icReviewing from '@/assets/images/pages/payment/icon-warning.svg'
import icNotFound from '@/assets/images/pages/payment/icon-not-found.svg'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
// import { quillEditor } from 'vue-quill-editor'
import Reject from './Reject.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

const {
  mapGetters,
  mapActions,
} = createNamespacedHelpers('user')

export default {
  components: {
    vSelect,
    BCardTitle,
    BInputGroup,
    BButton,
    BFormGroup,
    VueGoodTable,
    BSpinner,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    Reject,
  },

  mixins: [toastification, generalConfigsMixin],

  props: {
    errorGetUserInfo: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      dir: 'ltr',
      productsData: {
        action: 'add',
        productURL: '',
        targetMarkets: [],
      },

      description: '',

      isTaskReasonRejectActive: false,
      // validation
      required,
      url,
      PROFILE_STATUS,
      isReject: false,
      isApprove: false,
      editorOption: {
        modules: {
          toolbar: {
            container: TOOLBAR_EDITOR_OPTIONS,
          },
        },
      },

      columns: [
        // {
        //   label: 'ID',
        //   field: 'id',
        //   thClass: 'p-1 border-0 header-table-mf',
        //   tdClass:
        //     'p-1 border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle',
        // },
        {
          label: 'Product link',
          field: 'productLink',
          thClass: 'th-table border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-product-link td-table',
        },
        {
          label: 'Target markets',
          field: 'targetMarkets',
          thClass: 'th-table border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-target-markets td-table',
        },
        {
          label: 'Create At',
          field: 'createAt',
          thClass: 'th-table border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-create-at td-table',
        },
        {
          label: 'Admin Id',
          field: 'adminId',
          thClass: 'th-table border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-admin-id td-table',
        },
        {
          label: 'Reason rejected',
          field: 'rejectedReason',
          thClass: 'th-table border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-rejected-reason td-table',
        },
        {
          label: 'Status',
          field: 'status',
          thClass: 'th-table border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-status td-table',
        },
        {
          label: 'Action',
          field: 'action',
          thClass: 'th-table border-0 header-table-mf',
          tdClass:
            'border-top-0 border-left-0 border-right-0 border-bottom-1 align-middle td-action td-table',
        },
      ],

      // per page
      pageLength: 10,
      pages: ['5', '10', '20'],

      currentPage: 1,
      searchTerm: '',

      detailProductLink: {},

      errorAddProductLink: '',
    }
  },

  computed: {
    ...mapGetters([
      'userDetail',
      'userAdsAccounts',
      'status',
      'loading',
      'message',
    ]),

    createdDate() {
      return value => (value ? new Date(value).toLocaleString('en-GB') : '')
    },

    goToUrl() {
      return tag => {
        if (tag.includes('http://') || tag.includes('https://')) {
          return window.open(tag)
        }
        return window.open(`https://${tag}`)
      }
    },

    totalProductLinks() {
      return this.listProductLink.length
    },

    availableOptions() {
      return this.listTargetMarket?.filter(
        opt => this.productsData?.targetMarkets?.indexOf(opt) === -1,
      )
    },

    statusVariantRejected() {
      const statusColor = {
        [PROFILE_STATUS.APPROVED]: 'approved',
        [PROFILE_STATUS.REJECTED]: 'rejected',
        [PROFILE_STATUS.REVIEWING]: 'reviewing',
        [PROFILE_STATUS.NOT_FOUND]: 'not-found',
      }

      return status => statusColor[status]
    },
    iconStatusProduct() {
      const iconStatus = {
        [PROFILE_STATUS.APPROVED]: icApproved,
        [PROFILE_STATUS.REJECTED]: icRejected,
        [PROFILE_STATUS.REVIEWING]: icReviewing,
        [PROFILE_STATUS.NOT_FOUND]: icNotFound,
      }

      return status => iconStatus[status]
    },

    contentStatusProduct() {
      const contentStatus = {
        [PROFILE_STATUS.APPROVED]: 'This product have been verify.',
        [PROFILE_STATUS.REJECTED]: 'This product was rejected.',
        [PROFILE_STATUS.REVIEWING]: 'The user need review the product link.',
        [PROFILE_STATUS.NOT_FOUND]: 'This product not found.',
      }

      return status => contentStatus[status]
    },

    listProductLink() {
      const listProductUrl = this.userDetail?.products?.productURLs || {}

      const arrUrlConverted = Object.keys(listProductUrl)
        .map(value => ({
          productUrl: value,
          ...listProductUrl[value],
        }))

      return arrUrlConverted.reverse()
    },
  },

  methods: {
    ...mapActions([
      'updateStatusProductLinks',
      'createProductLinks',
      'updateUser',
      'profileStatusUpdate',
      'getUser',
    ]),

    resetForm() {
      this.productsData.productURL = ''
      this.$refs.formValidation.reset()
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchAdsAccounts()
    },

    openRejectedTask(val) {
      this.detailProductLink = val
      this.isTaskReasonRejectActive = true
    },

    onReject() {
      this.isReject = true
    },

    onApprove() {
      this.isApprove = true
    },

    // async updateStatusProductLinks(status) {
    //   const infoUser = {
    //     // eslint-disable-next-line no-underscore-dangle
    //     idUser: this.userDetail._id,
    //     detail: {
    //       status: status.status,
    //       productURLs: this.productsData.productURLs,
    //       targetMarkets: this.productsData.targetMarkets,
    //       content: this.description,
    //     },
    //     // products: this.productsData,
    //   }
    //   await this.profileStatusUpdate(infoUser)
    //   if (this.status) {
    //     this.toastSuccess(
    //       status.status === 'approved'
    //         ? 'Approved profile user success'
    //         : 'Rejected profile user success',
    //     )
    //     const { params } = this.$route
    //     this.getUser(params)
    //     this.description = ''
    //   } else {
    //     this.toastFailure(this.message)
    //   }
    // },

    // async handleProductLinks() {
    //   const detailProductLinks = {
    //     // eslint-disable-next-line no-underscore-dangle
    //     id: this.userDetail.localUserId,
    //     products: this.productsData,
    //     content: this.description,
    //   }
    //   await this.updateUser(detailProductLinks)
    //   if (this.status) {
    //     this.toastSuccess('Update product links success')
    //     const { params } = this.$route
    //     this.getUser(params)
    //     this.description = ''
    //   } else {
    //     this.toastFailure(this.message)
    //   }
    // },

    async createProductLinkUser() {
      const success = await this.$refs.formValidation.validate()
      if (success) {
        this.errorAddProductLink = ''
        const productsLinks = {
          id: this.userDetail.localUserId,
          detail: this.productsData,
        }
        await this.createProductLinks(productsLinks)
        if (this.status) {
          this.toastSuccess('Created product links success')
          const { params } = this.$route
          this.getUser(params)
          this.resetForm()
        } else {
          this.errorAddProductLink = this.message
        }
      }
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchAdsAccounts()
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      // await this.fetchAdsAccounts()
    },

    async approveAccountTicket(val) {
      const { params } = this.$route

      const paramsApprove = {
        id: params.id,
        action: 'approve',
        productURL: val.productUrl,
      }
      await this.updateStatusProductLinks(paramsApprove)
      if (this.status) {
        this.toastSuccess('Approved product links success')
        this.getUser(params)
      } else {
        this.toastFailure(this.message)
      }
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/quill.scss";

.content-reason {
  p {
    margin: 0;
  }
}

.ql-editor {
  min-height: 200px;
}

.add-page {
  .markdown {
    border: 1px solid #ccc;
    border-radius: 0.357rem;
  }
}

.form-product-links {
  .b-form-tags {
    border: none;
    padding: 0;
  }

  .vs__selected {
    background: rgba(38, 103, 255, 0.1);
    color: #2667ff;
  }
}

.country-tag {
  /* .b-form-tags {
    padding: 0;
    border: none;
  } */

  .vs__selected {
    background: rgba(38, 103, 255, 0.1);
    color: rgb(38, 103, 255);
  }

  .vs__dropdown-option--highlight {
    background: rgba(38, 103, 255, 0.1);
    color: rgb(38, 103, 255) !important;
  }
}

.reason-content {
  p {
    margin: 0;
  }
}

.input-height {
  .vs__dropdown-toggle {
    padding: 5.09px 0 6.5px 0;
  }
}

.table-product-links {
  .vgt-table {
    border-radius: 12px;

    .th-table {
      padding: 9px 16px;
    }

    .td-table {
      padding: 21px 16px;
    }
  }

  .td-product-link {
    max-width: 300px;
  }
  .td-rejected-reason{
    max-width: 400px;
  }
}
</style>
<style lang="scss" scoped>
.input-form {
  margin-bottom: 32px;
}

.input-height {
  height: 48px;
}

.content-url {
  overflow: hidden;
  text-overflow: ellipsis;

  .detail {
    color: #2667ff;
    text-decoration: underline;
    cursor: pointer;
    word-break: break-all;
  }
}

.list-target {
  list-style-type: none;

  .item-target {
    padding: 6px 8px;
    background-color: #16213e0d;
    border-radius: 4px;
    margin: 0.5rem;
  }
}

.tag-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.container-product-link {
  padding: 32px;

  .title-tab {
    font-size: 20px;
    margin-bottom: 32px;
  }

  .status-product-links {
    background-color: rgba(251, 176, 59, 0.1);
    color: rgba(251, 176, 59, 1);
    padding: 6px 14px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;

    .icon-method {
      margin-right: 12px;
    }
  }

  .rejected {
    color: rgba(240, 37, 72, 1);
    background-color: rgba(240, 37, 72, 0.1);
  }

  .approved {
    color: rgba(122, 179, 76, 1);
    background-color: rgba(122, 179, 76, 0.1);
  }

  .not-found {
    color: rgba(61, 64, 91, 1);
    background-color: rgba(61, 64, 91, 0.1);
  }

  .reviewing {
    color: rgba(255, 159, 67, 1);
    background-color: rgba(255, 159, 67, 0.1);
  }

  .target-market {
    margin: 24px 0;
  }

  .btn-control {
    display: flex;
    justify-content: end;
    align-items: center;
    margin: 32px 0;

    .btn-continue {
      width: max-content;
      font-size: 14px;
    }

    .btn-decline {
      background: rgba(225, 28, 78, 0.1) !important;
      color: #e11c4e !important;
      border: none !important;
    }

    .btn-save-change {
      width: max-content;
      background: rgba(250, 179, 69, 0.1) !important;
      color: rgba(250, 179, 69, 1) !important;
      border: none !important;
    }
  }
}

.text-error {
  margin-top: 1rem;
  color: #f02649;
  text-align: center;
}

.error-message-stripe {
  margin: 24px 0 0;
  background: rgba(225, 28, 78, .1);
  border-radius: 20px;
  padding: 1rem 2rem;
  color: #000;

  .content-error {
    margin-left: 6px;
    margin-bottom: 0;
    max-width: 88%;
    word-break: break-word;
  }
}
</style>
