<template>
  <div class="tickets-container">
    <div>
      <!-- filter -->
      <b-card-code data-testid="filter-card">
        <!-- advance search input -->
        <b-row class="justify-content-between">
          <b-col :md="hasAM ? 4 : 8">
            <b-form-group>
              <label data-testid="search-user-label">Search user by field</label>
              <b-input-group
                class="input-filter"
                data-testid="search-input-group"
              >
                <b-input-group-prepend>
                  <b-form-select
                    v-model="searchSelected"
                    class="select-search-custom"
                    :options="searchOptions"
                    style="border-top-right-radius: 0;
                            border-bottom-right-radius: 0"
                    :data-testid="`search-select-${searchSelected}`"
                    @input="handleChangeSearchType"
                  />
                </b-input-group-prepend>
                <b-form-input
                  v-model.trim="searchTerm"
                  class="d-inline-block"
                  :placeholder="
                    searchSelected === 'email'
                      ? 'Search by email'
                      : 'Search by ID'
                  "
                  :type="searchSelected === 'localUserId' ? 'number' : 'text'"
                  :data-testid="`search-input-${searchSelected}`"
                  @wheel="handleMouseWheelInput"
                  @input="handleSearch"
                  @keyup.enter="handleSubmitSearch"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col
            v-if="hasAM"
            md="4"
          >
            <b-form-group>
              <label data-testid="am-filter-label"> Filter by AM </label>
              <v-select
                v-model="amSelected"
                class="input-height filter-partner-id"
                placeholder="Select AM"
                :options="listAMOptions"
                :filter-by="handleSearchAm"
                data-testid="am-select"
                @input="handleAmChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="4"
            class="align-self-end"
          >
            <btn-loading
              class="btn-export"
              type="submit"
              block
              variant="outline-primary"
              :disabled="loading"
              :loading="loadingDown"
              data-testid="export-button"
              @click="submitExportData"
            >
              Export data
              <feather-icon
                icon="UploadIcon"
                size="16"
              />
            </btn-loading>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <b-form-group class="group-input">
              <label> Verify At </label>
              <calendar
                v-model="rangeDate"
                data-testid="verify-at-calendar"
                class="input-height"
                @input="handleDateChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="3"
            class="
              country-tag
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <b-form-group class="w-100">
              <label> Status </label>
              <v-select
                v-model="statusSelected"
                class="input-height"
                placeholder="Select status"
                :options="statusOptions"
                data-testid="status-select"
                @input="handleStatusChange"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="hasPartnership"
            md="5"
          >
            <b-form-group>
              <label> Filter by partner id </label>
              <v-select
                v-model="partnerSelected"
                name="email partner"
                class="input-height filter-partner-id"
                placeholder="Search by email or Id partner"
                :loading="loadingGiftCode"
                :disabled="loadingGiftCode"
                :options="partnerOptions"
                :filter-by="handleSearchPartner"
                data-testid="partner-select"
                @input="handlePartnerChange"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-code>

      <!-- table -->
      <vue-good-table
        id="table-verify-info"
        mode="remote"
        class="table-verify-info position-relative"
        :columns="columns"
        :rows="listItemUserInfo"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }"
        :pagination-options="{
          enabled: totalUserInfo > 0
        }"
        :sort-options="{
          enabled: totalUserInfo > 0
        }"
        :is-loading="loading || loadingGiftCode"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'selection-wrapper',
          selectionText: 'rows selected',
          clearSelectionText: 'Clear',
          selectAllByGroup: true
        }"
        data-testid="verify-info-table"
        @on-sort-change="onSortChange"
        @on-selected-rows-change="selectionChanged"
      >
        <div slot="selected-row-actions">
          <b-button
            variant="outline"
            size="sm"
            v-bind="$attrs"
            data-testid="assign-am-button"
            v-on="$listeners"
            @click="openAssignListAMSideBar"
          >
            Assign to AM
          </b-button>
        </div>
        <template
          slot-scope="props"
          slot="table-row"
        >
          <!-- Column: User Info -->
          <a
            v-if="props.column.field === 'email'"
            class="d-flex align-items-center cursor-pointer"
            target="_blank"
            :href="`/user/${props.row.localUserId}`"
            data-testid="user-info-link"
          >
            <b-img
              :id="`avt-user-${props.row._id}`"
              rounded
              class="ads-avt-img mr-1"
              :src="getAvatar(props.row.registerFromService)"
              data-testid="user-avatar"
            />
            <b-tooltip
              v-if="isEcomdyPlatform && props.row.registerFromService"
              placement="top"
              triggers="hover"
              :target="`avt-user-${props.row._id}`"
              data-testid="user-platform-tooltip"
            >
              {{ getUserPlatform(props.row.registerFromService) }}
            </b-tooltip>
            <div>
              <p
                class="mb-0 text-secondary font-weight-bold mb-0 underline"
                data-testid="user-full-name"
              >
                {{ props.row.fullName || '' }}
                <span v-if="props.row.whiteList">
                  <b-img
                    rounded
                    class="ads-avt-img mr-1"
                    :src="
                      require('@/assets/images/pages/payment/icon-white-list.svg')
                    "
                    data-testid="white-list-icon"
                  />
                </span>
              </p>
              <span
                class="text-placeholder"
                data-testid="user-email"
              >{{ props.row.email || '' }}</span>
            </div>
          </a>

          <!-- country -->
          <div
            v-else-if="props.column.field === 'country'"
            data-testid="user-country"
          >
            <span
              v-if="props.row.country"
              data-testid="country-value"
            >
              {{ props.row.country }}
            </span>
            <span
              v-else
              data-testid="country-na"
            > N/A </span>
          </div>

          <div
            v-else-if="props.column.field === 'contactInfo.phone'"
            data-testid="user-phone"
          >
            <span
              v-if="phone(props.row)"
              data-testid="phone-number"
            >
              {{ showPhoneNumber(phone(props.row)) }}
            </span>
            <span
              v-else
              data-testid="phone-na"
            > N/A </span>
          </div>

          <div
            v-else-if="props.column.field === 'contactInfo.zalo'"
            data-testid="user-zalo"
          >
            <span
              v-if="zaloNumber(props.row)"
              data-testid="zalo-number"
            >
              {{ (zaloNumber(props.row)) }}
            </span>
            <span
              v-else
              data-testid="zalo-na"
            > N/A </span>
          </div>

          <!-- contactInfo.facebook -->
          <div
            v-else-if="props.column.field === 'contactInfo.facebook'"
            class="content-product-link"
            data-testid="user-facebook"
          >
            <a
              v-if="facebookContact(props.row)"
              :href="facebookContact(props.row)"
              target="_blank"
              rel="noopener noreferrer"
              class="text-blue"
              data-testid="facebook-link"
            >
              {{ facebookContact(props.row) }}
            </a>
            <span
              v-else
              data-testid="facebook-na"
            > N/A </span>
          </div>

          <!-- contactInfo.whatsApp -->
          <div
            v-else-if="props.column.field === 'contactInfo.whatsApp'"
            data-testid="user-whatsapp"
          >
            <span data-testid="whatsapp-contact">
              {{ whatsAppContact(props.row) }}
            </span>
          </div>

          <!-- contactInfo.telegram -->
          <div
            v-else-if="props.column.field === 'contactInfo.telegram'"
            data-testid="user-telegram"
          >
            <span data-testid="telegram-contact">
              {{ telegramContact(props.row) }}
            </span>
          </div>

          <!-- industry -->
          <div
            v-else-if="props.column.field === 'industrySMB'"
            data-testid="user-industry"
          >
            <span
              v-if="props.row.industry"
              data-testid="industry-value"
            >
              {{ props.row.industry }}
            </span>
            <span
              v-else
              data-testid="industry-na"
            >
              N/A
            </span>
          </div>

          <!-- TikTok Shop code -->
          <div
            v-else-if="props.column.field === 'shopCode'"
            data-testid="user-shop-code"
          >
            <span
              v-if="props.row.shopCode"
              data-testid="shop-code-value"
            >
              {{ props.row.shopCode }}
            </span>
            <span
              v-else
              data-testid="shop-code-na"
            >
              N/A
            </span>
          </div>

          <!-- products.productURLs -->
          <div
            v-else-if="props.column.field === 'products.productURLs'"
            data-testid="user-product-urls"
          >
            <div
              v-if="productLinkList(props.row).length > 0"
              data-testid="product-link-list"
            >
              <ul
                v-for="(item, index) in productLinkList(props.row)"
                :key="index"
                class="pl-1"
                data-testid="product-link-item"
              >
                <li>
                  <p
                    v-b-tooltip="item.productUrl"
                    class="
                      text-blue
                      underline
                      cursor-pointer
                      tag-content
                      content-product-link
                      m-0
                    "
                    data-testid="product-url"
                    @click="goToUrl(item.productUrl)"
                  >
                    {{ item.productUrl }}
                  </p>
                  <span
                    v-if="!isVNWhiteLabel"
                    data-testid="product-details"
                  >
                    {{ targetMarketsToString(item) }} - {{ item.status }}
                  </span>
                </li>
              </ul>
            </div>
            <span
              v-else
              data-testid="no-product-urls"
            > N/A </span>
          </div>

          <!-- EXCLUSIVE_PARTNER -->
          <div
            v-else-if="props.column.field === 'platform'"
            data-testid="user-platform"
          >
            <span data-testid="platform-exclusive-partner">
              {{ platformExclusivePartner(props.row) }}
            </span>
          </div>

          <div
            v-else-if="props.column.field === 'industry'"
            :class="industryExclusivePartner(props.row) !== 'N/A' ? 'column-industry': null"
            data-testid="user-industry-exclusive"
          >
            <span data-testid="industry-exclusive-partner">
              {{ industryExclusivePartner(props.row) }}
            </span>
          </div>
          <!-- END EXCLUSIVE_PARTNER -->

          <!-- screenshots -->
          <div
            v-else-if="props.column.field === 'screenshots'"
            data-testid="user-screenshots"
          >
            <div
              v-if="hasScreenshot(props.row) || dashboardScreenshot(props.row)"
              data-testid="screenshot-container"
            >
              <div
                class="d-flex"
                data-testid="screenshot-list"
              >
                <div
                  v-for="(img, index) in listScreenshot(props.row)"
                  :key="index"
                  class="img-store-screenshot"
                  data-testid="screenshot-preview"
                  @click="previewImgStoreScreenshot(img)"
                >
                  <b-img
                    :src="img"
                    alt="img uploaded"
                    data-testid="screenshot-image"
                  />
                  <div
                    class="gallery-item-info"
                    data-testid="gallery-item-info"
                  >
                    <feather-icon
                      size="21"
                      icon="EyeIcon"
                      data-testid="preview-icon"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span
              v-else
              data-testid="no-screenshots"
            > N/A </span>
          </div>

          <!-- plans.type -->
          <div
            v-else-if="props.column.field === 'plans.type'"
            data-testid="user-plan"
          >
            <span data-testid="user-plan-type">
              {{ currentPlan(props.row) }}
            </span>
          </div>

          <!-- createdAt -->
          <div
            v-else-if="props.column.field === 'createdAt'"
            data-testid="user-created-at"
          >
            <span
              v-if="createdDate(props.row)"
              data-testid="created-date"
            >
              {{ createdDate(props.row) }}
            </span>
            <span
              v-else
              data-testid="no-created-date"
            >N/A</span>
          </div>

          <!-- contactInfo.verification.status -->
          <div
            v-else-if="props.column.field === 'contactInfo.verification.status'"
            data-testid="user-verification-status"
          >
            <span
              v-if="statusUserInfo(props.row)"
              class="text-status"
              :class="statusVariant(statusUserInfo(props.row))"
              data-testid="user-verification-status-info"
            >
              {{ statusUserInfo(props.row) }}
            </span>
            <span
              v-else
              data-testid="user-verification-status-na"
            > N/A </span>
          </div>

          <!-- contactInfo.verification.rejectedReason -->
          <div
            v-else-if="
              props.column.field === 'contactInfo.verification.rejectedReason'
            "
            data-testid="user-rejected-reason"
          >
            <div
              v-if="rejectReason(props.row)"
              class="reason-reject-content"
              data-testid="user-rejected-reason-content"
              v-html="rejectReason(props.row)"
            />
            <span
              v-else
              data-testid="user-rejected-reason-na"
            > N/A </span>
          </div>

          <!-- reviewedBy -->
          <div
            v-else-if="
              props.column.field === 'contactInfo.verification.verifiedBy'
            "
            data-testid="user-verified-by"
          >
            <span
              v-if="idAdmin(props.row)"
              data-testid="user-verified-by-admin"
            >{{ idAdmin(props.row) }}</span>
            <span
              v-else
              data-testid="user-verified-by-na"
            >N/A</span>
          </div>

          <!-- contactInfo.verification.at -->
          <div
            v-else-if="props.column.field === 'contactInfo.verification.at'"
            data-testid="user-verified-at"
          >
            <span
              v-if="approvedDate(props.row)"
              data-testid="user-verified-at-date"
            >{{ approvedDate(props.row) }}
            </span>
            <span
              v-else
              data-testid="user-verified-at-na"
            > N/A </span>
          </div>

          <!-- Column: amId -->
          <div
            v-else-if="props.column.field === 'amId'"
            data-testid="user-am-id"
          >
            <span
              v-if="props.row.amId"
              class="content-status"
              data-testid="user-am-id-value"
            >
              {{ props.row.amId }}
            </span>
            <span
              v-else
              data-testid="user-am-id-na"
            > N/A </span>
          </div>

          <!-- Column: amId -->
          <div
            v-else-if="props.column.field === 'belongToPartner.partnerLocalId'"
            data-testid="user-partner-id"
          >
            <span
              v-if="props.row.belongToPartner && props.row.belongToPartner.partnerLocalId"
              class="content-status"
              data-testid="user-partner-id-value"
            >
              {{ props.row.belongToPartner.partnerLocalId }}
            </span>
            <span
              v-else
              data-testid="user-partner-id-na"
            > N/A </span>
          </div>

          <!-- actions -->
          <div
            v-else-if="props.column.field === 'actions'"
            data-testid="user-actions"
          >
            <b-dropdown
              id="dropdown-actions"
              data-testid="dropdown-actions"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                  data-testid="more-actions-icon"
                />
              </template>
              <b-dropdown-item
                id="btn-action-approve"
                data-testid="btn-action-approve"
                @click="approveUserInfo(props.row, productLinkList(props.row))"
              >
                <span
                  id="txt-approve"
                  data-testid="txt-approve"
                >Approve</span>
              </b-dropdown-item>
              <b-dropdown-item
                id="btn-action-reject"
                data-testid="btn-action-reject"
                @click="openPopupReject(props.row, productLinkList(props.row))"
              >
                <span
                  id="txt-reject"
                  data-testid="txt-reject"
                >Reject</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="hasAM"
                id="btn-action-assign-to-am"
                data-testid="btn-action-assign-to-am"
                @click="openAssignToAMSideBar(props.row)"
              >
                <span
                  id="txt-assign-to-am"
                  data-testid="txt-assign-to-am"
                >Assign to AM</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <!-- Column: common -->
          <span
            v-else
            data-testid="common-field"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          v-if="totalUserInfo > 0"
          slot="pagination-bottom"
        >
          <div
            class="d-flex flex-wrap mt-1 justify-content-between"
            data-testid="pagination-container"
          >
            <!-- page length -->
            <div
              class="d-flex align-items-center mb-0"
              data-testid="page-length-container"
            >
              <span
                class="text-nowrap"
                data-testid="showing-text"
              > Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="pages"
                class="mx-1"
                data-testid="page-length-select"
                @input="handlePageChange"
              />
              <span
                class="text-nowrap"
                data-testid="total-users-text"
              >
                of {{ totalUserInfo }} user(s) info
              </span>
            </div>
            <div data-testid="pagination-wrapper">
              <b-pagination
                :total-rows="totalUserInfo"
                :value="currentPage"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mb-0"
                data-testid="pagination"
                @change="handleChangePage"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    data-testid="prev-page-icon"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    data-testid="next-page-icon"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>

        <!-- custom loading  -->
        <template slot="loadingContent">
          <b-spinner
            variant="primary"
            type="grow"
            small
            data-testid="loading-spinner"
          />
        </template>

        <div
          slot="emptystate"
          class="text-center"
          data-testid="empty-state"
        >
          <no-data-table-image
            class="my-5"
            data-testid="no-data-image"
          />
        </div>
      </vue-good-table>
    </div>

    <reject-info-modal
      :info-user-detail="infoUserDetail"
      :show-side-bar.sync="isShowRejectInfoSideBar"
      :product-link-reject="productLinkReject"
      @fetch-list-user-info="fetchListUserInfo"
    />

    <assign-to-AM-side-bar
      :list-user-info-selected="
        listUserInfoSelected === null ? [] : listUserInfoSelected
      "
      :show-side-bar.sync="showAssignAMSideBar"
      @fetch-user="fetchListUserInfo"
    />

    <show-screenshot-modal
      ref="open-screenshot-modal"
      :img="imgPreviewStoreScreenshot"
    />
  </div>
</template>

<script>
/* eslint-disable global-require,no-plusplus,no-param-reassign */
/* eslint-disable no-underscore-dangle */
import {
  BPagination,
  BSpinner,
  BImg,
  BDropdown,
  BDropdownItem,
  // BBadge,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupPrepend,
  BRow,
  BCol,
  BInputGroup,
  BButton,
  VBTooltip,
  VBModal, BTooltip,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import NoDataTableImage from '@/components/NoDataTableImage.vue'
import _get from 'lodash/get'
import { VueGoodTable } from 'vue-good-table'
import { toastification } from '@core/mixins/toast'
import { REGISTER_FROM_SERVICE, STATUS_USER_INFO } from '@/constants'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import AssignToAMSideBar from '@/views/user/components/AssignToAMSideBar.vue'
import Calendar from '@/views/home/components/Calendar.vue'
import axios from '@axios'
import axiosLib from 'axios'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import Ripple from 'vue-ripple-directive'
import numberFormatMixin from '@/mixins/numberFormatMixin'
import envMixin from '@/mixins/envMixin'
import gaTrackingMixin from '@/mixins/gaTrackingMixin'
import ShowScreenshotModal from '@/components/ShowScreenshotModal.vue'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import usersMixin from '@/mixins/usersMixin'
import RejectInfoModal from './RejectInfoModal.vue'

// const { mapActions } = createNamespacedHelpers('verifyInfo')
// const { mapActions: mapActionsUser } = createNamespacedHelpers('user')
// const { mapActions: mapActionsProductLinks } = createNamespacedHelpers('productLinks')

const cancelTokenSource = axiosLib.CancelToken.source
// eslint-disable-next-line import/no-mutable-exports
export let cancelToken = cancelTokenSource()

export const abort = () => {
  cancelToken.cancel()
  cancelToken = cancelTokenSource()
}

export default {
  components: {
    BTooltip,
    ShowScreenshotModal,
    BPagination,
    BSpinner,
    BImg,
    BDropdown,
    BDropdownItem,
    BCardCode,
    // BBadge,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BRow,
    BCol,
    BInputGroup,
    BButton,
    // components
    NoDataTableImage,
    VueGoodTable,
    vSelect,

    RejectInfoModal,
    AssignToAMSideBar,
    Calendar,
    BtnLoading,
  },

  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    Ripple,
  },

  mixins: [toastification, numberFormatMixin, envMixin, generalConfigsMixin, gaTrackingMixin, usersMixin],

  data() {
    return {
      loadingDown: false,

      currentPage: 1,
      searchTerm: '',

      // sort
      sortTerm: {
        field: '',
        type: '',
      },

      // search Dropdown
      searchSelected: 'localUserId',
      searchOptions: [
        {
          text: 'User ID',
          value: 'localUserId',
        },
        {
          text: 'User email',
          value: 'email',
        },
      ],

      rangeDate: {
        startDate: null,
        endDate: null,
      },

      // per page
      pageLength: 10,
      pages: ['10', '20', '50', '100'],

      infoUserDetail: {},
      isShowRejectInfoSideBar: false,

      statusSelected: null,
      statusOptions: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Rejected',
          value: STATUS_USER_INFO.REJECTED,
        },
        {
          label: 'Reviewing',
          value: STATUS_USER_INFO.REVIEWING,
        },
        {
          label: 'Approved',
          value: STATUS_USER_INFO.APPROVED,
        },
      ],

      amSelected: null,
      partnerSelected: null,

      showAssignAMSideBar: false,

      productLinkReject: [],

      listUserInfoSelected: null,

      listQuery: [],

      amEmail: null,

      imgPreviewStoreScreenshot: '',

      isScrollTable: false,
    }
  },

  computed: {
    REGISTER_FROM_SERVICE() {
      return REGISTER_FROM_SERVICE
    },
    ...mapGetters({
      listUserInfo: 'verifyInfo/listUserInfo',
      loading: 'verifyInfo/loading',
      statusVerifyInfo: 'verifyInfo/status',
      listAM: 'user/listAM',
      statusProductUser: 'user/status',
      messageErrorUser: 'user/message',
      messageErrorVerifyInfo: 'verifyInfo/message',
      listPartnerNoPaging: 'giftCode/listPartnerNoPaging',
      loadingGiftCode: 'giftCode/loading',
    }),

    columns() {
      return [
        {
          label: 'User ID',
          field: 'localUserId',
          thClass: `p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf column-sticky ${this.isScrollTable ? 'scroll-user-info-column' : ''}`,
          tdClass: `p-1 border-left-0 border-right-0 border-bottom-0 align-middle column-sticky bg-white ${this.isScrollTable ? 'scroll-user-info-column' : ''}`,
          sortable: false,
        },
        {
          label: 'User info',
          field: 'email',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
        },
        {
          label: 'Country',
          field: 'country',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
        },
        {
          label: 'Phone number',
          field: 'contactInfo.phone',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
          hidden: this.isEmediaPlatform,
        },
        {
          label: 'Zalo number',
          field: 'contactInfo.zalo',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
          hidden: !this.isVNWhiteLabel,
        },
        {
          label: 'Industry',
          field: 'industrySMB',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
          hidden: !this.isVNWhiteLabel,
        },
        {
          label: 'TikTok Shop Code',
          field: 'shopCode',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle width-column',
          hidden: !this.isVNWhiteLabel,
        },
        {
          label: 'Facebook',
          field: 'contactInfo.facebook',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          hidden: this.isVNWhiteLabel || this.isEmediaPlatform,
        },
        {
          label: 'WhatsApp',
          field: 'contactInfo.whatsApp',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          hidden: this.isVNWhiteLabel,
        },
        {
          label: 'Telegram',
          field: 'contactInfo.telegram',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          hidden: this.isVNWhiteLabel || this.isEmediaPlatform,
        },
        {
          label: 'Product Url',
          field: 'products.productURLs',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle column-product-link',
        },
        {
          label: 'Platform',
          field: 'platform',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          hidden: !this.isExclusivePartner,
        },
        {
          label: 'Industry',
          field: 'industry',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          hidden: !this.isExclusivePartner,
        },
        {
          label: 'Screenshots',
          field: 'screenshots',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle column-product-link',
          hidden: this.isSMBAgencyPlatform,
        },
        {
          label: 'Current plan',
          field: 'plans.type',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          hidden: !this.hasSubscription,
        },
        {
          label: 'Belong to partner',
          field: 'belongToPartner.partnerLocalId',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          hidden: !this.hasPartnership,
        },
        {
          label: 'Create At',
          field: 'createdAt',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
        },
        {
          label: 'Status',
          field: 'contactInfo.verification.status',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
        },
        {
          label: 'Reason reject',
          field: 'contactInfo.verification.rejectedReason',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
        },
        {
          label: 'Review by',
          field: 'contactInfo.verification.verifiedBy',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
        },
        {
          label: 'Verify At',
          field: 'contactInfo.verification.at',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
        },
        {
          label: 'Belong to AM',
          field: 'amId',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
          hidden: !this.hasAM,
        },
        {
          label: 'Actions',
          field: 'actions',
          thClass: 'p-1 border-left-0 border-right-0 border-bottom-0 header-table-mf',
          tdClass: 'p-1 border-left-0 border-right-0 border-bottom-0 align-middle',
        },
      ]
    },

    idAdmin() {
      return info => info?.contactInfo?.verification?.verifiedBy || 'N/A'
    },

    rejectReason() {
      return info => info?.contactInfo?.verification?.rejectedReason
    },

    phone() {
      return data => data?.contactInfo?.phone
    },

    zaloNumber() {
      return data => data?.contactInfo?.zalo
    },

    facebookContact() {
      return data => data?.contactInfo?.facebook
    },

    whatsAppContact() {
      return data => data?.contactInfo?.whatsApp || 'N/A'
    },

    telegramContact() {
      return data => data?.contactInfo?.telegram || 'N/A'
    },

    currentPlan() {
      return data => data?.plans?.type || 'N/A'
    },

    createdDate() {
      return value => (value.contactInfo?.createdAt
        ? new Date(value.contactInfo?.createdAt).toLocaleString('en-GB')
        : 'N/A')
    },

    platformExclusivePartner() {
      return value => value?.exclusiveVerifyInfo?.platform || 'N/A'
    },

    industryExclusivePartner() {
      return value => value?.exclusiveVerifyInfo?.industry || 'N/A'
    },

    approvedDate() {
      return value => (value.contactInfo?.verification?.at ? new Date(value.contactInfo?.verification?.at).toLocaleString('en-GB') : 'N/A')
    },

    listItemUserInfo() {
      return _get(this.listUserInfo, ['content'])
    },

    totalUserInfo() {
      return _get(this.listUserInfo, ['paging', 'total'])
    },

    isActiveBtn() {
      return this.searchTerm.length > 3
    },

    statusVariant() {
      const statusColor = {
        [STATUS_USER_INFO.NOT_FOUND]: 'not-found',
        [STATUS_USER_INFO.REJECTED]: 'rejected',
        [STATUS_USER_INFO.REVIEWING]: 'reviewing',
        [STATUS_USER_INFO.APPROVED]: 'approved',
      }

      return status => statusColor[status]
    },

    statusUserInfo() {
      const statusInfo = {
        [STATUS_USER_INFO.NOT_FOUND]: 'not-found',
        [STATUS_USER_INFO.REVIEWING]: 'reviewing',
        [STATUS_USER_INFO.REJECTED]: 'rejected',
        [STATUS_USER_INFO.APPROVED]: 'approved',
      }

      return info => statusInfo[info?.contactInfo?.verification?.status]
    },

    listAMOptions() {
      const listAMMap = this.listAM.map(item => {
        const options = {
          label: item.email,
          value: item.localUserId,
        }
        return options
      })
      const optionNone = {
        label: 'N/A',
        value: 'N/A',
      }
      return [optionNone, ...listAMMap]
    },

    partnerOptions() {
      const listPartnerMap = this.listPartnerNoPaging.content.map(item => ({
        value: item.localUserId,
        label: item.email,
        localId: item.localUserId,
      }))
      const optionNone = {
        label: 'N/A',
        value: 0,
      }

      return [optionNone, ...listPartnerMap]
    },

    productLinkList() {
      return val => {
        if (!val?.products?.productURLs) return []

        const productLinks = val?.products?.productURLs
        const convertedUrls = Object.keys(productLinks)
          .map(value => ({
            productUrl: value,
            ...productLinks[value],
          }))

        return convertedUrls.reverse()
      }
    },

    targetMarketsToString() {
      return item => item?.targetMarkets
        && Array.isArray(item?.targetMarkets)
        && item?.targetMarkets.length > 0
        && item?.targetMarkets.join(', ')
    },

    rangeDates() {
      return {
        verifiedFrom: this.rangeDate.startDate,
        verifiedTo: this.rangeDate.endDate
          ? this.rangeDate.endDate
          : this.rangeDate.startDate,
      }
    },

    noFilter() {
      return (
        this.searchTerm === ''
        && (this.statusSelected === null || this.statusSelected?.value === '')
        && this.amSelected === null
        && (this.rangeDate?.startDate === null || this.rangeDate?.endDate === null)
      )
    },

    hasScreenshot() {
      return data => data?.contactInfo?.storeScreenshots?.length > 0
    },

    dashboardScreenshot() {
      return value => value?.exclusiveVerifyInfo?.dashboardScreenshots?.length > 0
    },

    listScreenshot() {
      if (this.isExclusivePartner) {
        return data => data?.exclusiveVerifyInfo?.dashboardScreenshots
      }
      return data => data?.contactInfo?.storeScreenshots
    },
  },

  watch: {
    $route: {
      handler(route) {
        const {
          status,
          verifyAt,
          userId,
          email,
          am,
        } = route.query
        if (status) {
          switch (status) {
            case 'Rejected':
              this.statusSelected = {
                label: 'Rejected',
                value: STATUS_USER_INFO.REJECTED,
              }
              break
            case 'Reviewing':
              this.statusSelected = {
                label: 'Reviewing',
                value: STATUS_USER_INFO.REVIEWING,
              }
              break
            case 'Approved':
              this.statusSelected = {
                label: 'Approved',
                value: STATUS_USER_INFO.APPROVED,
              }
              break
            case 'All-status':
              this.statusSelected = {
                label: 'All Status',
                value: null,
              }
              break
            default:
              this.statusSelected = null
          }
        }

        if (verifyAt) {
          const isTextTo = verifyAt.includes('to')
          if (isTextTo) {
            const [startDate, endDate] = verifyAt.split('to')
            this.rangeDate = {
              startDate,
              endDate,
            }
          } else {
            this.rangeDate = {
              startDate: verifyAt,
              endDate: verifyAt,
            }
          }
        }

        if (userId) {
          this.searchSelected = 'localUserId'
          this.searchTerm = userId
        }

        if (email) {
          this.searchSelected = 'email'
          this.searchTerm = email
        }

        if (am) {
          if (am === 'N/A') {
            this.amSelected = {
              label: 'N/A',
              value: 'N/A',
            }
          } else {
            this.amEmail = am
          }
        }

        const { query } = this.$route
        if (this.listQuery.length === 0 && Object.entries(query).length > 0) {
          const objectKeys = Object.keys(query)

          const newQuery = objectKeys.map(value => ({
            keyQuery: value,
            valueQuery: `${query[value]}`,
          }))
          this.listQuery = newQuery
        }
      },
      deep: true,
      immediate: true,
    },

    listPartnerNoPaging: {
      handler(listPartner) {
        if (listPartner?.paging?.total > 0) {
          const { partnerId } = this.$route.query
          if (partnerId) {
            if (partnerId === 'N/A') {
              this.amSelected = {
                label: 'N/A',
                value: 0,
              }
              this.fetchListUserInfo()
            } else {
              this.partnerSelected = this.partnerOptions?.find(item => item?.value === Number(partnerId))
              this.fetchListUserInfo()
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },

    listQuery: {
      handler(query) {
        if (query.length > 0) {
          const objUrl = query.reduce((result, item) => {
            const key = item.keyQuery
            const value = item.valueQuery
            result[key] = value
            return result
          }, {})
          this.$router.push({
            name: 'verify-information',
            query: objUrl,
          })
        } else {
          window.history.pushState({}, '', '/verify-information')
        }
      },
      deep: true,
      immediate: true,
    },

    listAM: {
      handler(value) {
        if (value.length > 0) {
          const { am } = this.$route.query
          if (am) {
            this.amSelected = this.listAMOptions.find(item => item.label === am)
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    const { partnerId } = this.$route.query
    if (!partnerId) {
      this.fetchListUserInfo()
    }
    this.getListAM()
    this.fetchAllPartner()
    this.initScrollVerifyInfoTable()
  },

  beforeDestroy() {
    abort()
  },

  methods: {
    ...mapActions({
      getListUserInfo: 'verifyInfo/getListUserInfo',
      verifyUserInfo: 'verifyInfo/verifyUserInfo',
      getListAM: 'user/getListAM',
      updateStatusProductLinks: 'user/updateStatusProductLinks',
      getListPartnerNoPaging: 'giftCode/getListPartnerNoPaging',
    }),
    // ...mapActions(['getListUserInfo', 'verifyUserInfo']),
    // ...mapActionsUser(['getListAM']),
    // ...mapActionsProductLinks(['approveTicket']),

    async initScrollVerifyInfoTable() {
      await document.querySelector('#table-verify-info')
      document.querySelector('#table-verify-info .vgt-responsive').addEventListener('scroll', event => this.scrollVerifyInfoTable(event))
    },

    scrollVerifyInfoTable(event) {
      const currentScroll = Math.floor(event.target.scrollLeft)
      if (currentScroll === 0) {
        this.isScrollTable = false
      } else {
        this.isScrollTable = true
      }
    },

    handleSearchPartner(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (label || '').toLocaleLowerCase()
        .indexOf(searchTxt) > -1 || option?.localId?.toString()
          .toLocaleLowerCase()
          .indexOf(searchTxt) > -1
    },

    selectionChanged({ selectedRows }) {
      this.listUserInfoSelected = selectedRows
    },

    openAssignListAMSideBar() {
      this.showAssignAMSideBar = true
    },

    goToUrl(tag) {
      if (tag.includes('http://') || tag.includes('https://')) {
        window.open(tag)
      } else {
        window.open(`https://${tag}`)
      }
    },

    showPhoneNumber(phoneNum) {
      let result = ''
      if (phoneNum && phoneNum.split('|')) {
        const phoneElm = phoneNum.split('|')
        if (phoneElm.length === 3) {
          const [countryAbbr, countryCode, phone] = phoneNum.split('|')
          result = `${countryAbbr.toUpperCase()} +${countryCode}${phone}`
        }
      } else {
        result = phoneNum
      }
      return result
    },

    async handlePageChange(active) {
      this.currentPage = 1
      this.pageLength = active
      await this.fetchListUserInfo()
    },

    async handleChangePage(page) {
      this.currentPage = page
      await this.fetchListUserInfo()
    },

    async handleStatusChange() {
      this.currentPage = 1

      // handle url status
      this.listQuery = this.listQuery.filter(item => item.keyQuery !== 'status')
      if (this.statusSelected) {
        this.listQuery.push({
          keyQuery: 'status',
          valueQuery: this.statusSelected.label === 'All Status' ? 'All-status' : this.statusSelected.label,
        })
      }
      // handle url status

      await this.fetchListUserInfo()
    },

    handleSearchAm(option, label, search) {
      const searchTxt = search.toLocaleLowerCase()
      return (
        (label || '').toLocaleLowerCase()
          .indexOf(searchTxt) > -1
        || option?.value
          ?.toString()
          .toLocaleLowerCase()
          .indexOf(searchTxt) > -1
      )
    },

    async handleAmChange() {
      this.currentPage = 1
      this.amEmail = null
      // handle select am
      this.listQuery = this.listQuery.filter(item => item.keyQuery !== 'am')
      if (this.amSelected) {
        this.listQuery.push({
          keyQuery: 'am',
          valueQuery: this.amSelected.label,
        })
      }
      // handle select am
      await this.fetchListUserInfo()
    },

    async handlePartnerChange() {
      this.currentPage = 1
      // handle select partner id
      this.listQuery = this.listQuery.filter(item => item.keyQuery !== 'partnerId')
      if (this.partnerSelected) {
        this.listQuery.push({
          keyQuery: 'partnerId',
          valueQuery: this.partnerSelected?.label === 'N/A' ? 'N/A' : this.partnerSelected.value,
        })
      }
      // handle select am
      await this.fetchListUserInfo()
    },

    openAssignToAMSideBar(data) {
      this.listUserInfoSelected = [data]
      this.showAssignAMSideBar = true
    },

    async approveUserInfo(info, productLink) {
      const productLinkReviewing = productLink.find(
        item => item.status === 'reviewing',
      )
      if (productLinkReviewing) {
        const paramsApprove = {
          id: info.localUserId,
          action: 'approve',
          productURL: productLinkReviewing?.productUrl,
        }

        await this.updateStatusProductLinks(paramsApprove)
        if (this.statusProductUser) {
          const params = {
            id: info._id,
            status: 'verify',
          }
          await this.verifyUserInfo(params)
          if (this.statusVerifyInfo) {
            this.toastSuccess('Approve user info success!')
            this.fetchListUserInfo()
            if (this.isOnlyEcomdyPlatform) {
              this.trackingAdminApproveInfo(info.email) // GA Tracking
            }
          } else {
            this.toastFailure(this.messageErrorVerifyInfo)
          }
        } else {
          this.toastFailure(this.messageErrorUser)
        }
      } else {
        const params = {
          id: info._id,
          status: 'verify',
        }
        await this.verifyUserInfo(params)
        if (this.statusVerifyInfo) {
          this.toastSuccess('Approve user info success!')
          this.fetchListUserInfo()
          if (this.isOnlyEcomdyPlatform) {
            this.trackingAdminApproveInfo(info.email) // GA Tracking
          }
        } else {
          this.toastFailure(this.messageErrorVerifyInfo)
        }
      }
    },

    openPopupReject(info, productLink) {
      this.productLinkReject = productLink
      this.infoUserDetail = info
      this.isShowRejectInfoSideBar = true
    },

    async onSortChange(params) {
      this.sortTerm.field = params[0].field
      this.sortTerm.type = params[0].type
      await this.fetchListUserInfo()
    },

    handleChangeSearchType() {
      const { query } = this.$route
      if (query.email || query.userId || query.partnerId) {
        delete query.email
        delete query.userId
        delete query.partnerId
        this.listQuery = this.listQuery.filter(
          item => item.keyQuery !== 'email'
            && item.keyQuery !== 'userId'
            && item.keyQuery !== 'partnerId',
        )
      }
      this.searchTerm = ''
      this.fetchListUserInfo()
    },

    async handleSearch(searching) {
      if (this.debounce !== null) {
        await clearTimeout(this.debounce)
      }

      if (searching === '') {
        abort()
        this.fetchListUserInfo()
      } else if (
        searching.toString().length > 3
        && this.searchSelected !== 'email'
      ) {
        this.debounce = setTimeout(() => {
          // handle url user id
          this.listQuery = this.listQuery.filter(item => item.keyQuery !== 'userId' && item.keyQuery !== 'partnerId')
          if (this.searchSelected === 'localUserId') {
            this.listQuery.push({
              keyQuery: 'userId',
              valueQuery: searching,
            })
          } else {
            this.listQuery.push({
              keyQuery: 'partnerId',
              valueQuery: searching,
            })
          }
          // handle url user id
          abort()
          this.fetchListUserInfo()
        }, 600)
      } else if (this.searchSelected === 'email') {
        if (this.validateEmail(this.searchTerm)) {
          this.debounce = setTimeout(() => {
            // handle url email
            this.listQuery = this.listQuery.filter(item => item.keyQuery !== 'email')
            this.listQuery.push({
              keyQuery: 'email',
              valueQuery: this.searchTerm,
            })
            // handle url email
            this.fetchListUserInfo()
          }, 1000)
        }
      }
    },

    handleSubmitSearch() {
      if (this.searchTerm.length > 3 && this.searchSelected === 'email') {
        // handle url email
        this.listQuery = this.listQuery.filter(item => item.keyQuery !== 'email')
        this.listQuery.push({
          keyQuery: 'email',
          valueQuery: this.searchTerm,
        })
        // handle url email
        this.fetchListUserInfo()
      }
    },

    async handleDateChange(selectedDates) {
      this.rangeDate = selectedDates
      this.listQuery = this.listQuery.filter(item => item.keyQuery !== 'verifyAt')
      let verifyAt = null
      if (selectedDates.endDate) {
        verifyAt = (`${selectedDates.startDate}to${selectedDates.endDate}`)
      } else {
        verifyAt = selectedDates.startDate
      }

      if (verifyAt) {
        this.listQuery.push({
          keyQuery: 'verifyAt',
          valueQuery: verifyAt,
        })
      }

      this.fetchListUserInfo()
    },

    async fetchListUserInfo() {
      abort()

      const params = {
        ...(this.sortTerm.field && {
          sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
            this.sortTerm.field === 'createdAt' ? 'contactInfo.createdAt' : this.sortTerm.field
          }`,
        }),
        page: this.currentPage,
        size: this.pageLength,
        ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
        ...(this.statusSelected && { status: this.statusSelected.value }),
        ...(this.amSelected && !this.amEmail && { amId: this.amSelected.value }),
        ...(this.partnerSelected && { belongToPartnerId: this.partnerSelected.value }),
        ...(this.amEmail && { amEmail: this.amEmail }),
        ...(this.rangeDates && this.rangeDates),
      }
      await this.getListUserInfo({ params, cancelToken: cancelToken.token })
      if (!this.statusVerifyInfo && this.messageErrorVerifyInfo) {
        this.toastFailure(this.messageErrorVerifyInfo)
      }
    },

    submitExportData() {
      this.loadingDown = true
      if (this.noFilter) {
        this.$bvModal
          .msgBoxConfirm('Are you sure you want export all data?', {
            title: 'Please Confirm',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'primary',
            okTitle: 'Export data',
            cancelTitle: 'Close',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: true,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.exportExcel()
            } else {
              this.loadingDown = false
            }
          })
      } else {
        this.exportExcel()
      }
    },

    async exportExcel() {
      this.loadingDown = true

      try {
        const params = {
          ...(this.sortTerm.field && {
            sort: `${this.sortTerm.type === 'desc' ? '-' : ''}${
              this.sortTerm.field === 'createdAt' ? 'contactInfo.createdAt' : this.sortTerm.field
            }`,
          }),
          ...(this.searchTerm && { [this.searchSelected]: this.searchTerm }),
          ...(this.statusSelected && { status: this.statusSelected.value }),
          ...(this.amSelected && { amId: this.amSelected.value }),
          ...(this.partnerSelected && { belongToPartnerId: this.partnerSelected.value }),
          ...(this.rangeDates && this.rangeDates),
        }

        const { data } = await axios.get('/api/admin/users/contact-info/export', {
          responseType: 'blob',
          params,
        })
        const url = URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `list-verify-information-${new Date().toLocaleDateString()}.xlsx`,
        )
        document.body.appendChild(link)
        link.click()
        // Clean up after download
        document.body.removeChild(link)
      } catch (e) {
        const error = await new Response(e.data).json() || 'Server Error'
        this.toastFailure(error.message)
      } finally {
        this.loadingDown = false
      }
    },

    async fetchAllPartner() {
      await this.getListPartnerNoPaging()
    },

    previewImgStoreScreenshot(img) {
      this.imgPreviewStoreScreenshot = img
      this.$refs['open-screenshot-modal'].showModal()
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.table-verify-info {
  .column-product-link {
    min-width: 300px;
  }

  .width-column {
    min-width: 200px;
  }

  .column-industry {
    min-width: 150px;
  }

}

.filter-partner-id{
  .vs__actions{
    padding: 0 6px 0 3px;
  }
}

.table-verify-info {
  border-spacing: 0;
  .vgt-responsive {
    overflow-y: hidden;
  }
  .vgt-table {
    border-collapse: separate;
    border-spacing: 0px;
    border: 0 !important;
  }

  .vgt-checkbox-col {
    left: 0;
    background-color: #fff !important;
    border-right: 0 !important;
    border-bottom: 0 !important;
  }
  .column-sticky {
    left: 35px;
    border-left: 1px solid #ebe9f1 !important;
  }
  .column-sticky, .vgt-checkbox-col {
    position: sticky !important;
    z-index: 1;
  }

  tr:hover {
    .column-sticky, .vgt-checkbox-col {
      background-color: #fafafc !important;
    }
  }

  .scroll-user-info-column {
    &:after {
      content: "";
      position: absolute;
      height: 101%;
      top: 0;
      right: -13px;
      width: 13px;
      box-shadow: inset 13px 0px 8px -8px #00000026;
    }
  }
}
</style>

<style lang="scss" scoped>
.content-product-link {
  word-break: break-all;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn-export {
  margin-bottom: 1rem;
  height: 40px;
}

.btn-outline {
  background-color: #fff;
  color: #fab345;
  border-color: #fab345;
}

.text-status {
  text-transform: capitalize;
  padding: 5px 12px;
  border-radius: 100px;
  width: max-content;

  &.not-found {
    color: #babfc7;
    background-color: #babfc71a;
  }

  &.approved {
    color: #1db9c3;
    background-color: #1db9c31a;
  }

  &.rejected {
    color: #e11c4e;
    background-color: #e11c4e1a;
  }

  &.reviewing {
    color: #ff9903;
    background-color: #fbb03b1a;
  }
}

.column-product-link {
  ul {
    list-style: disc;
  }
}

.fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .img-full {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .close-btn {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 3rem;
    color: white;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }
}

.img-store-screenshot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 70px;
  background-color: #cccccc6e;
  margin-right: 4px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .gallery-item-info {
    display: none;
  }

  &:hover .gallery-item-info {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #ccccccb3;
    cursor: pointer;
  }
}
</style>
